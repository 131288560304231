import { Box } from '@chakra-ui/react';
import React from 'react';
import { IItem, TPartialItemWithItemID } from '../../../../../../game-server/src/modules/items/items.interface';
import { Item } from '../../Inventory/Item';
import '../../Inventory/Item.css';

export default function LoadoutItem(props: {
	item: TPartialItemWithItemID<IItem> | undefined;
	borderColor: string;
	class: string;
	onClick: () => void;
}) {
	return (
		<Box id={props.class} className='combat-gear-item' onClick={props.onClick}>
			{props.item && (
				<Item
					item={props.item}
					width='48px'
					height='48px'
					margin='1px'
					borderWidth='2px'
					borderStyle='solid'
					borderColor={props.borderColor}
				/>
			)}
		</Box>
	);
}
