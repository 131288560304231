import { Flex, Grid, ImageProps, Text } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import React from 'react';
import { ILocationData } from '../../../../../../game-server/src/modules/locations/location.interface';
import { IInspectData } from '../../../../../../game-server/src/modules/profile/Profile';
import useIsMobile from '../../../../hooks/useIsMobile';
import { socket } from '../../../../services/socket.service';
import { timeMSToString } from '../../../../helper/helperFunctions';
import { locations } from '../../../../utils/locationList';
import { Item } from '../../../game/Inventory/Item';
import { ProfileAddIcon } from './ProfileAddIcon';
import ProfileRemoveIcon from './ProfileRemoveIcon';

export function DungeonDisplay({
	dungeon,
	highestAug,
	date,
	time,
	allowRemove,
	...props
}: {
	dungeon: ILocationData['locID'];
	highestAug?: number;
	date?: Date;
	time?: number;
	allowRemove?: boolean;
} & ImageProps) {
	const dungeonData = locations[dungeon];
	if (!dungeonData) return null;
	const dungeonKey = dungeonData.relatedKey;
	if (!dungeonKey) return null;

	function removeDungeon(dungeonID: number | null) {
		if (dungeonID === null) return;
		socket.emit('profile:dungeon:remove', dungeonID);
	}

	return (
		<Flex flexDir='column' alignItems='center' position='relative'>
			<Item item={{ itemID: dungeonKey, augmentations: highestAug }} {...props} />
			{time !== undefined && <Text margin='0'>{timeMSToString(time)}</Text>}
			{date !== undefined && <Text margin='0'>{new Date(date).toLocaleDateString()}</Text>}
			{allowRemove && <ProfileRemoveIcon onClick={() => removeDungeon(dungeon)} />}
		</Flex>
	);
}

export function DungeonEditor({
	validDungeons,
	activeDungeons,
}: {
	validDungeons: ILocationData['locID'][];
	activeDungeons: IInspectData['dungeons'];
}) {
	const activeDungeonIds = activeDungeons.map((dungeon) => dungeon.location_id);
	const dungeons = validDungeons.filter((dungeon) => !activeDungeonIds.includes(dungeon));

	const isMobile = useIsMobile();

	if (dungeons.length === 0) return null;

	function addDungeon(dungeonID: number) {
		socket.emit('profile:dungeon:add', dungeonID);
	}

	function dungeonPicker() {
		return (
			<Grid gridTemplateColumns='repeat(auto-fit, 45px)' gap='5px' maxWidth='300px'>
				{dungeons.map((dungeon) => {
					return (
						<DungeonDisplay
							key={dungeon}
							dungeon={dungeon}
							onClick={() => addDungeon(dungeon)}
							height={isMobile ? '30px' : '40px'}
							width={isMobile ? '30px' : '40px'}
							style={{ cursor: 'pointer' }}
						/>
					);
				})}
			</Grid>
		);
	}
	return (
		<IdlescapeWrappingTooltip content={dungeonPicker()} activateOnClick>
			<ProfileAddIcon />
		</IdlescapeWrappingTooltip>
	);
}
