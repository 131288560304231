export function formatNumber(input: number, decimals = 2, trailingZeros = 0) {
	let output = input.toFixed(decimals);
	// Remove trailing zeros
	if (trailingZeros > 0) {
		output = output.replace(new RegExp(`\\.?0{1,${trailingZeros}}$`), '');
	} else if (trailingZeros === 0) {
		// Remove everything after the decimal point
		output = output.replace(/\.?0+$/, '');
	}
	return output;
}

export function toFixedLocale(number: number, minimumFractionDigits = 0, maximumFractionDigits = 2) {
	return number.toLocaleString('en-us', {
		minimumFractionDigits: minimumFractionDigits,
		maximumFractionDigits: maximumFractionDigits,
	});
}

export function formatBasicStat(statValue: number) {
	return (statValue > 0 ? '+' : '') + toFixedLocale(statValue);
}

export function formatMultiplierToPercentage(statValue: number) {
	return (statValue > 0 ? '+' : '') + formatMultiplierStat(statValue) + '%';
}

export function formatMultiplierStat(statValue: number) {
	return toFixedLocale(statValue * 100, 1, 3);
}

export function stringCapitalize(string: string | undefined) {
	if (!string || string.length === 0) return '';
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function camelCaseWord(string: string | undefined) {
	if (string) {
		return stringCapitalize(string.replace(/(?=[A-Z])/g, ' '));
	}
}

export function formatStat(statPath: string, value: number, asBonus = false) {
	if (statPath.startsWith('offensiveDamageAffinity') || statPath.startsWith('defensiveDamageAffinity')) {
		if (asBonus) return formatMultiplierToPercentage(value);
		return formatMultiplierToPercentage(value - 1);
	} else if (statPath.includes('.chance')) {
		if (asBonus) return formatMultiplierToPercentage(value);
		return formatMultiplierToPercentage(value);
	} else if (statPath === 'offensiveCritical.damageMultiplier') {
		if (asBonus) return formatBasicStat(value);
		return toFixedLocale(value);
	} else if (statPath === 'defensiveCritical.damageMultiplier') {
		if (asBonus) return formatBasicStat(value);
		return formatBasicStat(1 - value);
	}
	return formatBasicStat(value);
}

export function formatScrollCategories(categories?: string[]) {
	if (!categories) return [];
	return categories.map((category) => stringCapitalize(category.replace('set', '-set')));
}
