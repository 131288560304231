import { Flex } from '@chakra-ui/react';
import React from 'react';
import { MdOutlineRemoveCircle } from 'react-icons/md';

export default function ProfileRemoveIcon(props: { onClick: () => void }) {
	return (
		<Flex
			onClick={props.onClick}
			position='absolute'
			top='0'
			right='0'
			fontSize='20px'
			color='red.500'
			cursor='pointer'
			zIndex='1000'
			_hover={{ transform: 'scale(1.1)' }}
		>
			<MdOutlineRemoveCircle />
		</Flex>
	);
}
