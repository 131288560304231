import { atom, DefaultValue, selector } from 'recoil';

export const chatHistoryAtom = atom({
	key: 'chatHistoryAtom',
	default: [''],
});

export const chatHistoryIndexAtom = atom({
	key: 'chatHistoryIndexAtom',
	default: 0,
});

export const chatHistoryIndexSelector = selector({
	key: 'chatHistoryIndexSelector',
	get: ({ get }) => get(chatHistoryIndexAtom),
	set: ({ set, get }, newValue) => {
		const history = get(chatHistoryAtom);
		if (newValue instanceof DefaultValue) {
			set(chatHistoryIndexAtom, history.length - 1);
			return;
		}
		set(chatHistoryIndexAtom, Math.max(0, Math.min(history.length - 1, newValue)));
	},
});

export const chatInputSelector = selector({
	key: 'chatInputSelector',
	get: ({ get }) => {
		const history = get(chatHistoryAtom);
		const index = get(chatHistoryIndexAtom);
		return history[index];
	},
	set: ({ set, get }, newValue) => {
		if (newValue instanceof DefaultValue) {
			// Reset is triggered on send
			if (get(chatHistoryAtom)[0] !== '') {
				set(chatHistoryAtom, (oldValue) => {
					const copy = [...oldValue];
					copy.unshift('');
					return copy;
				});
			}
		} else {
			const oldValue = get(chatHistoryAtom);
			const index = get(chatHistoryIndexAtom);
			if (newValue === oldValue[index]) return;
			set(chatHistoryAtom, (oldValue) => {
				const copy = [...oldValue];
				copy.splice(index, 1);
				if (copy[0] === '') copy[0] = newValue;
				else copy.unshift(newValue);
				return copy;
			});
		}
		set(chatHistoryIndexAtom, 0);
	},
});

function appendToChatInput(oldValue: string, newValue: string | DefaultValue, prefix: string) {
	if (newValue instanceof DefaultValue) return newValue;
	if (oldValue.includes(`|${prefix}${newValue}|`)) return oldValue;
	oldValue = oldValue.trim();
	return oldValue !== '' ? `${oldValue} |${prefix}${newValue}|` : `|${prefix}${newValue}|`;
}

export const shareItemSelector = selector({
	key: 'shareItemSelector',
	get: ({ get }) => get(chatInputSelector),
	set: ({ set }, newValue) => {
		set(chatInputSelector, (oldValue) => appendToChatInput(oldValue, newValue, '@'));
	},
});
export const shareSkillSelector = selector({
	key: 'shareSkillSelector',
	get: ({ get }) => get(chatInputSelector),
	set: ({ set }, newValue) => {
		set(chatInputSelector, (oldValue) => appendToChatInput(oldValue, newValue, '#'));
	},
});
export const shareChestSelector = selector({
	key: 'shareChestSelector',
	// The return type of `get` determines the type of newValue in `set`
	get: ({ get }) => ["Don't use this selector", 0] as [string, number | null],
	set: ({ set }, newValue) => {
		set(chatInputSelector, (oldValue) => {
			if (newValue instanceof DefaultValue) return newValue;
			const [chestID, chestDBID] = newValue;
			if (chestDBID) {
				return appendToChatInput(oldValue, `${chestID},${chestDBID}`, '€');
			} else {
				return appendToChatInput(oldValue, chestID, '€');
			}
		});
	},
});
export const shareMarketplaceListingSelector = selector({
	key: 'shareMarketplaceListingSelector',
	get: ({ get }) => get(chatInputSelector),
	set: ({ set }, newValue) => {
		set(chatInputSelector, (oldValue) => appendToChatInput(oldValue, newValue, '$'));
	},
});
export const shareMarketplaceOfferSelector = selector({
	key: 'shareMarketplaceOfferSelector',
	get: ({ get }) => get(chatInputSelector),
	set: ({ set }, newValue) => {
		set(chatInputSelector, (oldValue) => appendToChatInput(oldValue, newValue, '¥'));
	},
});
export const shareItemSetSelector = selector({
	key: 'shareItemSetSelector',
	get: ({ get }) => get(chatInputSelector),
	set: ({ set }, newValue) => {
		set(chatInputSelector, (oldValue) => appendToChatInput(oldValue, newValue, '%'));
	},
});
export const shareQuestSelector = selector({
	key: 'shareQuestSelector',
	get: ({ get }) => get(chatInputSelector),
	set: ({ set }, newValue) => {
		set(chatInputSelector, (oldValue) => appendToChatInput(oldValue, newValue, '?'));
	},
});

export const addToChatInputSelector = selector({
	key: 'addToChatInputSelector',
	get: ({ get }) => get(chatInputSelector),
	set: ({ set }, newValue) => {
		set(chatInputSelector, (oldValue) => (oldValue !== '' ? `${oldValue} ${newValue}` : newValue));
	},
});
