import React from 'react';
import CombatMonsterArea from './CombatMonsterArea';
import { CombatGroupAvatarArea } from './CombatGroupAvatarArea';
import CombatBar from './CombatBar';
import { CombatGroupInfo } from './CombatGroupInfo';
import { Flex, Image } from '@chakra-ui/react';
import { useGroupField } from '../../../hooks/hooks';
import { getTimeString } from '../../../utils/helperFunctions';

export const CombatFightGroup = () => {
	const groupStatus = useGroupField('status');
	const catchupTime = useGroupField('catchupTime');
	return (
		<div className='group-combat-main'>
			<CombatBar></CombatBar>
			<div className='combat-fight-group'>
				<CombatGroupInfo></CombatGroupInfo>
				<CombatGroupAvatarArea></CombatGroupAvatarArea>
				<CombatMonsterArea></CombatMonsterArea>
				{groupStatus && (
					<Flex
						position='absolute'
						top='0'
						bottom='40px'
						left='0'
						right='0'
						justifyContent='center'
						flexDirection='column'
						alignItems='center'
						background='#0008'
						zIndex='1000'
						textAlign='center'
						fontSize='2xl'
					>
						<div>
							{groupStatus === 'catchup'
								? 'The leader is catching up'
								: 'The leader has exceeded their max offline time or has not logged in after a server restart'}
						</div>
						<Image src='/images/pontification/skill_waiting_icon.png' maxWidth='200px' />
						<div>
							{groupStatus === 'catchup' && catchupTime
								? `Time left: ${getTimeString(catchupTime)}`
								: 'Stop the action to leave the group'}
						</div>
					</Flex>
				)}
			</div>
		</div>
	);
};
