import { Box } from '@chakra-ui/react';
import React from 'react';
import { TSkillTab } from '../../../../../../game-server/src/Atypes/Skills';
import { TCombatType } from '../../../../../../game-server/src/modules/loadout/loadout';
import { socket } from '../../../../services/socket.service';
import { locations } from '../../../../utils/locationList';
import { baseLocations } from '../CombatZones';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import { tabs } from '../../../../helper/NavigationTabsList';

const combatTypes: Partial<Record<TSkillTab, TCombatType>> = {
	strength: 'Melee',
	magic: 'Magic',
	range: 'Range',
};

export default function LoadoutGenerator() {
	// Set state for a combat type
	const [combatType, setCombatType] = React.useState<TCombatType | undefined>('Melee');

	function generateLoadout(locationID: number, combatType?: TCombatType) {
		if (locationID) {
			socket.emit('loadout:generate', { locationID, combatType });
		}
	}

	function generateOptions(locationID: number, category?: string) {
		if (!locationID) return;
		const locationData = locations[locationID];
		const name = locationData.name ?? category;
		const icon = locationData.locationImage ?? category;

		const combatCategories = ['Combat', 'Dungeon', 'Elite Combat'];
		const isCombat = combatCategories.includes(category ?? '');
		return (
			<div
				key={name}
				className='loadout-slot idlescape-frame-box-dark idlescape-frame-box-interactive'
				onClick={() => generateLoadout(locationData.locID, isCombat ? combatType : undefined)}
			>
				<img src={icon} alt={name} />
				<div className='loadout-slot-name'>{name}</div>
			</div>
		);
	}

	const validLocations: {
		category: string;
		locationID: number;
		locationName: string;
		duration: number;
		requiredLevel: number;
		bestiaryLocation: number;
	}[] = [];
	// iterate through all the location values
	const blacklistedLocations = [1, 2, 3, 4, 5, 200, 201, 202];
	const eliteRange = [2000, 2999];
	const dungeonRange = [3000, 3999];
	for (const locIdx in locations) {
		const location = locations[locIdx];
		if (blacklistedLocations.includes(location.locID)) continue;
		const recommendedLevel = Number(location.extraTooltipInfo?.split(/[-+]/)[0]);
		const validLoc = {
			category: location.actionType.replace('Action-', ''),
			locationID: location.locID,
			locationName: location.name,
			duration: location.baseDuration,
			requiredLevel:
				location.accessRequirements?.requiredSkills?.[0]?.level ??
				(isNaN(recommendedLevel) ? 0 : recommendedLevel),
			bestiaryLocation: location.bestiaryBaseLocationID ?? location.locID,
		};
		// if the location is an elite zone, change the category to 'Elite Combat'
		if (location.locID >= eliteRange[0] && location.locID <= eliteRange[1]) {
			validLoc.category = 'Elite Combat';
		}
		// if the location is a dungeon, change the category to 'Dungeon'
		if (location.locID >= dungeonRange[0] && location.locID <= dungeonRange[1]) {
			validLoc.category = 'Dungeon';
		}
		validLocations.push(validLoc);
	}
	// Sort the locations by category, then by level, then by duration
	validLocations.sort((a, b) => {
		if (a.category < b.category) return -1;
		if (a.category > b.category) return 1;
		if (a.category !== 'Combat' && a.category !== 'Elite Combat' && a.requiredLevel !== b.requiredLevel)
			return a.requiredLevel - b.requiredLevel;
		const indexA = baseLocations.findIndex((i) => i === a.bestiaryLocation);
		const indexB = baseLocations.findIndex((i) => i === b.bestiaryLocation);
		if (indexA !== indexB) return indexA - indexB;
		if (a.duration < b.duration) return -1;
		if (a.duration > b.duration) return 1;
		return 0;
	});

	const locationCategories = validLocations.reduce((acc, curr) => {
		if (!(curr.category in acc)) {
			acc[curr.category] = [];
		}
		acc[curr.category].push(curr);
		return acc;
	}, {} as { [key: string]: typeof validLocations });

	const options: React.ReactNode[] = [];
	// For each category, generate a section with the category name and the locations
	Object.entries(locationCategories).forEach(([category, locations]) => {
		const categoryOptions = locations.map((location) => generateOptions(location.locationID, category));
		if (categoryOptions.length > 0) {
			options.push(
				<Accordion allowMultiple key={`loadout-category-${category}`} background='none'>
					<AccordionItem>
						<AccordionButton>
							<Box flex='1' textAlign='center'>
								{category}
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel pb={4}>
							<div className='loadout-slots'>{categoryOptions}</div>
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			);
		}
	});

	function getCombatStyleSelection() {
		const combatStyles = Object.keys(combatTypes) as TSkillTab[];
		// Generate 3 buttons similar to the options above
		const buttons = combatStyles.map((combatStyle) => {
			const style = combatTypes[combatStyle];
			const styleTab = tabs[combatStyle.toLowerCase()];
			return (
				<div
					key={style}
					className={`loadout-slot idlescape-frame-box-dark idlescape-frame-box-interactive${
						style === combatType ? ' selected' : ''
					}`}
					onClick={() => setCombatType(style)}
				>
					<img src={styleTab?.icon ?? ''} alt={style} />
					<div className='loadout-slot-name'>{style}</div>
				</div>
			);
		});
		return <div className='loadout-slots'>{buttons}</div>;
	}

	return (
		<Box textAlign='center'>
			<h2>Loadout Recommendations</h2>
			<h4>Combat Style Selection</h4>
			{getCombatStyleSelection()}
			{options.length > 0 ? (
				<div className='loadout-location-container'>{options}</div>
			) : (
				<h3>No available options?</h3>
			)}
		</Box>
	);
}
