import { Box, Container, Grid, Text, Image, useDisclosure } from '@chakra-ui/react';
import { IdlescapeFrameBox } from '@idlescape/ui';
import { useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useRecoilState, useRecoilValue } from 'recoil';
import { activeTabAtom } from '../../atoms/activeTabAtom';
import { ITab, tabs } from '../../helper/NavigationTabsList';
import GuideModal from '../game/GuideModal';
import { PlayArea } from '../game/PlayArea';
import './MobileLayout.css';

export const MobileTab = (props: { tab: ITab; showGuide?: boolean }) => {
	const [activeTab, setActiveTab] = useRecoilState(activeTabAtom);
	const active = activeTab === props.tab.id;
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<IdlescapeFrameBox
			width={'100%'}
			height={'100px'}
			variant={active ? 'primary' : 'dark'}
			onClick={() => setActiveTab(props.tab.id)}
			className={active ? 'anchor-mobile-tab-active' : ''}
		>
			<Grid gridTemplateColumns={'1fr'} width={'100%'} height={'100%'} position={'relative'} padding={'5px'}>
				<Text margin={0} wordBreak='break-word'>
					{props.tab.overrideTabName ?? props.tab.name}
				</Text>
				<Box height={'100%'} width={'100%'}>
					<Image
						src={props.tab.overrideTabIcon ?? props.tab.icon}
						maxWidth={'14vw'}
						height={'60px'}
						objectFit='contain'
					/>
				</Box>
			</Grid>
			{props.showGuide && (
				<>
					<Box
						position='absolute'
						right='5px'
						bottom='5px'
						fontSize={20}
						marginLeft={'5px'}
						marginTop={'4px'}
						cursor={'pointer'}
						onClick={(e) => {
							e.stopPropagation();
							onOpen();
						}}
					>
						<AiOutlineInfoCircle></AiOutlineInfoCircle>
					</Box>
					<GuideModal isOpen={isOpen} onClose={onClose} tab={props.tab.id} />
				</>
			)}
		</IdlescapeFrameBox>
	);
};

export const MobileLayout = () => {
	const activeTab = useRecoilValue(activeTabAtom);

	const [historyTab, setHistoryTab] = useState(tabs['quests']);

	useEffect(() => {
		if (activeTab !== historyTab.id && !['chat', 'inventory', 'equipment', 'social'].includes(activeTab)) {
			setHistoryTab(tabs[activeTab]);
		}
	}, [activeTab]);

	return (
		<Grid gridTemplateRows={'1fr 100px'} width={'100%'} height={'100%'} paddingBottom={'5px'}>
			<PlayArea />
			<Container>
				<Grid
					gridTemplateColumns={'repeat(5, 1fr)'}
					justifyContent={'center'}
					textAlign={'center'}
					className='anchor-mobile-tabs'
				>
					<MobileTab tab={historyTab} showGuide={true} />
					<MobileTab tab={tabs['chat']} />
					<MobileTab tab={tabs['inventory']} />
					<MobileTab tab={tabs['equipment']} />
					<MobileTab tab={tabs['social']} />
				</Grid>
			</Container>
		</Grid>
	);
};
