import axios from 'axios';

const setAuthToken = (token) => {
	if (token) {
		const bearerToken = String(token).startsWith('Bearer ') ? token : `Bearer ${token}`;
		// Apply authorization token to every request if logged in
		axios.defaults.headers.common['Authorization'] = bearerToken;
	} else {
		// Delete auth header
		delete axios.defaults.headers.common['Authorization'];
	}
};

export default setAuthToken;
