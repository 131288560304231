import { Grid, TabPanel, Text } from '@chakra-ui/react';
import React from 'react';

export default function DetailsGeneral(props: {
	accountCreated: Date;
	mutes: number;
	shadowMutes: number;
	bans: number;
	tradebans: number;
}) {
	return (
		<TabPanel>
			<Grid templateColumns='repeat(2, max-content)' gap={3}>
				<Text margin={0}>Account Created:</Text>
				<Text margin={0}>{new Date(props.accountCreated).toDateString()}</Text>
				<Text margin={0}>Mutes:</Text>
				<Text margin={0}>{props.mutes}</Text>
				<Text margin={0}>Shadow Mutes:</Text>
				<Text margin={0}>{props.shadowMutes}</Text>
				<Text margin={0}>Bans:</Text>
				<Text margin={0}>{props.bans}</Text>
				<Text margin={0}>Tradebans:</Text>
				<Text margin={0}>{props.tradebans}</Text>
			</Grid>
		</TabPanel>
	);
}
