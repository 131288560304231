import { Box, Flex, FormLabel, Image, Switch, Text, useDisclosure } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeWrappingTooltip } from '@idlescape/ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { IChestData } from '../../../../../game-server/src/modules/client/ClientManager';
import { IItem, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { shareChestSelector } from '../../../atoms/chat/chatHistoryAtom';
import { socket } from '../../../services/socket.service';
import { itemList } from '../../../utils/itemList';
import { Item } from '../../game/Inventory/Item';

export default function ChestContents() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [chestData, setChestData] = useState<IChestData[]>([]);
	const [combineChests, setCombineChests] = useState(true);
	const shareChest = useSetRecoilState(shareChestSelector);

	const hasDuplicateChests = chestData.some((chest) => {
		return chestData.filter((c) => c.chestID === chest.chestID).length > 1;
	});

	useEffect(() => {
		socket.on('chest', (data) => {
			data.contents = combineItems(data.contents);
			setChestData((chestData) => [...chestData, data]);
			onOpen();
		});

		return () => {
			socket.off('chest');
		};
	}, []);

	function handleCloseModal() {
		setChestData([]);
		onClose();
	}

	function renderItems(chest: IChestData) {
		if (chest.contents.length === 0) return null;
		chest.contents.sort((a, b) => {
			if (a.itemID < b.itemID) return -1;
			if (a.itemID > b.itemID) return 1;
			return 0;
		});
		const items = chest.contents.map((loot, index) => {
			const { itemID, amount: stackSize } = loot;
			const fakeItem: TPartialItemWithItemID<IItem> = {
				itemID,
				stackSize,
			};
			return <Item item={fakeItem} key={index} />;
		});
		return (
			<div className='chest-open-box all-items' key={`${chest.chestID}${chest.amount}content`}>
				{items}
			</div>
		);
	}

	function renderChests(chests: (IChestData & { combined?: boolean })[]) {
		return chests.map((chest, index) => {
			let title = chest.titleOverride ?? itemList[chest.chestID].name;
			if (chest.amount > 1) {
				title += ' x' + chest.amount;
			}
			const isFirstChestOfType = index === chests.findIndex((c) => c.chestID === chest.chestID);
			// @ts-ignore - findLastIndex needs ts v5.0
			const isLastChestOfType = index === chests.findLastIndex((c) => c.chestID === chest.chestID);
			const shareAll = chest.combined || (!isLastChestOfType && isFirstChestOfType);
			return (
				<React.Fragment key={`${chest.chestID}${chest.amount}${index}title`}>
					<DialogTitle>
						{title}
						<Image
							src={itemList[chest.chestID].itemImage}
							alt={itemList[chest.chestID].name}
							className='item'
							float='unset'
							marginLeft='5px'
						/>
					</DialogTitle>
					{(shareAll || isLastChestOfType) && (
						<Flex className='share-chest-button'>
							{shareAll ? (
								<IdlescapeWrappingTooltip
									content='Share all your recent chests of this type in chat'
									key='share'
								>
									<Box
										cursor='pointer'
										onClick={() => shareChest([chest.chestID.toString(), chest.id])}
									>
										<Image
											src='/images/chaticons/chat-icon-global.png'
											alt='Share'
											width='100%'
											height='100%'
										/>
										<Text
											position='absolute'
											top='0'
											left='0'
											margin='0'
											transform='rotate(30deg) translate(50%, 30%)'
											fontWeight='bolder'
											fontSize='large'
											textShadow='-2px 0 rgb(17, 6, 88), 0 2px rgb(17, 6, 88), 2px 0 rgb(17, 6, 88), 0 -2px rgb(17, 6, 88)'
										>
											ALL
										</Text>
									</Box>
								</IdlescapeWrappingTooltip>
							) : (
								<IdlescapeWrappingTooltip
									content='Share your latest batch of chests of this type in chat'
									key='share'
								>
									<Image
										src='/images/chaticons/chat-icon-global.png'
										alt='Share'
										width='100%'
										height='100%'
										onClick={() => shareChest([chest.chestID.toString(), null])}
										cursor='pointer'
									/>
								</IdlescapeWrappingTooltip>
							)}
						</Flex>
					)}
					{renderItems(chest)}
				</React.Fragment>
			);
		});
	}

	function combineItems(contents: { itemID: number; amount: number }[]) {
		return contents.reduce((acc, item) => {
			const found = acc.find((i) => i.itemID === item.itemID);
			if (found) {
				found.amount += item.amount;
			} else {
				acc.push(item);
			}
			return acc;
		}, [] as { itemID: number; amount: number }[]);
	}

	function renderJoinedChests() {
		const joinedChests = chestData.reduce((acc, chest) => {
			if (acc[chest.chestID]) {
				acc[chest.chestID].amount += chest.amount;
				acc[chest.chestID].combined = true;
				for (const item of chest.contents) {
					const index = acc[chest.chestID].contents.findIndex((c) => c.itemID === item.itemID);
					if (index >= 0) {
						acc[chest.chestID].contents[index].amount += item.amount;
					} else {
						acc[chest.chestID].contents.push(structuredClone(item));
					}
				}
			} else {
				acc[chest.chestID] = { ...structuredClone(chest), combined: false };
			}
			return acc;
		}, {} as { [key: IChestData['chestID']]: IChestData & { combined: boolean } });
		return renderChests(Object.values(joinedChests));
	}

	return (
		<Dialog
			open={isOpen}
			onClose={handleCloseModal}
			className='donate-dialog feedback-dialog sell-item-dialog popup-dialog'
		>
			{hasDuplicateChests && (
				<Flex alignItems='center'>
					<FormLabel htmlFor='combine-chests-switch'>Combine drops</FormLabel>
					<Switch
						id='combine-chests-switch'
						isChecked={combineChests}
						onChange={(e) => setCombineChests(e.target.checked)}
					/>
				</Flex>
			)}
			{combineChests ? renderJoinedChests() : renderChests(chestData)}
			<DialogActions>
				<IdlescapeButton variant='red' onClick={handleCloseModal} size='medium'>
					Close
				</IdlescapeButton>
			</DialogActions>
		</Dialog>
	);
}
