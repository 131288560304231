import { Box, Flex, Heading } from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import { ILoadout } from '../../../../../../game-server/src/modules/loadout/loadout.interface';
import { loadoutFoodAtom } from '../../../../atoms/loadoutAtom';
import { usePlayerField } from '../../../../hooks/hooks';
import { COMBAT_INVENTORY_SIZE } from '../../../../utils/constantsCollection';
import { doItemsMatch } from '../../../../utils/itemFunctions';
import { itemList } from '../../../../utils/itemList';
import { Item } from '../../Inventory/Item';
import '../../Inventory/Item.css';
import SelectionTooltipBox from '../../Tooltips/Types/SelectionTooltipBox';

const RED = 'rgba(255, 0, 0, 0.5)';
const GREEN = 'rgba(0, 255, 0, 0.5)';
const BLUE = 'rgba(0, 0, 255, 0.5)';

export default function LoadoutSelectFood() {
	const [loadoutFood, setLoadoutFood] = useRecoilState(loadoutFoodAtom);
	const combatInventory = usePlayerField('combatInventory');
	const stockpile = usePlayerField('stockpile');

	const possibleFood = stockpile.filter((item) => {
		const info = itemList[item.itemID];
		return info.healing;
	});
	// Remove duplicates
	for (const food of combatInventory) {
		const found = possibleFood.findIndex((item) => doItemsMatch(item, food));
		if (found === -1) possibleFood.push(food);
	}

	// Unequip if the item is moved
	useEffect(() => {
		for (let i = 0; i < loadoutFood.food.length; i++) {
			const food = loadoutFood.food[i];
			if (!food?.id) {
				continue;
			}
			let owned = false;
			let similarId = 0;
			for (const item of possibleFood) {
				if (item.id === food.id) {
					owned = true;
					break;
				}
				if (doItemsMatch(item, food)) {
					similarId = item.id;
				}
			}
			if (!owned) {
				// If a similar item exists, replace it instead of removing
				if (similarId) {
					const newFood = structuredClone(loadoutFood.food);
					const newSlot = newFood[i];
					if (newSlot?.id) newSlot.id = similarId;
					setLoadoutFood({ changed: true, food: newFood });
					return;
				}
				unequip(i);
			}
		}
	}, [stockpile, combatInventory]);

	function equip(item: IItem, index: number) {
		const newFood = structuredClone(loadoutFood.food);
		newFood[index] = {
			id: item.id,
			itemID: item.itemID,
			augmentations: item.augmentations,
			enchantmentID: item.enchantmentID,
		};
		setLoadoutFood({ changed: true, food: newFood });
		return true;
	}

	function unequip(index: number) {
		const newFood = structuredClone(loadoutFood.food);
		delete newFood[index];
		setLoadoutFood({ changed: true, food: newFood });
	}

	function emptyFood() {
		const newFood: ILoadout['foodInventory'] = [];
		setLoadoutFood({ changed: true, food: newFood });
	}

	function handleClick(e: React.MouseEvent, index: number) {
		if (e.shiftKey) {
			unequip(index);
			return true;
		}
		return false;
	}

	function getFoodBorders(inventory: ILoadout['foodInventory']) {
		const borderColors: string[] = [];
		const stockpileCopy = structuredClone(stockpile);
		const combatInventoryCopy = structuredClone(combatInventory);
		for (const item of inventory) {
			const combatInventoryItemindex = combatInventoryCopy.findIndex((e) => doItemsMatch(e, item));
			if (combatInventoryItemindex !== -1) {
				borderColors.push(BLUE);
				combatInventoryCopy.splice(combatInventoryItemindex, 1);
				continue;
			}
			const stockpileItem = stockpileCopy.find((e) => doItemsMatch(e, item));
			if (stockpileItem && stockpileItem.stackSize > 0) {
				borderColors.push(GREEN);
				stockpileItem.stackSize--;
			} else {
				borderColors.push(RED);
			}
		}
		return borderColors;
	}

	const borderColors = getFoodBorders(loadoutFood.food);

	const items: React.ReactElement[] = [];
	for (let i = 0; i < COMBAT_INVENTORY_SIZE; i++) {
		const item = loadoutFood.food[i];
		items.push(
			<SelectionTooltipBox
				key={i}
				items={possibleFood}
				clickItem={(item) => equip(item, i)}
				clickEmpty={() => unequip(i)}
				className='combat-consumable'
				currentItem={item}
				title={'Food ' + i}
				onClickBox={(e) => handleClick(e, i)}
			>
				{item && (
					<Item
						item={item}
						height='45px'
						width='45px'
						margin='0 auto'
						float='none'
						borderWidth='2px'
						borderStyle='solid'
						mobileTooltip={false}
						borderColor={borderColors[i]}
					/>
				)}
				{possibleFood.length > 0 && !item && (
					<Box
						className='combat-empty-slot'
						fontSize='24px'
						fontWeight='bold'
						textAlign='center'
						lineHeight='43px'
					>
						{possibleFood.length}
					</Box>
				)}
			</SelectionTooltipBox>
		);
	}

	return (
		<Flex flexDirection='column' alignItems='center'>
			<Heading size='md' textAlign='center'>
				Combat Food
			</Heading>
			<IdlescapeButton variant='green' size='medium' onClick={emptyFood} margin='auto 0 '>
				Empty Food
			</IdlescapeButton>
			<div className='loadout-inventory-container'>{items}</div>
		</Flex>
	);
}
