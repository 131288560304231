import { Box, Flex, Grid, Image, Switch } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IdlescapeButton, IdlescapeContainer, IdlescapeWrappingTooltip } from '@idlescape/ui';
import { capitalize } from 'lodash';
import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { IoIosWarning } from 'react-icons/io';
import { MdDragHandle } from 'react-icons/md';
import { ILoadout } from '../../../../../../game-server/src/modules/loadout/loadout.interface';
import useIsMobile from '../../../../hooks/useIsMobile';
import { socket } from '../../../../services/socket.service';
import { MERC_FOOD_COEFFICIENT } from '../../../../utils/constantsCollection';
import { enchantmentsList } from '../../../../utils/enchantmentList';
import { getLoadoutIcon } from '../../../../helper/helperFunctions';
import { itemList } from '../../../../utils/itemList';
import { enchantmentsIds } from '../../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { Item } from '../../Inventory/Item';
import { TMercStatus } from './MercenaryStatus';

export default function Mercenary({ merc, status, active }: { merc: ILoadout; status: TMercStatus; active: boolean }) {
	const icon = getLoadoutIcon(merc);
	const isMobile = useIsMobile();

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: merc.id.toString(),
	});
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	function renderStatus(status: TMercStatus) {
		switch (status.status) {
			case 'ok':
				return (
					<Box fontSize='xl'>
						<FaCheck color='green' />
					</Box>
				);
			case 'missing-gear':
			case 'conflicting-gear':
			case 'missing-food':
				return (
					<IdlescapeWrappingTooltip
						content={
							<Flex flexDirection='column' alignItems='center'>
								{capitalize(status.status).replace('-', ' ')}
								<Grid
									gridTemplateColumns={isMobile ? 'repeat(auto-fit, 50px)' : 'repeat(auto-fit, 64px)'}
									maxWidth='300px'
									justifyContent='center'
								>
									{status.missing.map((item, index) => (
										<Item key={index} item={item} />
									))}
								</Grid>
							</Flex>
						}
					>
						<Box fontSize='xl'>
							<IoIosWarning color='orange' />
						</Box>
					</IdlescapeWrappingTooltip>
				);
		}
	}

	function foodHP(merc: ILoadout) {
		if (!merc.options.loadoutMercUseFood) return 0;
		let healthBonus = 0;
		for (const invItem of merc.foodInventory.filter(Boolean)) {
			const itemData = itemList[invItem.itemID];
			if (itemData.healing) {
				const healingFoodMult = 1 + (invItem.augmentations ?? 0);
				const baseHealing = itemData.healing.hp * healingFoodMult;
				const healingFromTicks =
					(itemData.healing.perTick ?? 0) *
					(itemData.healing.totalTicks ?? 0) *
					Math.max(1, healingFoodMult / 2);
				const fullBonus = baseHealing + healingFromTicks;
				healthBonus += fullBonus * (invItem.stackSize ?? 1);
			}
		}

		let highestHealingStrength = 0;
		for (const equip of Object.values(merc.equipment)) {
			if (equip && equip.enchantmentID === enchantmentsIds.healing && equip.enchantmentStrength) {
				highestHealingStrength = Math.max(highestHealingStrength, equip.enchantmentStrength);
			}
		}
		const healingEnchantment = enchantmentsList[enchantmentsIds.healing];
		const healingStrength = highestHealingStrength * healingEnchantment.strengthPerLevel;
		healthBonus *= MERC_FOOD_COEFFICIENT * (1 + healingStrength);
		return healthBonus;
	}

	function toggleMercUseFood(loadoutID: number) {
		socket.emit('loadout:mercFood', { loadoutID });
	}

	function toggleMercenary(loadoutID: number) {
		socket.emit('settings:mercLoadout', loadoutID);
	}

	return (
		<Grid
			as={IdlescapeContainer}
			gridTemplateColumns='30px 50px 1fr 50px 1fr 30px'
			key={merc.id}
			justifyContent='center'
			alignItems='center'
			ref={setNodeRef}
			style={style}
			{...attributes}
			position='relative'
			zIndex={active ? 1 : undefined}
			height='100px'
		>
			<Box cursor={active ? 'grabbing' : 'grab'} {...listeners}>
				<MdDragHandle />
			</Box>
			{renderStatus(status)}
			<Box justifySelf='center'>
				<Image
					src={icon.icon}
					alt={icon.alt}
					width='30px'
					height='30px'
					objectFit='contain'
					filter='drop-shadow(2px 2px 2px black)'
					paddingRight='5px'
				/>
				{merc.name}
			</Box>
			<Flex justifyContent='center' alignItems='center'>
				<Switch isChecked={merc.options.loadoutMercUseFood} onChange={() => toggleMercUseFood(merc.id)} />
			</Flex>
			<Box justifySelf='center'>
				{foodHP(merc).toLocaleString('en-us')}{' '}
				<Image
					src='/images/combat/constitution_icon.png'
					alt='Expected HP'
					width='30px'
					height='30px'
					objectFit='contain'
				/>
			</Box>
			<IdlescapeButton variant='red' size='icon' onClick={() => toggleMercenary(merc.id)}>
				x
			</IdlescapeButton>
		</Grid>
	);
}
