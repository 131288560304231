import { Flex, Image, Text } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer, IdlescapeFrameBox, IdlescapeNumberInput } from '@idlescape/ui';
import React, { useEffect, useState } from 'react';
import {
	IItem,
	IItemData,
	TPartialItemWithItemID,
} from '../../../../../../game-server/src/modules/items/items.interface';
import { usePlayerEnchantmentStrength, usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';
import { filterForBaseItems } from '../../../../utils/itemFunctions';
import { itemList } from '../../../../utils/itemList';
import { leagueList } from '../../../../utils/leagueList';
import { enchantmentsIds } from '../../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { questList } from '../../../../utils/questList';
import { CraftingAugmentingData } from '../../CraftingAugmenting/CraftingAugmentingData';
import { Item } from '../../Inventory/Item';
import ResourceCost from '../ResourceCost';
import './CraftingRecipe.css';

export default function CraftingRecipe(props: { itemID: IItemData['id'] }) {
	const stockpile = usePlayerField('stockpile');
	const questsCompleted = usePlayerField('questsCompleted');
	const league = usePlayerField('league');
	const craftingTalentStrength = usePlayerEnchantmentStrength(enchantmentsIds.crafting_talent, 'crafting');
	const massCrafterStrength = usePlayerEnchantmentStrength(enchantmentsIds.mass_crafter, 'crafting');
	const [selectedRecipe, setSelectedRecipe] = useState(0);
	const [craftingCount, setCraftingCount] = useState(1);
	const [animating, setAnimating] = useState(false);

	const craftingRecipes = CraftingAugmentingData.getCraftingByID(props.itemID);

	useEffect(() => {
		setSelectedRecipe(0);
		setCraftingCount(1);
		setAnimating(false);
		if (craftingRecipes && !craftingRecipes[selectedRecipe]) {
			setSelectedRecipe(0);
		}
	}, [props.itemID, craftingRecipes]);

	if (!craftingRecipes) return null;

	const item = itemList[props.itemID];
	const resources = craftingRecipes[selectedRecipe]?.recipe;
	if (!item.craftingStats || !resources) return null;
	const resourceCosts = Object.entries(resources).map(([resource, count]) => {
		const resourceCount = Math.ceil(count * (1 - craftingTalentStrength));
		return { resource: itemList[Number(resource)], count: resourceCount };
	});

	const maxCrafted = calculateMaxCrafted();

	let craftingMultiplier = craftingRecipes[selectedRecipe].multiplier ?? item.craftingStats.multiplier ?? 1;
	if (craftingMultiplier > 1) {
		craftingMultiplier = Math.floor(craftingMultiplier * (1 + massCrafterStrength));
	}
	const fakeItem: TPartialItemWithItemID<IItem> = {
		itemID: props.itemID,
		stackSize: craftingMultiplier,
		...(item.forcedEnchant !== undefined && item.forcedEnchantAmount !== undefined
			? { enchantmentID: item.forcedEnchant, enchantmentStrength: item.forcedEnchantAmount }
			: {}),
	};

	const experiencePerCraft = craftingRecipes[selectedRecipe].experience ?? item.craftingStats.experience;

	const description = item.craftingStats.description ?? item.extraTooltipInfo;

	function attemptCraft() {
		if (!animating && craftingCount > 0) {
			socket.emit('crafting', {
				itemID: props.itemID,
				itemAmount: craftingCount,
				recipeIndex: selectedRecipe,
			});
			setAnimating(true);
			setTimeout(() => setAnimating(false), 1000);
		}
	}

	function getInventoryCount(id: number) {
		id = Number(id);
		const filteredStockpile = filterForBaseItems(stockpile, [id], itemList);
		return filteredStockpile.reduce((acc, item) => {
			return acc + item.stackSize;
		}, 0);
	}

	function calculateMaxCrafted() {
		if (!craftingRecipes) return 0;
		const resources = craftingRecipes[selectedRecipe].recipe;
		let maxCrafted = Infinity;

		for (const [id, count] of Object.entries(resources)) {
			const stock = getInventoryCount(Number(id));
			const maxRes = Math.floor(stock / Math.ceil(count * (1 - craftingTalentStrength)));
			maxCrafted = Math.min(maxCrafted, maxRes);
		}

		if (maxCrafted === Infinity) maxCrafted = 0;

		return maxCrafted;
	}

	function isLocked() {
		const questEnabled = leagueList[league].rules.quests;
		if (!questEnabled) return false;
		if (item.craftingStats && item.craftingStats.unlockQuest) {
			// Check if the player has completed the quest
			if (!questsCompleted.find((quest) => quest.id === item?.craftingStats?.unlockQuest)) {
				return true;
			}
		}
		return false;
	}

	function renderLocked() {
		if (!isLocked()) return null;
		if (!item?.craftingStats?.unlockQuest) return null;
		return (
			<div className='crafting-locked-cover'>
				<div>
					Recipe Locked
					<br />
					<br />
					Complete Quest: {questList[item.craftingStats.unlockQuest].name}
				</div>
			</div>
		);
	}

	return (
		<IdlescapeContainer position='relative' overflowY='hidden'>
			{renderLocked()}
			<div className='crafting-recipe'>
				<div className='crafting-item'>
					<div className='crafting-item-name'>{item.name}</div>
					<div className='crafting-item-icon'>
						<Item
							item={fakeItem}
							width='100% !important'
							height='100% !important'
							backgroundImage='linear-gradient(to bottom, rgba(87, 104, 253, 0.199), rgba(87, 104, 253, 0.199)), url(/images/ui/frame_box.png)'
							border='none'
							fontSize={'2em'}
						/>
					</div>
					<Text className={'crafting-item-exp' + (craftingCount == 0 ? ' red' : '')} margin='0'>
						Per Craft: {experiencePerCraft.toLocaleString('en-us')}{' '}
						<Image src='/images/total_level.png' alt='Experience' width='20px' height='20px' />
					</Text>
					{craftingCount > 1 && (
						<div className='crafting-item-exp'>
							Total: {(experiencePerCraft * craftingCount).toLocaleString('en-us')}{' '}
							<Image src='/images/total_level.png' alt='Experience' width='20px' height='20px' />
						</div>
					)}
					<div className='crafting-item-description'>{description}</div>
				</div>
				<div className='crafting-options'>
					<div className='crafting-options-requirements-container'>
						{craftingRecipes.length > 1 && (
							<div className='nav-tab-container'>
								{craftingRecipes.map((recipe, index) => (
									<div
										key={index}
										className={
											'nav-tab-flex nav-tab' + (selectedRecipe == index ? ' selected-tab' : '')
										}
										onClick={() => setSelectedRecipe(index)}
									>
										{index + 1}
									</div>
								))}
							</div>
						)}
						<IdlescapeFrameBox variant='dark' padding='8px 15px' width='100%' _hover={{}}>
							<ResourceCost resourceCosts={resourceCosts} amount={craftingCount} />
						</IdlescapeFrameBox>
					</div>
					<div className='crafting-option-row'>
						<IdlescapeNumberInput
							className='crafting-option-count'
							name='craftCount'
							id='craftCount'
							min={0}
							max={10000000000}
							step={1}
							value={craftingCount}
							setValue={setCraftingCount}
							flex='1'
							background='rgba(0, 0, 0, 0.6)'
						/>

						<IdlescapeButton variant='blue' onClick={() => setCraftingCount(maxCrafted)} size='small'>
							Max
						</IdlescapeButton>
					</div>
					<Flex justifyContent='center'>
						<IdlescapeButton
							className={animating ? 'craft-animation' : ''}
							onClick={() => attemptCraft()}
							variant={
								craftingCount > maxCrafted || maxCrafted == 0 || isLocked()
									? 'disabled'
									: animating
									? 'orange'
									: 'green'
							}
						>
							{animating ? 'Crafting...' : 'Craft'}
						</IdlescapeButton>
					</Flex>
				</div>
			</div>
		</IdlescapeContainer>
	);
}
