import { Box, Flex, Image } from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React, { useEffect, useState } from 'react';
import { IItem, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { IQuestStep } from '../../../../../game-server/src/modules/quest/Quest.interface';
import { formatNumberToString } from '../../../helper/helperFunctions';
import { usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import { Item } from '../Inventory/Item';
import SelectionTooltipBox from '../Tooltips/Types/SelectionTooltipBox';

export default function QuestItemTurnIn({
	questID,
	stepID,
	requirement,
	allowTurnIn,
	amount,
	setAmount,
}: {
	questID: number;
	stepID: number;
	requirement: IQuestStep['validTargets'][0];
	allowTurnIn: boolean;
	amount: number;
	setAmount: (amount: number) => void;
}) {
	const stockpile = usePlayerField('stockpile');
	const [turnInItem, setTurnInItem] = useState<IItem | null>(null);

	const filteredStockpile = stockpile.filter((item) => item.itemID === requirement.targetID);
	const totalStackSize = turnInItem
		? turnInItem.stackSize
		: filteredStockpile.reduce((acc: number, item: IItem) => acc + item.stackSize, 0);
	const here: TPartialItemWithItemID<IItem> = turnInItem ?? {
		itemID: requirement.targetID,
	};

	useEffect(() => {
		const item = turnInItem ? stockpile.find((item) => item.id === turnInItem.id) : null;
		if (!item) {
			if (filteredStockpile.length === 1) {
				// If there is exactly one item that matches the requirement, set it as the turn in item
				setTurnInItem(filteredStockpile[0]);
			} else {
				setTurnInItem(null);
			}
		} else {
			setTurnInItem(item);
		}
	}, [turnInItem, filteredStockpile]);

	function turnInItems(questID: number, stepID: number, itemID: number) {
		if (!turnInItem) return;
		if (amount <= 0 || itemID <= 0) return;
		const capped = Math.min(amount, turnInItem.stackSize);
		const newAmount = Math.min(amount, turnInItem.stackSize - capped);
		setAmount(newAmount);
		socket.emit('quest:turnInItems', { questID, stepID, itemID, amount: capped, id: turnInItem.id });
	}

	return (
		<Flex margin='5px' flexDirection='column' alignItems='center' textAlign='center' maxWidth='100px'>
			<Box position='relative'>
				<SelectionTooltipBox items={filteredStockpile} clickItem={setTurnInItem} showEmpty={false}>
					<Item item={here} minAmount={1} />
				</SelectionTooltipBox>
				<div>
					<Image
						src='/images/ui/inventory_icon.png'
						alt='inventory'
						line-height='40px'
						justifySelf='right'
						width='16px'
						height='16px'
						display='inline-block'
						verticalAlign='middle'
					/>
					{formatNumberToString(totalStackSize)}
				</div>
			</Box>
			{requirement.progressMult ? `(${requirement.progressMult}x)` : ''}
			<br />
			{allowTurnIn && (
				<IdlescapeButton
					size='small'
					padding='0 20px'
					variant={turnInItem ? 'green' : 'disabled'}
					onClick={() => turnInItems(questID, stepID, requirement.targetID)}
				>
					{turnInItem ? 'Turn In' : filteredStockpile.length === 0 ? 'No Items' : 'Select Item'}
				</IdlescapeButton>
			)}
		</Flex>
	);
}
