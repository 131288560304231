import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer } from '@idlescape/ui';
import React from 'react';
import { IGroupPlayerClientData } from '../../../../../game-server/src/modules/group/Group';
import { useAppSelector, usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import AllItems from '../AllItems2';
import CombatAvatarMugshot from '../Combat/CombatAvatarMugshot';
import GroupInvites from './GroupInvites';

export const GroupContent = () => {
	const { groupData } = useAppSelector((state) => state.group);
	const playerId = usePlayerField('id');
	const playerSettings = usePlayerField('settings');

	const groupSize = groupData?.groupMemberData?.length | 0;

	function handleKickPlayer(userId: number) {
		socket.emit('group:kick', { userId });
	}

	function handlePromotePlayer(userId: number) {
		socket.emit('group:promote', { userId });
	}

	function handleLeaveGroup() {
		socket.emit('group:leave');
	}

	function handleReadyGroup() {
		socket.emit('group:ready');
	}

	function handleRefresh() {
		socket.emit('group:refresh');
	}

	function renderLeaderOptions(elem: IGroupPlayerClientData) {
		if (groupData.leaderId !== playerId) return null;
		if (elem.id === playerId) return null;

		return (
			<Flex position='absolute' top='100%' width='100%' textAlign='center' justifyContent='space-between'>
				<Text
					as='span'
					color='green'
					cursor='pointer'
					onClick={() => handlePromotePlayer(elem.id)}
					_hover={{
						fontWeight: 'bold',
						textDecoration: 'underline',
					}}
				>
					Promote
				</Text>
				<span> | </span>
				<Text
					as='span'
					color='red'
					cursor='pointer'
					onClick={() => handleKickPlayer(elem.id)}
					_hover={{
						fontWeight: 'bold',
						textDecoration: 'underline',
					}}
				>
					Kick
				</Text>
			</Flex>
		);
	}

	function renderEmptySlots() {
		const maxSize = groupSize > 5 ? groupData.maxSize : 5;
		const emptiesToRender = maxSize - groupSize - 1;

		const emptySlots: React.ReactElement[] = [];
		for (let i = 0; i <= emptiesToRender; i++) {
			emptySlots.push(
				<Box
					key={'group-empty-slot-' + i}
					backgroundColor='rgba(0, 0, 0, 0.26)'
					height='100px'
					borderRadius='5px'
					position='relative'
				>
					<div className='group-panel-member-username'>{'Empty'}</div>
				</Box>
			);
		}
		return emptySlots;
	}

	function renderGroup() {
		return (
			<IdlescapeContainer
				as={Grid}
				width='100%'
				margin='0 auto'
				gridTemplateColumns='repeat(auto-fit, 100px)'
				justifyContent='space-around'
			>
				{groupData.groupMemberData.map((member) => {
					return (
						<Flex
							key={'group-member-' + member.id}
							flexDirection='column'
							justifyContent='space-between'
							height='100px'
							marginBottom='17px'
							borderRadius='5px'
							position='relative'
							backgroundColor={
								member.id === playerId ? 'rgba(148, 148, 148, 0.329)' : 'rgba(0, 0, 0, 0.26);'
							}
							boxShadow={member.ready ? '0 0 10px #6aff45' : '0 0 10px #ff4545'}
						>
							{member.equipment && (
								<CombatAvatarMugshot
									equipment={member.equipment}
									cosmetics={member.selectedCosmetics ?? {}}
								/>
							)}

							<div className='group-panel-member-username'>
								{groupData.leaderId === member.id && (
									<img
										className='message-username-icon'
										alt='group leader'
										src='/images/chaticons/leader-icon.png'
									/>
								)}
								{member.username}
							</div>
							<Box textAlign='center' position='relative' backgroundColor='rgba(0, 0, 0, 0.582)'>
								{member.currentHealth} / {member.combatStats?.maxHealth}
							</Box>
							{renderLeaderOptions(member)}
						</Flex>
					);
				})}
				{renderEmptySlots()}
			</IdlescapeContainer>
		);
	}

	function renderGroupInterface() {
		const amIReady = groupData.groupMemberData.some((member) => member.id === playerId && member.ready);

		return (
			<Box className='group-panel-container'>
				{renderGroup()}
				{/* <div className='group-panel-invited-player-notification'>
						{this.state.response !== {} && (
							<div>
								<span className={'group-panel-invite-response-' + this.state.response.type}>
									{this.state.response.message}
								</span>
							</div>
						)}
					</div> */}
				<Flex justifyContent='center' gap='15px'>
					<IdlescapeButton variant={amIReady ? 'orange' : 'green'} size='medium' onClick={handleReadyGroup}>
						{amIReady ? 'Not Ready!' : 'Ready Up!'}
					</IdlescapeButton>
					<IdlescapeButton
						variant={groupData.groupMemberData.length === 1 ? 'disabled' : 'red'}
						size='medium'
						onClick={handleLeaveGroup}
					>
						Leave Group
					</IdlescapeButton>
				</Flex>
				<GroupInvites />
				{playerSettings.combat.showInventoryInGroup && (
					<AllItems hideCurrency defaultSearch='equipment|dungeon|elite|consumable' />
				)}
			</Box>
		);
	}

	if (groupSize === 0) {
		return (
			<h3>
				Waiting for group data. If you are certain you have a group, try pressing the button below!
				<IdlescapeButton variant='red' onClick={handleRefresh}>
					Force Group Data Refresh
				</IdlescapeButton>
			</h3>
		);
	} else {
		return renderGroupInterface();
	}
};
