import { io, Socket } from 'socket.io-client';
import { IClientToServerEvents, IServerToClientEvents } from '../../../game-server/src/modules/io/io.events';
import axios from 'axios';

const fetchJwtToken = async () => {
	try {
		const response = await axios.get('/api/auth/get-token', { withCredentials: true });
		return response.data.token; // Assuming the token is in the response data
	} catch (error) {
		console.error('Error fetching JWT token:', error);
		return null;
	}
};

export let socket: Socket<IServerToClientEvents, IClientToServerEvents>;

export const initiateSocketConnection = async () => {
	console.log('Initiating socket connection...');
	const token = await fetchJwtToken();
	if (!token) {
		console.error('Failed to retrieve JWT token');
		return false;
	}

	const CONFIG = {
		query: { token, characterID: sessionStorage.characterID },
		reconnection: true,
		forceNew: true,
	};

	socket = io(CONFIG);

	console.log('Connecting socket...');
	return true;
};

export const getSocket = () => {
	return socket;
};

/* export const socket; */
