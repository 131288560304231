/* eslint-disable turbo/no-undeclared-env-vars */
import { clientType } from 'idlescape-globals';
import { NavigateFunction } from 'react-router-dom';

export const gameUrls = {
	game: '/',
	login: `${process.env.REACT_APP_PUBLIC_LANDING_URL}/login`,
	landing: `${process.env.REACT_APP_PUBLIC_LANDING_URL}`,
	characters: '/characters',
	verificationComplete: '/verification-completed',
	verificationFailed: '/verification-failed',
	platinumSuccess: '/payment-successful-platinum',
	bondSuccess: '/payment-successful-bond',
	subscriptionSuccess: '/payment-successful-subscription',
	paymentFail: '/payment-failed',
	recoverPassword: `${process.env.REACT_APP_PUBLIC_LANDING_URL}/recover-password`,
	steam: 'https://store.steampowered.com/app/2692420/Idlescape/',
	news: `${process.env.REACT_APP_PUBLIC_LANDING_URL}/news/`,
	patchnotes: `${process.env.REACT_APP_PUBLIC_LANDING_URL}/patchnotes/`,
	rules: `${process.env.REACT_APP_PUBLIC_LANDING_URL}/rules/`,
	wiki: 'https://wiki.idlescape.com/',
	discord: 'https://discord.gg/RjgxPaDzhx', //`${process.env.REACT_APP_PUBLIC_LANDING_URL}/discord/`
	reddit: 'https://www.reddit.com/r/Idlescape/',
	youtube: 'https://www.youtube.com/@IdlescapeGame',
	twitter: 'https://x.com/idlescape',
	instagram: 'https://www.instagram.com/idlescapegame/',
	tiktok: 'https://www.tiktok.com/@idlescapegame',
	modApplication: `${process.env.REACT_APP_PUBLIC_LANDING_URL}/mod-application`,
};

const currentClient = clientType || 'web';
switch (currentClient) {
	case 'steam':
		gameUrls.login = '/login';
		break;
	case 'web':
		break;
	case 'app':
		gameUrls.login = '/login';
		break;
	default:
}

export function openGameUrl(link: keyof typeof gameUrls, options?: { navigate?: NavigateFunction; openNew?: boolean }) {
	const url = gameUrls[link];
	const absoluteURL = new URL(url, window.location.href).href;
	if (absoluteURL === window.location.href) {
		// We are already here so nothing to do
		return;
	}
	const externalLink = url.startsWith('http') || url.startsWith('localhost');
	// Deselect character
	if (link === 'characters' || link === 'landing') {
		sessionStorage.removeItem('characterID');
	}
	// External links and opening the link in a new tab doesn't work with navigate
	if (!externalLink && !options?.openNew && options?.navigate) {
		options.navigate(url);
		return;
	}
	window.open(url, options?.openNew ? '_blank' : '_self');
}
