// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { ILocationData, TLocationListClient } from '../../../game-server/src/modules/locations/location.interface';
import { ActionTypes } from './enums/ActionTypes';
import { monstersIds } from '../utils/lookup-dictionaries/lookupMonsterList';
import { locationsIds } from '../utils/lookup-dictionaries/lookupLocationList';
import { enchantmentsIds } from '../utils/lookup-dictionaries/lookupEnchantmentList';
import { chatIconsIds } from '../utils/lookup-dictionaries/lookupChatIconList';

export const locations: TLocationListClient = {
	5: {
		name: 'The Void',
		locID: 5,
		actionType: ActionTypes.Broken,
		baseDuration: 5000,
		activeMessage: 'In the Void',
		locationImage: '',
		relatedKey: 8008,
	},
	6: {
		name: 'The Forges',
		locID: 6,
		actionType: ActionTypes.Smithing,
		baseDuration: 5000,
		activeMessage: 'Smithing at the Forges',
		locationImage: '/images/smithing/city_forge.png',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'smithing',
					level: 1,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
	},
	7: {
		name: 'The Cooking Guild',
		locID: 7,
		actionType: ActionTypes.Cooking,
		baseDuration: 5000,
		activeMessage: 'Cooking in the Cooking Guild',
		locationImage: '/images/cooking/cooking_screen_cropped.png',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'cooking',
					level: 1,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
	},
	8: {
		name: 'The Academy of Augmentation',
		locID: 8,
		actionType: ActionTypes.Enchanting,
		baseDuration: 5000,
		activeMessage: 'Augmenting in the Academy',
		locationImage: '/images/enchanting/augmenting_logo.png',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'enchanting',
					level: 1,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
	},
	9: {
		name: 'The Runecrafting Shrines',
		locID: 9,
		actionType: ActionTypes.Runecrafting,
		baseDuration: 5000,
		activeMessage: 'Runecrafting at the Shrines',
		locationImage: 'images/runecrafting/RuneCraftingIcon.png',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'runecrafting',
					level: 1,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
	},
	10: {
		name: 'The Preparation Halls',
		locID: 10,
		actionType: ActionTypes.Cooking,
		baseDuration: 5000,
		activeMessage: 'Preparing at the Halls',
		locationImage: '/images/cooking/cooking_screen_cropped.png',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'cooking',
					level: 1,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
	},
	11: {
		name: 'The College of Scrollcrafting',
		locID: 11,
		actionType: ActionTypes.Enchanting,
		baseDuration: 5000,
		activeMessage: 'Creating scrolls',
		locationImage: 'images/enchanting/scroll.png',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'enchanting',
					level: 1,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
	},
	12: {
		name: 'The Institute of Research',
		locID: 12,
		actionType: ActionTypes.Enchanting,
		baseDuration: 5000,
		activeMessage: 'Conducting Item Research',
		locationImage: 'images/runecrafting/dust/gear_scrap_common.png',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'enchanting',
					level: 1,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
	},
	13: {
		name: 'The Cloth Weaving Tables',
		locID: 13,
		actionType: ActionTypes.Runecrafting,
		baseDuration: 5000,
		activeMessage: 'Weaving Cloth',
		locationImage: 'images/runecrafting/RuneCraftingIcon.png',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'runecrafting',
					level: 1,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
	},
	50: {
		name: 'Clay Pit',
		locID: 50,
		actionType: ActionTypes.Mining,
		actionVerb: 'Mine',
		baseDuration: 5000,
		activeMessage: 'Mining in the Clay Pit',
		locationImage: '/images/mining/clay_pit.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'mining',
					level: 1,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		xpPerCompletion: [
			{
				skill: 'mining',
				amount: 5,
			},
		],
	},
	51: {
		name: 'City Outskirts Mine',
		locID: 51,
		actionType: ActionTypes.Mining,
		actionVerb: 'Mine',
		baseDuration: 8000,
		activeMessage: 'Mining in the City Outskirts Mine',
		locationImage: '/images/mining/city_outskirts_quarry.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'mining',
					level: 10,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		xpPerCompletion: [
			{
				skill: 'mining',
				amount: 10,
			},
		],
	},
	52: {
		name: 'Village Quarry',
		locID: 52,
		actionType: ActionTypes.Mining,
		actionVerb: 'Mine',
		baseDuration: 8000,
		activeMessage: 'Mining in the Village Quarry',
		locationImage: '/images/mining/village_quarry.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'mining',
					level: 20,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		xpPerCompletion: [
			{
				skill: 'mining',
				amount: 15,
			},
		],
	},
	53: {
		name: 'Desert Mine',
		locID: 53,
		actionType: ActionTypes.Mining,
		actionVerb: 'Mine',
		baseDuration: 12000,
		activeMessage: 'Mining in the Desert Mine',
		locationImage: '/images/mining/desert_mine.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'mining',
					level: 30,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		xpPerCompletion: [
			{
				skill: 'mining',
				amount: 30,
			},
		],
	},
	54: {
		name: 'Underground Mine',
		locID: 54,
		actionType: ActionTypes.Mining,
		actionVerb: 'Mine',
		baseDuration: 12000,
		activeMessage: 'Mining in the Underground Mine',
		locationImage: '/images/mining/underground_mine.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'mining',
					level: 40,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'Keep an eye out for hamsters!',
		xpPerCompletion: [
			{
				skill: 'mining',
				amount: 40,
			},
		],
	},
	55: {
		name: 'Hidden Mine',
		locID: 55,
		actionType: ActionTypes.Mining,
		actionVerb: 'Mine',
		baseDuration: 15000,
		activeMessage: 'Mining in the Hidden Mine',
		locationImage: '/images/mining/hidden_mine.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'mining',
					level: 50,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		xpPerCompletion: [
			{
				skill: 'mining',
				amount: 60,
			},
		],
	},
	56: {
		name: 'Volcano Mine',
		locID: 56,
		actionType: ActionTypes.Mining,
		actionVerb: 'Mine',
		baseDuration: 15000,
		activeMessage: 'Mining in the Volcano Mine',
		locationImage: '/images/mining/volcano_mine.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'mining',
					level: 60,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		xpPerCompletion: [
			{
				skill: 'mining',
				amount: 70,
			},
		],
	},
	57: {
		name: 'Slate Spires',
		locID: 57,
		actionType: ActionTypes.Mining,
		actionVerb: 'Mine',
		baseDuration: 13500,
		activeMessage: 'Mining at the Slate Spires',
		locationImage: '/images/mining/slate_spires.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'mining',
					level: 45,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		xpPerCompletion: [
			{
				skill: 'mining',
				amount: 40,
			},
		],
	},
	80: {
		name: 'Deep Pit',
		locID: 80,
		actionType: ActionTypes.Mining,
		actionVerb: 'Mine',
		baseDuration: 20000,
		activeMessage: 'Mining in the Deep Pit',
		locationImage: '/images/mining/deep_pit.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'mining',
					level: 70,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'This is a dangerous location! Has a higher chance to find greater geodes.',
		dangerousGatheringZoneChance: 0.01,
		dangerousGatheringZoneMonster: [
			{
				monsterID: 12,
			},
			{
				monsterID: 502,
				dangerZoneEncounter: true,
			},
			{
				monsterID: 504,
				dangerZoneEncounter: true,
			},
		],
		xpPerCompletion: [
			{
				skill: 'mining',
				amount: 100,
			},
		],
	},
	101: {
		name: 'Grasslands',
		locID: 101,
		actionType: ActionTypes.Foraging,
		actionVerb: 'Forage',
		baseDuration: 5000,
		activeMessage: 'Foraging in the Grasslands',
		locationImage: '/images/foraging/grasslands.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'foraging',
					level: 1,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		nodes: [
			{
				nodeID: 'The Grasslands',
				frequency: 5,
				maxFrequency: 5,
				minimumBaseAmount: 1,
				maximumBaseAmount: 16,
			},
			{
				nodeID: 'A Tree',
				frequency: 15,
				maxFrequency: 33,
				minimumBaseAmount: 16,
				maximumBaseAmount: 32,
				tags: ['tree'],
			},
			{
				nodeID: 'A Large Tree',
				frequency: 0,
				maxFrequency: 16,
				minimumBaseAmount: 16,
				maximumBaseAmount: 32,
				tags: ['tree'],
			},
			{
				nodeID: 'Grassy Vegetables',
				frequency: 1,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['plants'],
			},
			{
				nodeID: 'Wildberry Bushes',
				frequency: 1,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['plants'],
			},
			{
				nodeID: 'Grassy Seeds',
				frequency: 2,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['seeds'],
			},
			{
				nodeID: 'Small Game Hunt',
				frequency: 0,
				maxFrequency: 5,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['hunting'],
			},
		],
		xpPerCompletion: [
			{
				skill: 'foraging',
				amount: 5,
			},
		],
	},
	102: {
		name: 'Verdant Valley',
		locID: 102,
		actionType: ActionTypes.Foraging,
		actionVerb: 'Forage',
		baseDuration: 8000,
		activeMessage: 'Foraging in the Verdant Valley',
		locationImage: '/images/foraging/verdant_valley.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'foraging',
					level: 10,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		nodes: [
			{
				nodeID: 'The Verdant Valley',
				frequency: 5,
				maxFrequency: 5,
				minimumBaseAmount: 1,
				maximumBaseAmount: 16,
			},
			{
				nodeID: 'A Tree',
				frequency: 10,
				maxFrequency: 15,
				minimumBaseAmount: 16,
				maximumBaseAmount: 32,
				tags: ['tree'],
			},
			{
				nodeID: 'Oak Tree',
				frequency: 10,
				maxFrequency: 25,
				minimumBaseAmount: 16,
				maximumBaseAmount: 32,
				tags: ['tree'],
			},
			{
				nodeID: 'Copse of Verdant Trees',
				frequency: 0,
				maxFrequency: 16,
				minimumBaseAmount: 16,
				maximumBaseAmount: 32,
				tags: ['tree'],
			},
			{
				nodeID: 'Verdant Vegetables',
				frequency: 1,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 12,
				tags: ['plants'],
			},
			{
				nodeID: 'Wild Wheat',
				frequency: 1,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 12,
				tags: ['plants'],
			},
			{
				nodeID: 'Verdant Seeds',
				frequency: 5,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 12,
				tags: ['seeds'],
			},
			{
				nodeID: 'Verdant Reagents',
				frequency: 1,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 12,
				tags: ['plants'],
			},
			{
				nodeID: 'Valley Hunting',
				frequency: 0,
				maxFrequency: 5,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['hunting'],
			},
		],
		xpPerCompletion: [
			{
				skill: 'foraging',
				amount: 10,
			},
		],
	},
	103: {
		name: 'Fungal Grotto',
		locID: 103,
		actionType: ActionTypes.Foraging,
		actionVerb: 'Forage',
		baseDuration: 8000,
		activeMessage: 'Foraging in the Fungal Grotto',
		locationImage: '/images/foraging/fungal_grotto.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'foraging',
					level: 20,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		nodes: [
			{
				nodeID: 'The Fungal Grotto',
				frequency: 5,
				maxFrequency: 5,
				minimumBaseAmount: 1,
				maximumBaseAmount: 16,
			},
			{
				nodeID: 'Lots of Fungi',
				frequency: 1,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 16,
				tags: ['plants'],
			},
			{
				nodeID: "Carrots and 'Taters",
				frequency: 1,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 16,
				tags: ['plants'],
			},
			{
				nodeID: 'A Seedy Place',
				frequency: 5,
				maxFrequency: 25,
				minimumBaseAmount: 1,
				maximumBaseAmount: 8,
				tags: ['seeds'],
			},
			{
				nodeID: 'Lone Swamp Tree',
				frequency: 0,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 32,
				tags: ['tree'],
			},
			{
				nodeID: 'Grotto Hunt',
				frequency: 0,
				maxFrequency: 5,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['hunting'],
			},
		],
		xpPerCompletion: [
			{
				skill: 'foraging',
				amount: 15,
			},
		],
	},
	104: {
		name: 'The Tangle',
		locID: 104,
		actionType: ActionTypes.Foraging,
		actionVerb: 'Forage',
		baseDuration: 12000,
		activeMessage: 'Foraging in The Tangle',
		locationImage: '/images/foraging/the_tangle.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'foraging',
					level: 30,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		nodes: [
			{
				nodeID: 'The Tangle',
				frequency: 5,
				maxFrequency: 5,
				minimumBaseAmount: 1,
				maximumBaseAmount: 16,
			},
			{
				nodeID: 'Sweet Tooth',
				frequency: 5,
				maxFrequency: 10,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['plants'],
			},
			{
				nodeID: 'All Tangled Up',
				frequency: 15,
				maxFrequency: 30,
				minimumBaseAmount: 16,
				maximumBaseAmount: 64,
				tags: ['tree'],
			},
			{
				nodeID: 'A Little Chili',
				frequency: 2,
				maxFrequency: 16,
				minimumBaseAmount: 2,
				maximumBaseAmount: 10,
				tags: ['plants'],
			},
			{
				nodeID: 'Tangle of Seeds',
				frequency: 5,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 12,
				tags: ['seeds'],
			},
			{
				nodeID: 'Tangled Reagents',
				frequency: 2,
				maxFrequency: 16,
				minimumBaseAmount: 2,
				maximumBaseAmount: 10,
				tags: ['plants'],
			},
			{
				nodeID: 'Uprooting Pests',
				frequency: 0,
				maxFrequency: 5,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['hunting'],
			},
		],
		xpPerCompletion: [
			{
				skill: 'foraging',
				amount: 30,
			},
		],
	},
	105: {
		name: 'Misty Marsh',
		locID: 105,
		actionType: ActionTypes.Foraging,
		actionVerb: 'Forage',
		baseDuration: 12000,
		activeMessage: 'Foraging in the Misty Marsh',
		locationImage: '/images/foraging/misty_marsh.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'foraging',
					level: 40,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		nodes: [
			{
				nodeID: 'The Misty Marsh',
				frequency: 5,
				maxFrequency: 5,
				minimumBaseAmount: 1,
				maximumBaseAmount: 16,
			},
			{
				nodeID: 'Misty Trees',
				frequency: 5,
				maxFrequency: 20,
				minimumBaseAmount: 12,
				maximumBaseAmount: 36,
				tags: ['tree'],
			},
			{
				nodeID: 'Misty Plants',
				frequency: 1,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 12,
				tags: ['plants'],
			},
			{
				nodeID: 'Misty Seeds',
				frequency: 5,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 12,
				tags: ['seeds'],
			},
			{
				nodeID: 'Smelly Swamp',
				frequency: 0,
				maxFrequency: 5,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['hunting'],
			},
		],
		xpPerCompletion: [
			{
				skill: 'foraging',
				amount: 40,
			},
		],
	},
	106: {
		name: 'Frozen Tundra',
		locID: 106,
		actionType: ActionTypes.Foraging,
		actionVerb: 'Forage',
		baseDuration: 15000,
		activeMessage: 'Foraging in the Frozen Tundra',
		locationImage: '/images/foraging/frozen_tundra.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'foraging',
					level: 50,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		nodes: [
			{
				nodeID: 'The Frozen Tundra',
				frequency: 15,
				maxFrequency: 15,
				minimumBaseAmount: 8,
				maximumBaseAmount: 32,
			},
			{
				nodeID: 'Thawed Oasis',
				frequency: 0,
				maxFrequency: 36,
				minimumBaseAmount: 8,
				maximumBaseAmount: 32,
				tags: ['seeds', 'plants', 'tree'],
			},
			{
				nodeID: 'Squirrel Cache',
				frequency: 0,
				maxFrequency: 36,
				minimumBaseAmount: 8,
				maximumBaseAmount: 12,
				tags: ['seeds'],
			},
			{
				nodeID: 'Frozen Reagents',
				frequency: 0,
				maxFrequency: 15,
				minimumBaseAmount: 2,
				maximumBaseAmount: 10,
				tags: ['plants'],
			},
			{
				nodeID: 'Big Game Hunting',
				frequency: 0,
				maxFrequency: 5,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['hunting'],
			},
		],
		xpPerCompletion: [
			{
				skill: 'foraging',
				amount: 60,
			},
		],
	},
	107: {
		name: 'Haunted Woods',
		locID: 107,
		actionType: ActionTypes.Foraging,
		actionVerb: 'Forage',
		baseDuration: 15000,
		activeMessage: 'Foraging in the Haunted Woods',
		locationImage: '/images/foraging/haunted_woods.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'foraging',
					level: 60,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'Stories of these woods appear to greatly exaggerate its haunted nature.',
		nodes: [
			{
				nodeID: 'The Haunted Woods',
				frequency: 5,
				maxFrequency: 5,
				minimumBaseAmount: 1,
				maximumBaseAmount: 16,
			},
			{
				nodeID: "Woods' Bounty",
				frequency: 3,
				maxFrequency: 20,
				minimumBaseAmount: 1,
				maximumBaseAmount: 16,
				tags: ['plants'],
			},
			{
				nodeID: 'Gnarled Trees',
				frequency: 3,
				maxFrequency: 30,
				minimumBaseAmount: 4,
				maximumBaseAmount: 16,
				tags: ['seeds', 'tree'],
			},
			{
				nodeID: 'Haunted Seeds',
				frequency: 5,
				maxFrequency: 20,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['seeds'],
			},
			{
				nodeID: 'Haunted Reagents',
				frequency: 1,
				maxFrequency: 16,
				minimumBaseAmount: 4,
				maximumBaseAmount: 16,
				tags: ['plants'],
			},
			{
				nodeID: 'Hidden Predators',
				frequency: 0,
				maxFrequency: 5,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['hunting'],
			},
		],
		xpPerCompletion: [
			{
				skill: 'foraging',
				amount: 70,
			},
		],
	},
	108: {
		name: 'Living Forest',
		locID: 108,
		actionType: ActionTypes.Foraging,
		actionVerb: 'Forage',
		baseDuration: 20000,
		activeMessage: 'Foraging in the Living Forest',
		locationImage: '/images/foraging/living_forest.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'foraging',
					level: 70,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: "This is a dangerous location! Has a higher chance to find greater bird's nests.",
		nodes: [
			{
				nodeID: 'The Living Forest',
				frequency: 20,
				maxFrequency: 20,
				minimumBaseAmount: 1,
				maximumBaseAmount: 16,
			},
			{
				nodeID: 'Healthy Living',
				frequency: 15,
				maxFrequency: 45,
				minimumBaseAmount: 4,
				maximumBaseAmount: 12,
				tags: ['plants', 'seeds'],
			},
			{
				nodeID: 'The Young Trees',
				frequency: 10,
				maxFrequency: 30,
				minimumBaseAmount: 8,
				maximumBaseAmount: 24,
				tags: ['tree'],
			},
			{
				nodeID: 'The Elder Tree',
				frequency: 0,
				maxFrequency: 30,
				minimumBaseAmount: 8,
				maximumBaseAmount: 16,
				tags: ['tree'],
			},
			{
				nodeID: 'Living Reagents',
				frequency: 0,
				maxFrequency: 30,
				minimumBaseAmount: 4,
				maximumBaseAmount: 12,
				tags: ['plants'],
			},
			{
				nodeID: 'Living Seeds',
				frequency: 0,
				maxFrequency: 30,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['seeds'],
			},
			{
				nodeID: 'Poaching',
				frequency: 0,
				maxFrequency: 5,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				tags: ['hunting'],
			},
		],
		xpPerCompletion: [
			{
				skill: 'foraging',
				amount: 100,
			},
		],
		dangerousGatheringZoneChance: 0.05,
		dangerousGatheringZoneMonster: [
			{
				monsterID: 25,
			},
			{
				monsterID: 508,
				dangerZoneEncounter: true,
			},
		],
	},
	151: {
		name: 'Shallow Pond',
		locID: 151,
		actionType: ActionTypes.Fishing,
		actionVerb: 'Fish',
		baseDuration: 8500,
		activeMessage: 'Fishing in the Shallow Pond',
		locationImage: '/images/fishing/shallow_pond.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'fishing',
					level: 1,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		nodes: [
			{
				nodeID: 'The Shallow Pond',
				frequency: 100,
				maxFrequency: 150,
				minimumBaseAmount: 2,
				maximumBaseAmount: 4,
			},
			{
				nodeID: 'Oops, All Shrimp!',
				frequency: 50,
				maxFrequency: 100,
				minimumBaseAmount: 5,
				maximumBaseAmount: 12,
			},
			{
				nodeID: 'Small Schools of Fish',
				frequency: 15,
				maxFrequency: 65,
				minimumBaseAmount: 2,
				maximumBaseAmount: 8,
				xpMultiplier: 1.1,
				tags: ['fish'],
			},
			{
				nodeID: 'Drifting Around',
				frequency: 15,
				minimumBaseAmount: 2,
				maximumBaseAmount: 8,
				maxFrequency: 100,
				xpMultiplier: 1.1,
				tags: ['driftwood'],
			},
			{
				nodeID: 'Shallow Flora',
				frequency: 5,
				minimumBaseAmount: 2,
				maximumBaseAmount: 8,
				maxFrequency: 50,
				xpMultiplier: 1.2,
				tags: ['fiber'],
			},
		],
		xpPerCompletion: [
			{
				skill: 'fishing',
				amount: 20,
			},
		],
	},
	152: {
		name: 'Lazy River',
		locID: 152,
		actionType: ActionTypes.Fishing,
		actionVerb: 'Fish',
		baseDuration: 9500,
		activeMessage: 'Fishing in the Lazy River',
		locationImage: '/images/fishing/lazy_river.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'fishing',
					level: 20,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		nodes: [
			{
				nodeID: 'The Lazy River',
				frequency: 100,
				minimumBaseAmount: 2,
				maximumBaseAmount: 4,
				maxFrequency: 150,
			},
			{
				nodeID: 'Migrating Schools',
				frequency: 25,
				minimumBaseAmount: 2,
				maximumBaseAmount: 8,
				maxFrequency: 75,
				tags: ['fish'],
			},
			{
				nodeID: 'ANGY bois',
				frequency: -25,
				minimumBaseAmount: 1,
				maximumBaseAmount: 4,
				maxFrequency: 25,
				xpMultiplier: 1.2,
				tags: ['fish'],
			},
			{
				nodeID: 'Riverbed Flora',
				frequency: 5,
				minimumBaseAmount: 2,
				maximumBaseAmount: 8,
				maxFrequency: 45,
				xpMultiplier: 1.3,
				tags: ['fiber'],
			},
			{
				nodeID: 'Drifting Debris',
				frequency: 5,
				minimumBaseAmount: 2,
				maximumBaseAmount: 8,
				maxFrequency: 45,
				xpMultiplier: 1.3,
				tags: ['driftwood'],
			},
		],
		xpPerCompletion: [
			{
				skill: 'fishing',
				amount: 30,
			},
		],
	},
	153: {
		name: 'Still Lake',
		locID: 153,
		actionType: ActionTypes.Fishing,
		actionVerb: 'Fish',
		baseDuration: 10500,
		activeMessage: 'Fishing in the Still Lake',
		locationImage: '/images/fishing/still_lake.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'fishing',
					level: 40,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		nodes: [
			{
				nodeID: 'The Still Lake',
				frequency: 100,
				minimumBaseAmount: 2,
				maximumBaseAmount: 4,
				maxFrequency: 125,
			},
			{
				nodeID: 'Big Catch!',
				frequency: -30,
				minimumBaseAmount: 2,
				maximumBaseAmount: 6,
				maxFrequency: 75,
				xpMultiplier: 1.2,
			},
			{
				nodeID: "Yedyod's Cove",
				frequency: -75,
				minimumBaseAmount: 1,
				maximumBaseAmount: 4,
				maxFrequency: 100,
				xpMultiplier: 1.3,
				tags: ['fish'],
			},
			{
				nodeID: 'Cage Match!',
				frequency: 3,
				minimumBaseAmount: 2,
				maximumBaseAmount: 6,
				maxFrequency: 100,
				xpMultiplier: 1.1,
				tags: ['fish'],
			},
			{
				nodeID: 'Lake Flora',
				frequency: 5,
				minimumBaseAmount: 2,
				maximumBaseAmount: 8,
				maxFrequency: 75,
				xpMultiplier: 1.2,
				tags: ['fiber'],
			},
			{
				nodeID: 'Capsized Boat',
				frequency: 1,
				minimumBaseAmount: 2,
				maximumBaseAmount: 8,
				maxFrequency: 50,
				xpMultiplier: 1.1,
				tags: ['driftwood'],
			},
		],
		xpPerCompletion: [
			{
				skill: 'fishing',
				amount: 60,
			},
		],
	},
	154: {
		name: 'Open Ocean',
		locID: 154,
		actionType: ActionTypes.Fishing,
		actionVerb: 'Fish',
		baseDuration: 11500,
		activeMessage: 'Fishing in the Open Ocean',
		locationImage: '/images/fishing/open_ocean.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'fishing',
					level: 60,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'WIP',
		nodes: [
			{
				nodeID: 'The Open Ocean',
				frequency: 100,
				minimumBaseAmount: 2,
				maximumBaseAmount: 4,
				maxFrequency: 150,
			},
			{
				nodeID: 'Sharks Ate All The Tuna',
				frequency: 15,
				minimumBaseAmount: 1,
				maximumBaseAmount: 2,
				maxFrequency: 75,
			},
			{
				nodeID: 'Almost Jurassic',
				frequency: -50,
				minimumBaseAmount: 2,
				maximumBaseAmount: 3,
				maxFrequency: 50,
				xpMultiplier: 1.2,
			},
			{
				nodeID: 'Is that a tentacle?',
				frequency: -100,
				minimumBaseAmount: 2,
				maximumBaseAmount: 3,
				maxFrequency: 50,
				xpMultiplier: 1.3,
				tags: ['fish'],
			},
			{
				nodeID: 'Carefree Day',
				frequency: 10,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				maxFrequency: 60,
				xpMultiplier: 1.1,
			},
			{
				nodeID: 'Shipwreck Bay',
				frequency: -25,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				maxFrequency: 100,
				xpMultiplier: 1.1,
				tags: ['driftwood'],
			},
			{
				nodeID: "Playtester's Paradise",
				frequency: -50,
				minimumBaseAmount: 1,
				maximumBaseAmount: 1,
				maxFrequency: 2,
				xpMultiplier: 1.2,
			},
			{
				nodeID: 'Ghost Ship',
				frequency: -75,
				minimumBaseAmount: 1,
				maximumBaseAmount: 8,
				maxFrequency: 4,
				xpMultiplier: 1.2,
				tags: ['driftwood'],
			},
			{
				nodeID: 'Ocean Flora',
				frequency: 5,
				minimumBaseAmount: 2,
				maximumBaseAmount: 8,
				maxFrequency: 75,
				xpMultiplier: 1.1,
				tags: ['fiber'],
			},
		],
		xpPerCompletion: [
			{
				skill: 'fishing',
				amount: 90,
			},
		],
	},
	155: {
		name: 'Stormy Seas',
		locID: 155,
		actionType: ActionTypes.Fishing,
		actionVerb: 'Fish',
		baseDuration: 11500,
		activeMessage: 'Fishing in the Stormy Seas',
		locationImage: '/images/fishing/stormy_fishing.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'fishing',
					level: 80,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
		extraTooltipInfo: 'This is a dangerous location! Has a higher chance to find ancient treasures.',
		nodes: [
			{
				nodeID: 'The Stormy Seas',
				frequency: 100,
				minimumBaseAmount: 2,
				maximumBaseAmount: 4,
				maxFrequency: 150,
			},
			{
				nodeID: 'Eye of the Storm',
				frequency: 25,
				minimumBaseAmount: 4,
				maximumBaseAmount: 8,
				maxFrequency: 100,
			},
			{
				nodeID: 'Endless Vortex',
				frequency: -75,
				minimumBaseAmount: 2,
				maximumBaseAmount: 8,
				maxFrequency: 15,
				xpMultiplier: 1.2,
			},
			{
				nodeID: "INFO's Stand",
				frequency: -100,
				minimumBaseAmount: 3,
				maximumBaseAmount: 9,
				maxFrequency: 5,
				xpMultiplier: 1.3,
				tags: ['driftwood', 'fish'],
			},
			{
				nodeID: "World's End",
				frequency: -50,
				minimumBaseAmount: 3,
				maximumBaseAmount: 7,
				maxFrequency: 5,
				xpMultiplier: 1.2,
			},
			{
				nodeID: 'Deep Sea Flora',
				frequency: 5,
				minimumBaseAmount: 2,
				maximumBaseAmount: 8,
				maxFrequency: 45,
				xpMultiplier: 1.1,
				tags: ['fiber'],
			},
		],
		xpPerCompletion: [
			{
				skill: 'fishing',
				amount: 100,
			},
		],
		dangerousGatheringZoneChance: 0.02,
		dangerousGatheringZoneMonster: [
			{
				monsterID: 22,
			},
			{
				monsterID: 23,
			},
			{
				monsterID: 515,
				dangerZoneEncounter: true,
			},
			{
				monsterID: 516,
				dangerZoneEncounter: true,
			},
		],
	},
	200: {
		name: 'Deep Pit Encounter',
		locID: 200,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting Monsters in the Deep Pit',
		locationImage: '/images/mining/deep_pit.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'mining',
					level: 70,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
	},
	201: {
		name: 'Stormy Seas Encounter',
		locID: 201,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting Monsters in the Stormy Seas',
		locationImage: '/images/fishing/stormy_fishing.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'fishing',
					level: 85,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
	},
	202: {
		name: 'Living Forest Encounter',
		locID: 202,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting Monsters in the Living Forest',
		locationImage: '/images/foraging/living_forest.jpg',
		accessRequirements: {
			requiredSkills: [
				{
					skill: 'foraging',
					level: 70,
				},
			],
			requiredItems: [],
			consumeRequiredItems: false,
			requiredEquipment: [],
			maxGroupSize: 1,
		},
	},
	1000: {
		name: 'Farm',
		locID: 1000,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Farm',
		locationImage: '/images/combat/locations/farm_3.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.25)',
			backgroundSize: '100% 150%',
			backgroundPosition: '80% 40%',
		},
		accessRequirements: {
			maxGroupSize: 1,
		},
		extraTooltipInfo:
			'1-5+|Any|A peaceful farm with animals and a handful of monsters. Good for the novice adventurer first learning how to fight.',
	},
	1001: {
		name: 'Caves',
		locID: 1001,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Caves',
		locationImage: '/images/combat/locations/cave_2.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.25)',
			backgroundSize: '100% 150%',
			backgroundPosition: '80% 70%',
		},
		accessRequirements: {
			maxGroupSize: 1,
		},
		extraTooltipInfo:
			'5-10+|Any|Caves and dens of monsters may be found while in the wilderness. This close to civilization they are likely only populated by weak monsters, like imps or goblins.',
	},
	1002: {
		name: 'City',
		locID: 1002,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the City',
		locationImage: '/images/combat/locations/city_2.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)',
			backgroundSize: '100% 150%',
			backgroundPosition: '80% 0',
		},
		accessRequirements: {
			maxGroupSize: 1,
		},
		extraTooltipInfo:
			'10-20+|Magic|Human guardsmen and militia patrol the city to deal with criminals. Sometimes they are aided by some artificial constructs that control sets of armor. Any amateur adventurer should be able to handle themselves well here.',
	},
	1003: {
		name: 'Lava Maze',
		locID: 1003,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Lava Maze',
		locationImage: '/images/combat/locations/lava_maze.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)',
			backgroundSize: '100% 115%',
			backgroundPosition: '80% 70%',
		},
		accessRequirements: {
			maxGroupSize: 1,
		},
		extraTooltipInfo:
			'20-40+|Range|This maze is the primary obstacle blocking any would-be adventurer from truly breaking away from civilization and going out into the truly dangerous locations. Many dangerous demons and spiders have made their home here, it would be wise to be at least moderately experienced before attempting to solve this maze.',
	},
	1004: {
		name: 'Corrupted Lands',
		locID: 1004,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Corrupted Lands',
		locationImage: '/images/combat/locations/corrupted_lands_3.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)',
			backgroundSize: '120% 150%',
			backgroundPosition: '40% 0',
		},
		accessRequirements: {
			maxGroupSize: 1,
		},
		extraTooltipInfo:
			'40-60+|Melee|Some terrible cataclysm has left this land corrupted beyond repair. The only life that can survive here are twisted by some sort of common curse. Legends say that spending too much time here may eventually cause you to succumb to the curse as well. An experienced and well equipped adventurer should be able to survive here.',
	},
	1005: {
		name: 'Valley of Giants',
		locID: 1005,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Valley of Giants',
		locationImage: '/images/combat/locations/valley_of_giants_4.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.25)',
			backgroundSize: '100% 100%',
			backgroundPosition: '80% 50%',
		},
		accessRequirements: {
			maxGroupSize: 1,
		},
		extraTooltipInfo:
			'60-90+|Melee|Situated far from human civilization, this valley holds the remnants of a once great civilization that has been almost entirely lost to time. Its inhabitants are hostile and barbaric, but they have preserved some lost knowledge to produce equipment far beyond the ability of any human crafter. Legends say that a King yet remains to rule this Valley.',
	},
	1006: {
		name: 'Chaos Wastes',
		locID: 1006,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Chaos Wastes',
		locationImage: '/images/combat/locations/chaos_wastes_4.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.3)',
			backgroundSize: '100% 120%',
			backgroundPosition: '40% 25%',
		},
		accessRequirements: {
			maxGroupSize: 2,
		},
		extraTooltipInfo:
			'90+|Magic|Chaos warps the very essence of reality in these lands. Abominations and unknowable horrors roam the wastes seeking out life and destroying it where it may be found. Impossible crystalline towers reach into the sky where it does not reach down to tear at the earth. Only the most powerful adventurers should explore here.',
	},
	1007: {
		name: 'Enchanted Armory',
		locID: 1007,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Enchanted Armory',
		locationImage: '/images/combat/locations/enchanted_armory_2.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		accessRequirements: {
			maxGroupSize: 2,
		},
		extraTooltipInfo:
			'40-70+|Magic|Bastions of magical research lay within the city, churning out the golems and constructs that patrol its street. Their armories store massive stockpiles of these creations, ready for testing in true battle. Perhaps you and a friend may be experienced enough to provide the battle and reap the rewards?',
	},
	1008: {
		name: 'Fallen Academy',
		locID: 1008,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Fallen Academy',
		locationImage: '/images/combat/locations/fallen_academy_2.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)',
			backgroundSize: '120% 150%',
			backgroundPosition: '10% 0',
		},
		accessRequirements: {
			maxGroupSize: 1,
		},
		extraTooltipInfo:
			'25-50+|Melee;Range|Once the foremost academy of all human civilization, some disaster has befallen this once great institution. Now, it is a place of death and destruction, filled with the hostile undead and the living alike. A moderately experienced adventurer may be able to unearth what has caused this tragedy.',
	},
	1009: {
		name: 'Overgrown Woods',
		locID: 1009,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Overgrown Woods',
		locationImage: '/images/combat/locations/overgrown_forest_2.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)',
			backgroundSize: '120% 130%',
			backgroundPosition: '30% 10%',
		},
		accessRequirements: {
			maxGroupSize: 2,
		},
		extraTooltipInfo:
			'25-50+|Melee|On the frontiers are huge untamed forests filled with all manner of beasts. A moderately experienced adventurer may be able to head a hunting expedition into these woods and potentially uncover some of the secrets of the forest.',
	},
	1010: {
		name: 'Elven Encampment',
		locID: 1010,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Elven Encampment',
		locationImage: '/images/combat/locations/elven_encampment_2.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)',
			backgroundSize: '100% 120%',
			backgroundPosition: '50% 30%',
		},
		accessRequirements: {
			maxGroupSize: 2,
		},
		extraTooltipInfo:
			'50-75+|Range|Deep within the forest are the last vestiges of a once proud and illustrious empire. Though they are scattered and few in number, the elves survive due to their superior skills and equipment. Experienced adventurers may match those skills and seize their equipment. Tales tell of a powerful Elven King who still resides in the forest in ruins of his old family estate, but who would believe that?',
	},
	2000: {
		name: 'Living Armory',
		locID: 2000,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Living Armory',
		locationImage: '/images/combat/locations/living_armory_2.png',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo: '75+|Magic|An armory of living armor imbued with potent magical energy.',
		bestiaryBaseLocationID: 1002,
		relatedKey: 8001,
	},
	2001: {
		name: "Giant's Hills",
		locID: 2001,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: "Fighting in the Giant's Hills",
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/giant_hills.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'75+|Melee|Rolling hills located deeper within the Valley of Giants. The inhabitants here are much older than the ones found in the valley.',
		bestiaryBaseLocationID: 1005,
		relatedKey: 8002,
	},
	2002: {
		name: 'Corroded Battlefield',
		locID: 2002,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting on the Corroded Battlefield',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/corroded_battlefield.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'75+|Melee|The field of some forgotten battle left to decay in the muck and mire of these forsaken lands.',
		bestiaryBaseLocationID: 1004,
		relatedKey: 8003,
	},
	2003: {
		name: 'Goblin Kitchen',
		locID: 2003,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Goblin Kitchen',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/goblin_kitchen.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo: '75+|Any|One of many goblin field kitchens meant to feed their raids on human civilization.',
		bestiaryBaseLocationID: 1001,
		relatedKey: 8004,
	},
	2004: {
		name: "Ot_to's Farm",
		locID: 2004,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: "Fighting in Ot_to's Farm",
		locationImage: '/images/combat/locations/otto_farm_2.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)',
			backgroundSize: '100% 100%',
			backgroundPosition: '50% 20%',
		},
		accessRequirements: {
			maxGroupSize: 1,
		},
		extraTooltipInfo:
			'75+|Any|The farm of the foremost livestock farmer in the lands. The animals here have grown to be immensely powerful compared to their standard brethren.',
		bestiaryBaseLocationID: 1000,
		relatedKey: 8005,
	},
	2005: {
		name: 'The Pit',
		locID: 2005,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in The Pit',
		locationImage: '/images/combat/locations/pit.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		accessRequirements: {
			maxGroupSize: 1,
		},
		extraTooltipInfo:
			'75+|Range|A pit found while traversing the Lava Maze that seems to lead directly into a den of demons and massive spiders.',
		bestiaryBaseLocationID: 1003,
		relatedKey: 8006,
	},
	2006: {
		name: 'Sacred Grove',
		locID: 2006,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Sacred Grove',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/sacred_grove.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'75+|Range;Magic|A grove within the Living Forest that houses some of the oldest trees and their guardians.',
		bestiaryBaseLocationID: 202,
		relatedKey: 8007,
	},
	2007: {
		name: 'Chaotic Rift',
		locID: 2007,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Chaotic Rift',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/chaotic_rift.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo: '90+|Magic|A tear within reality itself.',
		bestiaryBaseLocationID: 1006,
		relatedKey: 8009,
	},
	2008: {
		name: 'Stormier Seas',
		locID: 2008,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Stormier Seas',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/stormier_seas.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo: '75+|Any|A hotspot of angry fish and predators with a side of eldritch horror!',
		bestiaryBaseLocationID: 201,
		relatedKey: 8010,
	},
	2009: {
		name: 'Obsidian Research Facility',
		locID: 2009,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Obsidian Research Facility',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/obsidian_facility.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'75+|Any|One of several research facilities looking into the potential usage of obsidian and glass based constructs to protect human civilization.',
		bestiaryBaseLocationID: 1007,
		relatedKey: 8011,
	},
	2010: {
		name: 'Fallen Wizard Tower',
		locID: 2010,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Fallen Wizard Tower',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/fallen_wizard_tower.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)',
			backgroundSize: '100% 140%',
			backgroundPosition: '50% 20%',
		},
		extraTooltipInfo:
			'75+|Melee;Range|A lone wizard tower found deep in the wilderness. Despite its decrepit state, it still appears to have some inhabitants.',
		bestiaryBaseLocationID: 1008,
		relatedKey: 8012,
	},
	2011: {
		name: 'Nox Woods',
		locID: 2011,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting in the Nox Woods',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/nox_woods.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'75+|Range|A path leading deep into the Overgrown Woods where the canopy is so thick it is perpetual night. Who made this path?',
		bestiaryBaseLocationID: 1009,
		relatedKey: 8013,
	},
	2012: {
		name: 'Royal Encampment',
		locID: 2012,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Royal Encampment',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/royal_encampment.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'75+|Any|An Elven encampment found deep in the wilderness. Appears to be far more well equipped than other camps that you have seen.',
		bestiaryBaseLocationID: 1010,
		relatedKey: 8014,
	},
	3000: {
		name: 'Gazing into the Abyss',
		locID: 3000,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Gazing too deeply into the abyss',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/aberrant.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo: '30+|Any|Gaze too deeply into the abyss and it might gaze back.',
		relatedKey: 9015,
	},
	3001: {
		name: 'Goblin Settlement',
		locID: 3001,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Raiding a Goblin Settlement',
		accessRequirements: {
			maxGroupSize: 3,
		},
		locationImage: '/images/combat/locations/goblin_settlement.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'30+|Any|An established goblin settlement bustling with activity. Generally well hidden, only a map could lead you here. A Goblin Chef leads these settlements as their chieftan.',
		bestiaryBaseLocationID: 1001,
		relatedKey: 9006,
	},
	3002: {
		name: 'Dark Fortress',
		locID: 3002,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Fighting through the Dark Fortress',
		accessRequirements: {
			maxGroupSize: 3,
		},
		locationImage: '/images/combat/locations/dark_fortress_2.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)',
			backgroundSize: '100% 140%',
			backgroundPosition: '50% 0',
		},
		extraTooltipInfo:
			'50+|Any|A stronghold in the wastes where less than savory individuals strike bargains to increase their power, even at the cost of their humanity. Generally well defended by automated defenses that exceed even those of the City.',
		bestiaryBaseLocationID: 1002,
		relatedKey: 9008,
	},
	3003: {
		name: "Giant's Keep",
		locID: 3003,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: "Challenging the Giant's Keep",
		accessRequirements: {
			maxGroupSize: 5,
		},
		locationImage: '/images/combat/locations/giant_keep.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			"75+|Any|The Giant King's Keep found deep within the Valley. Your party must possess the power of every type of giant in order to challenge him.",
		bestiaryBaseLocationID: 1005,
		relatedKey: 9000,
	},
	3004: {
		name: 'Elder Ruins',
		locID: 3004,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Exploring the Elder Ruins',
		accessRequirements: {
			maxGroupSize: 4,
		},
		locationImage: '/images/combat/locations/elder_ruins.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)',
			backgroundSize: '100% 140%',
			backgroundPosition: '50% 20%',
		},
		extraTooltipInfo:
			'75+|Melee|The ruins of some elder civilization. Though all that remains are ruins and toppled stones, its ruler still remains.',
		bestiaryBaseLocationID: 1010,
		relatedKey: 9016,
	},
	3005: {
		name: 'Overgrown Castle',
		locID: 3005,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Unturning stones at the Overgrown Castle',
		accessRequirements: {
			maxGroupSize: 3,
		},
		locationImage: '/images/combat/locations/overgrown_castle.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'50+|Range|One of many ruined castle found deep within the woods. A skilled tracker could potentially map a route to one by following the trail of the many animal packs that roam the woods.',
		bestiaryBaseLocationID: 1009,
		relatedKey: 9017,
	},
	3006: {
		name: 'Ancient Barrows',
		locID: 3006,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Grave Robbing in the Ancient Barrows',
		accessRequirements: {
			maxGroupSize: 2,
		},
		locationImage: '/images/combat/locations/ancient_barrows.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)',
			backgroundSize: '100% 140%',
			backgroundPosition: '50% 20%',
		},
		extraTooltipInfo:
			'50+|Melee;Range|The resting place for those lost in the many monster attacks that threaten humanity. Now, it is a desecrated place of death and decay. A properly attuned tool may grant you entry to one of these haunted crypts.',
		bestiaryBaseLocationID: 1008,
		relatedKey: 9010,
	},
	3007: {
		name: 'Palace of Flame',
		locID: 3007,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Extinguishing the Palace of Flame',
		accessRequirements: {
			maxGroupSize: 2,
		},
		locationBuffs: [
			{
				buffID: 8006,
				stacks: 99999,
				strength: 3,
				onlyOnStart: true,
				scaleByDifficulty: {
					4: 1,
					5: 1.33,
					10: 1.67,
					15: 2,
					20: 2.33,
				},
			},
		],
		locationImage: '/images/combat/locations/palace_of_flames_2.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)',
			backgroundSize: '100% 140%',
			backgroundPosition: '50% 20%',
		},
		extraTooltipInfo:
			'60+|Range;Magic|Hidden well within the Lava Maze, this palace stands the flames of its surroundings despite all odds. Perhaps there is some magic at work here to counteract the hellish heat?',
		bestiaryBaseLocationID: 1003,
		relatedKey: 9011,
	},
	3008: {
		name: 'Eye of the Storm',
		locID: 3008,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Treading water at the Eye of the Storm',
		accessRequirements: {
			maxGroupSize: 5,
		},
		locationImage: '/images/combat/locations/eye_of_the_storm.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'75+|Range|Chaotic energy has begun to twist the fabric of reality here, attempting to tear open a portal to some other plane of existence. The portal is unstable and defended by some sort of otherworldly creature. It is clear that this creature is a mere servant to some greater threat.',
		bestiaryBaseLocationID: 1006,
		relatedKey: 9018,
	},
	3009: {
		name: 'The Maelstrom',
		locID: 3009,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Facing the Maelstrom',
		accessRequirements: {
			maxGroupSize: 5,
		},
		locationImage: '/images/combat/locations/maelstrom.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'90+|Any|The portal has been opened and an avatar of chaos is beginning to manifest itself in our reality. Will you be enough to stop it?',
		bestiaryBaseLocationID: 1006,
		relatedKey: 9019,
	},
	3010: {
		name: 'RISE OF INFO',
		locID: 3010,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Facing the Truth',
		accessRequirements: {
			maxGroupSize: 50,
		},
		locationImage: '/images/books/true_shrimp.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo: "99+|Any|Did you know you won't survive this?",
		relatedKey: 8000,
	},
	3011: {
		name: 'Beyond the Edge',
		locID: 3011,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Going Beyond the Edge',
		accessRequirements: {
			maxGroupSize: 10,
		},
		locationImage: '/images/books/true_shrimp.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo: '99+|Any|The destroyer of worlds has awoken.',
		messages: {
			bossName: 'The True Shrimp God',
			onStart: {
				message: 'Who dares to challenge the Void?',
				type: 'combat-general',
			},
			onVictory: {
				message: 'We shall struggle forever, it is inevitable.',
				type: 'combat-general',
			},
			onDefeat: {
				message: 'Your might does not match your arrogance, begone!',
				type: 'combat-general',
			},
		},
		relatedKey: 9024,
	},
	3012: {
		name: 'Abandoned Academy Base',
		locID: 3012,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Revealing Forbidden Knowledge',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/forbidden_library2.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'60+|Master/Legendary Skilling Gear|An abandoned academy base that has been recently uncovered by the sea.',
		bestiaryBaseLocationID: 201,
		relatedKey: 9027,
	},
	3013: {
		name: 'Nature Atheneum',
		locID: 3013,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Reading Elven Scripts',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/elven_library.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'60+|Master/Legendary Skilling Gear|A trove of elven knowledge, hidden deep within the forest.',
		bestiaryBaseLocationID: 202,
		relatedKey: 9026,
	},
	3014: {
		name: 'Dwarven Research Station',
		locID: 3014,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Researching the Dwarves',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/dwarven_lab2.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'60+|Master/Legendary Skilling Gear|A dwarven research station located deep within the volcanic wastes.',
		bestiaryBaseLocationID: 1003,
		relatedKey: 9025,
	},
	3015: {
		name: 'Dark Vault',
		locID: 3015,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Delving into the Dark Vault',
		accessRequirements: {
			maxGroupSize: 3,
		},
		locationImage: '/images/combat/locations/dark_vault.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)',
			backgroundSize: '100% 140%',
			backgroundPosition: '50% 0',
		},
		extraTooltipInfo:
			'75+|Magic;Lightning|These vaults hold a wandering monstrosity that cannot be controlled or sated: a foul amalgamation of corruption and dark magic.',
		bestiaryBaseLocationID: 1002,
		relatedKey: 9033,
	},
	3016: {
		name: "Master Chicken's Kitchen",
		locID: 3016,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Finding the Limpe Sauce',
		accessRequirements: {
			maxGroupSize: 1,
		},
		locationImage: '/images/combat/locations/chicken_kitchen.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
		},
		extraTooltipInfo:
			'60+|Master/Legendary Skilling Gear|A kitchen hidden deep in the wilderness, where the Master Chicken prepares his famous Limpe Sauce.',
		bestiaryBaseLocationID: 1000,
		relatedKey: 9035,
	},
	9999: {
		name: 'Training Ground',
		locID: 9999,
		actionType: ActionTypes.Combat,
		baseDuration: 1000,
		activeMessage: 'Checking DPS',
		locationImage: '/images/combat/locations/training_ground.png',
		locationImageStyle: {
			linearGradient: 'rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)',
			backgroundSize: '100% 150%',
			backgroundPosition: '80% 0',
		},
		accessRequirements: {
			maxGroupSize: 5,
		},
		extraTooltipInfo:
			'1+|Any|A single dummy for checking your DPS against. Try the /dpsdummy command! Awards NO xp, essence, or loot, but it DOES cost ammo and runes!',
	},
};
