import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { socket } from '../../../../services/socket.service';
import './Inspect.css';
import { IInspectData } from '../../../../../../game-server/src/modules/profile/Profile';
import Profile from './Profile';

export default function Inspect() {
	const [inspectData, setInspectData] = useState<IInspectData>({
		username: '',
		league: 0,
		levels: {},
		cosmetics: {},
		icons: [],
		achievements: [],
		dungeons: [],
		chests: [],
		hiscores: [],
		items: [],
		achievementPoints: 0,
		backgroundLocation: 1000,
	});
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		socket.on('inspect:player', (data) => {
			setInspectData(data);
			onOpen();
		});
		return () => {
			socket.off('inspect:player');
		};
	});

	return <Profile {...inspectData} allowEdit={false} isOpen={isOpen} onClose={onClose} />;
}
