import {
	Box,
	Flex,
	Grid,
	Heading,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Table,
	Tbody,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeFrameBox, IdlescapeWrappingTooltip } from '@idlescape/ui';
import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { ILocationData } from '../../../../../../game-server/src/modules/locations/location.interface';
import { IInspectData } from '../../../../../../game-server/src/modules/profile/Profile';
import { usePlayerField } from '../../../../hooks/hooks';
import useIsMobile from '../../../../hooks/useIsMobile';
import { socket } from '../../../../services/socket.service';
import { chatIconList } from '../../../../utils/chatIconList';
import { BASE_PROFILE_SLOTS, PLUS_PROFILE_SLOTS } from '../../../../utils/constantsCollection';
import { leagueList } from '../../../../utils/leagueList';
import { locations } from '../../../../utils/locationList';
import { tabs } from '../../../../helper/NavigationTabsList';
import { questList } from '../../../../utils/questList';
import { CombatAvatar } from '../../../game/Combat/CombatAvatar2';
import { AchievementDisplay, AchievementEditor } from './AchievementEditor';
import BackgroundEditor from './BackgroundEditor';
import { DungeonDisplay, DungeonEditor } from './DungeonEditor';
import HiscoreEditor, { HiscoreDisplay } from './HiscoreEditor';
import { IconDisplay, IconEditor } from './IconEditor';
import { ItemDisplay, ItemEditor } from './ItemEditor';
import { ChestDisplay, ChestEditor } from './ChestEditor';
import { IItemData } from '../../../../../../game-server/src/modules/items/items.interface';

export default function Profile({
	username,
	league,
	levels,
	cosmetics,
	icons,
	achievements,
	validDungeons,
	dungeons,
	validChests,
	chests,
	hiscores,
	items,
	backgroundLocation,
	achievementPoints,
	allowEdit,
	isOpen,
	onClose,
}: IInspectData & {
	validDungeons?: ILocationData['locID'][];
	validChests?: IItemData['id'][];
	allowEdit: boolean;
	isOpen: boolean;
	onClose: () => void;
}) {
	const [isEditing, setIsEditing] = useState(false);
	const isMobile = useIsMobile();

	// These belong to the inspecting player, but are only used, when they inspect themselves
	const subscription = usePlayerField('subscription');
	const bonusProfileSlots = usePlayerField('bonusProfileSlots');
	const slotsInUse =
		hiscores.length + icons.length + achievements.length + dungeons.length + items.length + chests.length;
	const maxSlots = BASE_PROFILE_SLOTS + (subscription.active ? PLUS_PROFILE_SLOTS : 0) + bonusProfileSlots;
	const hasFreeSlots = slotsInUse < maxSlots;

	const backgroundImage = locations[backgroundLocation].locationImage;

	function setCurrentCosmetics() {
		socket.emit('profile:cosmetics:set');
	}

	function renderLevels() {
		const totalLevel = levels.total?.level ?? 0;
		const totalMastery = levels.total?.masteryLevel ?? 0;

		return (
			<div className='inspect-levels'>
				<div className='inspect-level'>
					<img className='header-league-icon' src='/images/total_level.png' alt='Total Level' />
					{totalLevel}
				</div>
				<div className='inspect-level lightblue-text'>
					<img
						className='header-league-icon'
						src='/images/total_level_mastery_icon.png'
						alt='Total Mastery Level'
					/>
					{totalMastery}
				</div>
				{Object.entries(levels).map(([skill, skillData]) => {
					if (skill === 'total') return;
					const imageSource = tabs[skill].icon;
					const image = <img className='header-league-icon' src={imageSource} alt={skill} />;
					let masteryLevel: React.ReactElement | null = null;
					if (skillData.masteryLevel) {
						masteryLevel = <span className='lightblue-text'> + {skillData.masteryLevel}</span>;
					}
					const key = `${skill}-${skillData.level}-${skillData.masteryLevel}`;
					return (
						<div key={key} className='inspect-level'>
							{image}
							{skillData.level}
							{masteryLevel}
						</div>
					);
				})}
			</div>
		);
	}

	function renderHiscores() {
		return (
			<IdlescapeFrameBox variant='primary' padding='0px 15px 15px' minWidth='300px' _hover={{}}>
				<Heading className='tooltip-title' paddingTop='0' size='md' textAlign='center'>
					Hiscores
				</Heading>
				{/* Needs to be active even when all slots are in use, since removing also requires the editor */}
				{isEditing && <HiscoreEditor activeHiscores={hiscores} />}
				<Table style={{ borderCollapse: 'separate' }}>
					<Thead>
						<Tr>
							<Th>Skill</Th>
							<Th>Rank</Th>
							<Th>Level</Th>
						</Tr>
					</Thead>
					<Tbody>
						{hiscores.map((hiscore) => (
							<HiscoreDisplay key={`${hiscore.skill}-${hiscore.mastery}`} hiscore={hiscore} />
						))}
					</Tbody>
				</Table>
			</IdlescapeFrameBox>
		);
	}

	function renderIcons() {
		return (
			<IdlescapeFrameBox variant='primary' padding='0px 15px 15px' minWidth='280px' _hover={{}}>
				<Heading className='tooltip-title' paddingTop='0' size='md' textAlign='center'>
					Icons
				</Heading>
				<Flex justifyContent='center' flexWrap='wrap'>
					{icons.map((icon) => {
						if (!icon) return null;
						const iconData = chatIconList[icon];
						if (!iconData) return null;
						return (
							<IconDisplay
								key={icon}
								iconData={iconData}
								height={isMobile ? '50px' : '60px'}
								width={isMobile ? '50px' : '60px'}
								allowRemove={isEditing}
							/>
						);
					})}
					{isEditing && hasFreeSlots && <IconEditor activeIcons={icons} />}
				</Flex>
			</IdlescapeFrameBox>
		);
	}

	function renderAchievements() {
		return (
			<IdlescapeFrameBox variant='primary' padding='0px 15px 15px' minWidth='280px' _hover={{}}>
				<Heading className='tooltip-title' paddingTop='0' size='md' textAlign='center'>
					Achievements {achievementPoints}
					<Image
						src='images/tutorial/achievement_point.png'
						alt='Quest Points'
						height='18px'
						width='18px'
						marginLeft='5px'
					/>
				</Heading>
				<Flex justifyContent='center' flexWrap='wrap' gap='5px'>
					{achievements.map((achievement) => {
						const achievementData = questList[achievement.id];
						if (!achievementData) return null;
						return (
							<AchievementDisplay
								key={achievement.id}
								achievementData={achievementData}
								date={achievement.completionDate}
								height={isMobile ? '50px' : '60px'}
								width={isMobile ? '50px' : '60px'}
								allowRemove={isEditing}
							/>
						);
					})}
					{isEditing && hasFreeSlots && <AchievementEditor activeAchievements={achievements} />}
				</Flex>
			</IdlescapeFrameBox>
		);
	}

	function renderDungeons() {
		return (
			<IdlescapeFrameBox variant='primary' padding='0px 15px 15px' minWidth='280px' _hover={{}}>
				<Heading className='tooltip-title' paddingTop='0' size='md' textAlign='center'>
					Dungeons
				</Heading>
				<Flex justifyContent='center' flexWrap='wrap' gap='5px'>
					{dungeons.map((dungeon) => (
						<DungeonDisplay
							key={dungeon.id}
							dungeon={dungeon.location_id}
							date={dungeon.time_completed}
							time={dungeon.time}
							highestAug={dungeon.difficulty}
							allowRemove={isEditing}
						/>
					))}
					{isEditing && hasFreeSlots && (
						<DungeonEditor validDungeons={validDungeons ?? []} activeDungeons={dungeons} />
					)}
				</Flex>
			</IdlescapeFrameBox>
		);
	}

	function renderChests() {
		return (
			<IdlescapeFrameBox variant='primary' padding='0px 15px 15px' minWidth='280px' _hover={{}}>
				<Heading className='tooltip-title' paddingTop='0' size='md' textAlign='center'>
					Chests
				</Heading>
				<Grid
					gridTemplateColumns={`repeat(auto-fit, ${isMobile ? '50px' : '64px'})`}
					gap='5px'
					justifyContent='center'
				>
					{chests.map((chest) => (
						<ChestDisplay
							key={chest.chestID}
							chestID={chest.chestID}
							amount={chest.amount}
							loot={chest.loot}
							date={chest.date}
							height={isMobile ? '50px' : '60px'}
							width={isMobile ? '50px' : '60px'}
							allowRemove={isEditing}
						/>
					))}
					{isEditing && hasFreeSlots && <ChestEditor validChests={validChests ?? []} activeChests={chests} />}
				</Grid>
			</IdlescapeFrameBox>
		);
	}

	function renderItems() {
		return (
			<IdlescapeFrameBox variant='primary' padding='0px 15px 15px' minWidth='280px' _hover={{}}>
				<Heading className='tooltip-title' paddingTop='0' size='md' textAlign='center'>
					Items
				</Heading>
				<Grid
					gridTemplateColumns={`repeat(auto-fit, ${isMobile ? '50px' : '64px'})`}
					gap='5px'
					justifyContent='center'
				>
					{items.map((item, index) => (
						<ItemDisplay key={index} item={item} allowRemove={isEditing} />
					))}
					{isEditing && hasFreeSlots && <ItemEditor activeItems={items} />}
				</Grid>
			</IdlescapeFrameBox>
		);
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				maxWidth='600px'
				backgroundImage={`url(${backgroundImage})`}
				backgroundSize='cover'
				backgroundPosition='center'
				style={{ borderImageSource: 'url(/images/stone-9slice-dark-gray_border.png)' }}
			>
				<ModalHeader display='flex' justifyContent='center' alignItems='center' position='relative'>
					{allowEdit && (
						<Flex position='absolute' top='0' left='0' gap='5px'>
							<IdlescapeButton
								variant={isEditing ? 'green' : 'blue'}
								size='icon'
								onClick={() => setIsEditing(!isEditing)}
							>
								{isEditing ? <FaCheck /> : <MdEdit />}
							</IdlescapeButton>
							{isEditing && (
								<>
									<BackgroundEditor activeBackground={backgroundLocation} />
									<IdlescapeWrappingTooltip content='Set displayed cosmetics and equipment from your current gear.'>
										<IdlescapeButton
											variant={'blue'}
											size='icon'
											onClick={() => setCurrentCosmetics()}
										>
											<Image
												src='/images/ui/equipment_icon.png'
												alt='Set Cosmetics'
												width='24px'
												height='24px'
												objectFit='contain'
											/>
										</IdlescapeButton>
									</IdlescapeWrappingTooltip>
								</>
							)}
						</Flex>
					)}
					<Heading className='tooltip-title' backgroundColor='rgba(0,0,0,0.3)'>
						{username}
					</Heading>
					<IdlescapeWrappingTooltip content={leagueList[league]?.name}>
						<Image
							src={leagueList[league]?.icon}
							alt={leagueList[league]?.name}
							width='60px'
							height='60px'
							position='absolute'
							top='0'
							right='50px'
						/>
					</IdlescapeWrappingTooltip>
					<ModalCloseButton />
				</ModalHeader>
				<ModalBody
					as={Flex}
					gap='35px'
					alignItems='end'
					justifyContent='center'
					flexWrap='wrap'
					padding='0'
					className='inspect-container'
				>
					<Box width='200px' marginLeft='10px'>
						<CombatAvatar equipment={{}} cosmetics={cosmetics} />
					</Box>
					{renderLevels()}
					<Flex flexWrap='wrap' alignItems='center' justifyContent='center'>
						{(isEditing || hiscores.length > 0) && renderHiscores()}
						{(isEditing || achievements.length > 0) && renderAchievements()}
						{(isEditing || dungeons.length > 0) && renderDungeons()}
						{(isEditing || chests.length > 0) && renderChests()}
						{(isEditing || icons.length > 0) && renderIcons()}
						{(isEditing || items.length > 0) && renderItems()}
					</Flex>
				</ModalBody>
				<ModalFooter display='flex' justifyContent='center'>
					{allowEdit && isEditing && (
						<IdlescapeButton size='large' variant='green' onClick={() => setIsEditing(!isEditing)}>
							View Profile
						</IdlescapeButton>
					)}
					<IdlescapeButton size='large' variant='red' onClick={onClose}>
						Close
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
