import React, { useEffect, useState } from 'react';
import './Characters.css';
import { IdlescapeButton, IdlescapeContainer, IdlescapePill } from '@idlescape/ui';
import type { ICharacterInfo } from '../../../../game-server/src/modules/login/loginHelpers';
import Axios from 'axios';
import { AbsoluteCenter, Box, Grid, HStack, Image, Text } from '@chakra-ui/react';
import { formatNumberToString, getGoldColorFromNumber, getHeatColorFromNumber } from '../../helper/helperFunctions';
import { leagueList } from '../../utils/leagueList';
import { useNavigate } from 'react-router-dom';
import { CreateCharacterPanel } from './CreateCharacter';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import CombatAvatarMugshot from '../game/Combat/CombatAvatarMugshot';
import { confirmAlert } from 'react-confirm-alert';
import { LoadingWheel } from '../layout/LoadingWheel';
import Countdown from 'react-countdown';
import { BiLogOut } from 'react-icons/bi';
import { logoutUser } from '../../actions/authActions';
import useIsMobile from '../../hooks/useIsMobile';
import { openGameUrl } from '../../GameUrls';
import { BASE_ACTIVE_CHARACTERS, BASE_MAX_CHARACTERS } from '../../utils/constantsCollection';
import { useDispatch } from 'react-redux';

export const CharactersMenu = () => {
	const [maxCharacters, setMaxCharacters] = useState(BASE_MAX_CHARACTERS);
	const [characters, setCharacters] = useState<ICharacterInfo[]>([]);
	const [maxActiveCharacters, setMaxActiveCharacters] = useState(BASE_ACTIVE_CHARACTERS);
	const [activateCharacterId, setActivateCharacterId] = useState(0);
	const [loading, setLoading] = useState(true);
	const isMobile = useIsMobile();

	const [view, setView] = useState<'characterList' | 'createCharacter'>('characterList');
	const [showingActive, setShowingActive] = useState(true);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		document.title = 'Idlescape - Character Menu';

		if (view === 'characterList') {
			Axios.post('/api/auth/character-info')
				.then((res) => {
					setMaxCharacters(res.data.maxCharacters);
					setCharacters(res.data.characters);
					setMaxActiveCharacters(res.data.maxActiveCharacters);

					if (!res.data.claimed) {
						sessionStorage.setItem('characterID', res.data.characters[0].id);
						openGameUrl('game', { navigate });
					}

					setLoading(false);
				})
				.catch((err) => {
					console.log('Error when getting the character list!!!', err);
					openGameUrl('login', { navigate });
				});
		}
	}, [view]);

	useEffect(() => {
		if (activateCharacterId) {
			handleActivateCharacter(activateCharacterId);
			setActivateCharacterId(0);
		}
	}, [characters]);

	function selectCharacter(event: React.MouseEvent, id: number) {
		sessionStorage.setItem('characterID', id.toString());
		if (event.ctrlKey || event.metaKey || event.button === 1) {
			openGameUrl('game', { navigate, openNew: true });
		} else {
			openGameUrl('game', { navigate });
		}
	}

	function changeView() {
		// && characters.length < maxCharacters removed from conditional for season character creation, should be improved later
		if (view === 'characterList') {
			setView('createCharacter');
		} else {
			setView('characterList');
		}
	}

	function handleActivateCharacter(characterId: number) {
		const activeCount = activeCharCount();
		const maxActiveReached = activeCount >= maxActiveCharacters;
		const currentCharacter = characters.find((char) => char.id === characterId);
		const currentCharacterLeague = currentCharacter?.league as number;
		const leagueData = leagueList[currentCharacterLeague];
		const maxLeagueRestriction = characters.some((char) => {
			return (
				char.active &&
				char.league === currentCharacterLeague &&
				(leagueData.rules.marketplace || leagueData.rules.endDate)
			);
		});

		if ((maxActiveReached && !leagueData.rules.endDate) || maxLeagueRestriction) {
			activateCharacterPopup(characterId);
		} else {
			activateCharacter(characterId);
			return null;
		}
	}

	function activateCharacterPopup(characterId: number) {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<Box maxWidth={'400px'} textAlign={'center'}>
						{getActivationContent(characterId, onClose)}
					</Box>
				);
			},
			overlayClassName: 'idlescape-confirm-alert',
		});
	}

	function deleteCharacterPopup() {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<Box maxWidth={'400px'} textAlign={'center'}>
						{getDeleteContent(onClose)}
					</Box>
				);
			},
			overlayClassName: 'idlescape-confirm-alert',
		});
	}

	function deleteCharacterPopupConfirm(characterId: number) {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<Box maxWidth={'400px'} textAlign={'center'}>
						{getDeleteConfirm(characterId, onClose)}
					</Box>
				);
			},
			overlayClassName: 'idlescape-confirm-alert',
		});
	}

	function claimSeasonalRewards(seasonalCharacter: number, claimingId: number) {
		Axios.post('/api/auth/claim-seasonal-rewards', { seasonalCharacter, claimingId }).then((res) => {
			setCharacters(res.data.characters);
		});
	}

	function claimSeasonalPopup(fromCharacter: number, fromCharacterName: string) {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<Box maxWidth={'400px'} textAlign={'center'}>
						<Text fontWeight={'bold'}>Claim Seasonal Rewards - {fromCharacterName}</Text>
						<Text>You can only claim Event Tokens and Medals from Seasonal Rewards on one character.</Text>
						<Text>
							Account rewards, like Chat Icons, will be available on any of your characters after being
							claimed.
						</Text>
						<Text>Select character to receive the rewards:</Text>
						{characters.map((character) => {
							if (!character.active) {
								return null;
							}
							if (leagueList[character.league].rules.endDate) {
								return null;
							}
							return (
								<IdlescapePill
									key={'select-deactivate-character-' + character.id}
									image={leagueList[character.league].icon}
									marginTop={'20px'}
									variant={'dark'}
									onClick={() => {
										onClose();
										claimSeasonalRewards(fromCharacter, character.id);
									}}
								>
									<HStack>
										<Box>{character.username}</Box>
										<Box fontSize={'12px'} fontStyle={'italic'}>
											{character.action}
										</Box>
									</HStack>
								</IdlescapePill>
							);
						})}
					</Box>
				);
			},
			overlayClassName: 'idlescape-confirm-alert',
		});
	}

	function activateCharacter(characterId: number, deactivateCharacterId?: number) {
		Axios.post('/api/auth/activate-character', { characterId, deactivateCharacterId }).then((res) => {
			setCharacters(res.data.characters);
		});
	}

	function deleteCharacter(confirmationId: number, deleteCharacterId: number) {
		if (confirmationId !== deleteCharacterId) {
			return;
		}
		Axios.post('/api/auth/delete-character', { characterId: deleteCharacterId }).then((res) => {
			setCharacters(res.data.characters);
		});
	}
	function logout() {
		logoutUser(dispatch);
	}

	function getActivationContent(characterId: number, onClose: () => void) {
		const activeCount = activeCharCount();
		const maxActiveReached = activeCount >= maxActiveCharacters;
		const character = characters.find((char) => char.id === characterId);
		const characterLeague = character?.league as number;
		const leagueData = leagueList[characterLeague];
		const maxLeagueRestriction = characters.some((char) => {
			return (
				char.active &&
				char.league === characterLeague &&
				(leagueData.rules.marketplace || leagueData.rules.endDate)
			);
		});

		let string = '';
		if (maxActiveReached) {
			string = `You can only have ${maxActiveCharacters} active characters at a time. To activate this character, select a character below to deactivate.`;
		} else if (maxLeagueRestriction) {
			string =
				'You can only have one character per marketplace-enabled league active at a time. To activate this character, select a character below to deactivate.';
		}

		return (
			<Box>
				<Box>
					{string}
					<Text fontWeight={'bold'}>
						Deactivating a character will start a one hour cooldown before the character can be activated
						again. All market offers and listings will be removed.
					</Text>
				</Box>

				<Box>
					{characters.map((char) => {
						if (!char.active) {
							return null;
						}

						// Remove every other character if there's a character limit for the league
						if (maxLeagueRestriction) {
							if (characterLeague !== char.league) {
								return null;
							}
						}

						// Remove season characters from list as they don't count
						if (leagueList[char.league].rules.endDate) {
							// Unless you somehow have 2 characters in the same league
							if (characterLeague !== char.league) {
								return null;
							}
						}

						return (
							<IdlescapePill
								key={'select-deactivate-character-' + char.id}
								image={leagueList[char.league].icon}
								marginTop={'20px'}
								variant={'dark'}
								onClick={() => {
									onClose();
									activateCharacter(characterId, char.id);
								}}
							>
								<HStack>
									<Box>{char.username}</Box>
									<Box fontSize={'12px'} fontStyle={'italic'}>
										{char.action}
									</Box>
								</HStack>
							</IdlescapePill>
						);
					})}
				</Box>
			</Box>
		);
	}

	function getDeleteContent(onClose: () => void) {
		return (
			<Box>
				<Text fontWeight={'bold'}>
					<span style={{ color: 'red' }}>CAUTION!</span>
					<br></br>
					Deleting a character will remove it from your account.
					<br></br>
					<span style={{ color: 'red' }}>CAUTION!</span>
				</Text>

				<Box>
					{characters.map((character) => {
						return (
							<IdlescapePill
								key={'select-deactivate-character-' + character.id}
								image={leagueList[character.league].icon}
								marginTop={'20px'}
								variant={'dark'}
								onClick={() => {
									onClose();
									deleteCharacterPopupConfirm(character.id);
								}}
								cursor={'pointer'}
								_hover={{
									filter: 'brightness(1.3)',
								}}
							>
								<HStack>
									<Box>{character.username}</Box>
									<Box fontSize={'12px'} fontStyle={'italic'}>
										{character.action}
									</Box>
								</HStack>
							</IdlescapePill>
						);
					})}
				</Box>
			</Box>
		);
	}

	function getDeleteConfirm(characterId: number, onClose: () => void) {
		const charactersRandomOrder = characters.sort(() => Math.random() - 0.5);
		return (
			<Box>
				<Text fontWeight={'bold'}>
					<span style={{ color: 'red' }}>Are you ABSOLUTELY certain you want to delete this character?</span>
					<br></br>
					<span style={{ color: 'red' }}>Select the character again to confirm!</span>
				</Text>

				<Box>
					{charactersRandomOrder.map((character) => {
						return (
							<IdlescapePill
								key={'select-deactivate-character-' + character.id}
								image={leagueList[character.league].icon}
								marginTop={'20px'}
								variant={'dark'}
								onClick={() => {
									onClose();
									deleteCharacter(characterId, character.id);
								}}
								cursor={'pointer'}
								_hover={{
									filter: 'brightness(1.3)',
								}}
							>
								<HStack>
									<Box>{character.username}</Box>
									<Box fontSize={'12px'} fontStyle={'italic'}>
										{character.action}
									</Box>
								</HStack>
							</IdlescapePill>
						);
					})}
				</Box>
			</Box>
		);
	}

	const activeCharCount = () => {
		return characters.filter((char) => {
			const leagueData = leagueList[char.league];
			const leagueEnded = leagueData.rules.endDate && new Date(leagueData.rules.endDate) > new Date();
			return char.active && !leagueData.rules.ignoreCharacterCount && !leagueEnded;
		}).length;
	};

	const charCount = () => {
		return characters.filter((char) => {
			const leagueData = leagueList[char.league];
			const leagueEnded = leagueData.rules.endDate && new Date(leagueData.rules.endDate) > new Date();
			return !leagueData.rules.ignoreCharacterCount && !leagueEnded;
		}).length;
	};

	function isCharacterSlotAvailable(leagueID: number) {
		const leagueInfo = leagueList[leagueID];
		const characterCount = charCount();
		const hasCharacter = characters.some((char) => char.league === leagueID);
		const slotAvailable = maxCharacters > characterCount;

		// Season ignores character count
		if (leagueInfo.rules.endDate) {
			if (new Date(leagueInfo.rules.endDate) < new Date()) {
				return false;
			}
			return !hasCharacter;
		}

		return slotAvailable;
	}

	const Header = () => {
		const characterCount = charCount();
		const activeCount = activeCharCount();

		const overMaxTotal = characterCount >= maxCharacters;
		const overMaxActive = activeCount >= maxActiveCharacters;
		const canMakeMoreCharacters = Object.values(leagueList).some((league) => isCharacterSlotAvailable(league.id));
		const createCharacterText = !canMakeMoreCharacters ? 'Character Limit Reached' : 'Create Character';
		return (
			<Grid
				gridTemplateColumns={isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr'}
				columnGap={'20px'}
				textAlign={'center'}
				paddingTop={'20px'}
				paddingX='20px'
				marginLeft={'auto'}
				marginRight={'auto'}
				width={'100%'}
			>
				<IdlescapePill
					gridRow={isMobile ? '2' : '1'}
					justifySelf={'right'}
					alignSelf={'end'}
					image='/images/combat/mugshot.png'
					tooltipText='Maximum Active Characters'
					color={overMaxActive ? '#C53030' : 'white'}
				>
					{`${activeCount} / ${maxActiveCharacters}`}
				</IdlescapePill>
				<Image
					maxWidth={'500px'}
					marginLeft={'auto'}
					marginRight={'auto'}
					height={'auto'}
					width={'60%'}
					gridColumn={isMobile ? '1 / -1' : '2 / 4'}
					alt='Idlescape Logo'
					src='/images/landing/logo_2.0.png'
				/>
				<IdlescapePill
					justifySelf={'left'}
					alignSelf={'end'}
					image='/images/ui/social_icon.png'
					tooltipText='Maximum League Characters'
					color={overMaxTotal ? '#C53030' : 'white'}
				>
					{`${characterCount} / ${maxCharacters}`}
				</IdlescapePill>
				<IdlescapeButton
					variant={canMakeMoreCharacters ? 'green' : 'disabled'}
					size='large'
					gridColumn={'1 / -1'}
					justifySelf={'center'}
					onClick={changeView}
				>
					{view === 'characterList' ? createCharacterText : 'Back to Character List'}
				</IdlescapeButton>
				{view !== 'createCharacter' && (
					<IdlescapeButton
						variant='red'
						size='large'
						gridColumn={'1 / -1'}
						justifySelf={'center'}
						onClick={deleteCharacterPopup}
					>
						Delete Character
					</IdlescapeButton>
				)}
				<IdlescapePill
					image={<BiLogOut />}
					position={'absolute'}
					top={'20px'}
					right={'20px'}
					cursor='pointer'
					_hover={{ transform: 'scale(1.05)' }}
					onClick={() => logout()}
				>
					Logout
				</IdlescapePill>
			</Grid>
		);
	};

	const CharacterList = () => {
		const activeCharacters = characters.filter((char) => char.active);
		const inactiveCharacters = characters.filter((char) => !char.active);

		const categorizeCharacters = (chars: ICharacterInfo[]) => {
			const permanent: ICharacterInfo[] = chars.filter(
				(char) => !leagueList[char.league].rules.endDate && !leagueList[char.league].rules.legacy
			);
			const seasonal: ICharacterInfo[] = chars.filter((char) => {
				const league = leagueList[char.league];
				if (!league.rules.endDate) return false;
				if (leagueList[char.league].rules.legacy) return false;
				return new Date(league.rules.endDate) > new Date();
			});
			const oldSeasonalUnclaimed = chars.filter((char) => {
				const league = leagueList[char.league];
				if (!league.rules.endDate) return false;
				if (leagueList[char.league].rules.legacy) return false;
				return new Date(league.rules.endDate) <= new Date() && !char.seasonClaimed;
			});
			const oldSeasonal: ICharacterInfo[] = chars.filter((char) => {
				const league = leagueList[char.league];
				if (!league.rules.endDate) return false;
				if (leagueList[char.league].rules.legacy) return false;
				return (
					new Date(league.rules.endDate) <= new Date() &&
					!chars.some((c) => c.league === char.league && c.active) &&
					char.seasonClaimed
				);
			});
			const legacy: ICharacterInfo[] = chars.filter((char) => leagueList[char.league].rules.legacy);
			return { permanent, seasonal, oldSeasonal, oldSeasonalUnclaimed, legacy };
		};

		const activeCategories = categorizeCharacters(activeCharacters);
		const inactiveCategories = categorizeCharacters(inactiveCharacters);

		const currentCategory = showingActive ? activeCategories : inactiveCategories;

		return (
			<div>
				<div style={{ display: 'flex', justifyContent: 'center', gap: '20px', margin: '20px 0' }}>
					<IdlescapeButton
						variant={showingActive ? 'green' : 'orange'}
						size='large'
						gridColumn={'1 / -1'}
						justifySelf={'center'}
						onClick={() => setShowingActive(true)}
					>
						Show Active ({activeCharacters.length})
					</IdlescapeButton>
					<IdlescapeButton
						variant={showingActive ? 'orange' : 'green'}
						size='large'
						gridColumn={'1 / -1'}
						justifySelf={'center'}
						onClick={() => setShowingActive(false)}
					>
						Show Inactive ({inactiveCharacters.length})
					</IdlescapeButton>
				</div>
				{currentCategory.permanent.length > 0 && (
					<>
						<Text className='character-grid-title'>Permanent Gamemode</Text>
						<Grid className='character-grid'>
							{currentCategory.permanent.map((character) => (
								<CharacterElement key={'character-' + character.id} character={character} />
							))}
						</Grid>
					</>
				)}
				{currentCategory.seasonal.length > 0 && (
					<>
						<Text className='character-grid-title'>Seasonal</Text>
						<Grid className='character-grid'>
							{currentCategory.seasonal.map((character) => (
								<CharacterElement key={'character-' + character.id} character={character} />
							))}
						</Grid>
					</>
				)}
				{currentCategory.oldSeasonalUnclaimed.length > 0 && (
					<>
						<Text className='character-grid-title'>Old Seasonal Unclaimed</Text>
						<Grid className='character-grid'>
							{currentCategory.oldSeasonalUnclaimed.map((character) => (
								<CharacterElement key={'character-' + character.id} character={character} />
							))}
						</Grid>
					</>
				)}
				{currentCategory.oldSeasonal.length > 0 && (
					<>
						<Text className='character-grid-title'>Old Seasonal Claimed</Text>
						<Grid className='character-grid'>
							{currentCategory.oldSeasonal.map((character) => (
								<CharacterElement key={'character-' + character.id} character={character} />
							))}
						</Grid>
					</>
				)}
				{currentCategory.legacy.length > 0 && (
					<>
						<Text className='character-grid-title'>Legacy Leagues</Text>
						<Grid className='character-grid'>
							{currentCategory.legacy.map((character) => (
								<CharacterElement key={'character-' + character.id} character={character} />
							))}
						</Grid>
					</>
				)}
			</div>
		);
	};

	const CharacterElement = ({ character }: { character: ICharacterInfo }) => {
		const characterLeagueInfo = leagueList[character.league];
		const gold = formatNumberToString(character.gold);
		const goldColor = getGoldColorFromNumber(character.gold);
		const heat = formatNumberToString(character.heat);
		const heatColor = getHeatColorFromNumber(character.heat);
		const cosmetic = character.settings?.cosmetic.selectedCosmetics ?? {};
		const leagueEnd = new Date(leagueList[character.league].rules.endDate ?? 0);
		const openLeague = !leagueList[character.league].rules.endDate || leagueEnd > new Date();
		const seasonAlreadyClaimed = character.seasonClaimed;
		return (
			<Box position={'relative'}>
				{!character.active && character.activationCooldown === 0 ? (
					<AbsoluteCenter zIndex={1}>
						{openLeague ? (
							<IdlescapeButton
								variant='green'
								textAlign={'center'}
								p={'0 50px'}
								width={'fit-content'}
								onClick={() => handleActivateCharacter(character.id)}
							>
								Activate
							</IdlescapeButton>
						) : (
							<IdlescapeContainer fontSize={'1.5em'} textAlign={'center'}>
								Season ended {leagueEnd.toLocaleDateString()}
								<IdlescapeButton
									onClick={() => claimSeasonalPopup(character.id, character.username)}
									size='large'
									variant={seasonAlreadyClaimed ? 'disabled' : 'green'}
								>
									{seasonAlreadyClaimed ? 'Claimed' : 'Claim Rewards'}
								</IdlescapeButton>
							</IdlescapeContainer>
						)}
					</AbsoluteCenter>
				) : null}
				{!character.active && character.activationCooldown > 0 ? (
					<AbsoluteCenter zIndex={1} fontSize={'32px'} fontWeight={'bold'}>
						<Countdown
							date={Date.now() + character.activationCooldown}
							renderer={({ minutes, seconds }) => {
								return `${minutes}min ${seconds}s`;
							}}
						></Countdown>
					</AbsoluteCenter>
				) : null}
				<div
					className={`character-select-container ${character.active ? '' : 'character-inactive'}`}
					onMouseDown={(e) => selectCharacter(e, character.id)}
					/* onClick={(e) => selectCharacter(e, character.id)} */
				>
					<div className='character-username-and-image'>
						<div className='character-image'>
							<div className='character-image-background-border'></div>
							<div className='character-inner-image'>
								<CombatAvatarMugshot equipment={character.equipment} cosmetics={cosmetic} />
							</div>
							<img
								className='character-image-league'
								src={characterLeagueInfo.icon}
								alt={characterLeagueInfo.name}
							/>
						</div>
						<div className='character-username-and-levels'>
							<div className='character-username'>
								{character.username}
								<div className='decorative-line'></div>
							</div>
							<div className='character-levels'>
								<div className='character-level'>
									<img src='/images/total_level.png' alt='Total Level' />
									<span> {character.totalLevel}</span>
								</div>
								<div className='character-level'>
									<img src='/images/total_level_mastery_icon.png' alt='Mastery Level' />
									<span> {character.masteryLevel}</span>
								</div>
							</div>
							<div className='decorative-line extended-decorative-line'></div>
						</div>
					</div>
					<div className='character-gold-and-heat'>
						<div className={'character-gold ' + goldColor}>
							<img src='/images/money_icon.png' alt='Gold' />
							<span>{gold}</span>
						</div>
						<div className={'character-heat ' + heatColor}>
							<img src='/images/heat_icon.png' alt='Heat' />
							<span>{heat}</span>
						</div>
					</div>
					<div className='decorative-line'></div>
					<div className='character-last-logged-in'>Last logged in: {character.lastLoggedInString} ago</div>
					<div className='decorative-line'></div>
					<div className='character-action'>{character.action}</div>
				</div>
			</Box>
		);
	};

	return (
		<Box
			backgroundImage={
				'https://res.cloudinary.com/dxwkhyrde/image/upload/v1675617557/%40idlescape-ui/landing_page_blurred_epqnau.png'
			}
			backgroundSize={'cover'}
			backgroundPosition={'center'}
			minHeight={'100vh'}
			backgroundAttachment={'fixed'}
		>
			{loading ? (
				<AbsoluteCenter>
					<LoadingWheel statusText='Loading Characters...' />
				</AbsoluteCenter>
			) : (
				<Box>
					<Header />
					<SwitchTransition mode={'out-in'}>
						<CSSTransition
							key={(view === 'characterList') as unknown as React.Key}
							timeout={300}
							addEndListener={(node, done) => {
								node.addEventListener('transitionend', done, false);
							}}
							classNames='fade'
						>
							{view === 'characterList' ? (
								<CharacterList />
							) : (
								<CreateCharacterPanel
									setView={setView}
									activateCharacter={setActivateCharacterId}
									isCharacterSlotAvailable={isCharacterSlotAvailable}
								/>
							)}
						</CSSTransition>
					</SwitchTransition>
				</Box>
			)}
		</Box>
	);
};
