// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { cloneDeep } from 'lodash';
import { IItemData } from '../../../game-server/src/modules/items/items.interface';
import { IFrequencyReturn, ILoot } from '../../../game-server/src/modules/items/Loot.interface';
import { ISmithingForge } from '../../../game-server/src/modules/skills/smithing/Smithing.interface';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';
import {
	FORGE_FIELDS_TO_ENHANCE_OR_MAINTAIN,
	FORGE_REFINING_BASE_TIME_ADJUSTMENT,
	FORGE_REFINING_PROBABILITY_ADJUSTMENT,
	INFERNO_CAP,
	MINIMUM_ACTION_TIME_IN_MILLISECONDS,
} from './constantsCollection';
import { forges } from './forgeList';
import { dwarvenElvenBonus, dwarvenElvenHaste } from './itemFunctions';

export function smithingDuration(
	bar: IItemData,
	effectiveSmithingLevel: number,
	forgeID: number,
	intensity: number,
	hasteStrength: number,
	productionHasteAffix: number,
	forgeEnhancementStrength: number,
	forgeMaintenanceStrength: number,
	logisticsStrength: number,
	manufacturingStrength: number,
	onlyForgeDuration?: boolean
) {
	if (!bar.time) {
		return MINIMUM_ACTION_TIME_IN_MILLISECONDS;
	}
	const barTier = getBarTier(bar);
	const forge = getForge(forgeID, forgeEnhancementStrength, forgeMaintenanceStrength);
	const intensityMult = getIntensitySpeedMult(barTier, intensity, forge);
	if (!forge) {
		return MINIMUM_ACTION_TIME_IN_MILLISECONDS;
	}
	const { forgeSpeedMult } = forge;

	const forgeDuration = bar.time * forgeSpeedMult * intensityMult;
	if (onlyForgeDuration) {
		return forgeDuration;
	}
	const hasteMult = 1 + hasteStrength + productionHasteAffix;
	const powerMult = 100 / (99 + effectiveSmithingLevel);
	const playerDuration =
		(bar.time * forgeSpeedMult * intensityMult * powerMult) /
		hasteMult /
		(1 + dwarvenElvenHaste(manufacturingStrength, logisticsStrength));

	return Math.max(MINIMUM_ACTION_TIME_IN_MILLISECONDS, playerDuration);
}

export function getBarTier(bar: IItemData) {
	const divide = 10.0;
	return bar.smithingTier ?? Math.max(1, Math.round((bar.level || 1) / divide));
}

export function getIntensitySpeedMult(barTier: number, intensity: number, forge: ISmithingForge): number {
	return forge.forgeIntensitySpeedMult ** (intensity - barTier);
}

export function getIntensityHeatMult(barTier: number, intensity: number, forge: ISmithingForge): number {
	return forge.forgeIntensityHeatCostMult ** (intensity - barTier);
}

export function getIntensityBonusBars(barTier: number, intensity: number, forge: ISmithingForge): number {
	return forge.forgeIntensityBonusBars ** (intensity - barTier);
}

export function getIntensityMaterialMult(barTier: number, intensity: number, forge: ISmithingForge): number {
	return forge.forgeIntensityMaterialCostMult ** (intensity - barTier);
}

export function getForge(forgeID: number, enhancementStrength: number, maintenanceStrength: number): ISmithingForge {
	const clonedForge = cloneDeep(forges[forgeID]);
	for (const [keyString, value] of Object.entries(clonedForge)) {
		const key = keyString as keyof typeof FORGE_FIELDS_TO_ENHANCE_OR_MAINTAIN;
		if (typeof value === 'number') {
			const forgeModifier = FORGE_FIELDS_TO_ENHANCE_OR_MAINTAIN[key];
			if (!forgeModifier || forgeModifier.skipField) {
				continue;
			}
			const neutral = forgeModifier.neutral;
			if (value > neutral) {
				if (forgeModifier.enhanceOnPositive) {
					// Make it bigger
					clonedForge[key] *= 1 + enhancementStrength;
				}
				if (forgeModifier.maintainOnPositive) {
					// Make it smaller
					clonedForge[key] *= 1 - maintenanceStrength;
				}
			} else if (value < neutral) {
				if (forgeModifier.enhanceOnNegative) {
					// Make it smaller
					clonedForge[key] *= 1 - enhancementStrength;
				}
				if (forgeModifier.maintainOnNegative) {
					// Make it bigger
					clonedForge[key] *= 1 + maintenanceStrength;
				}
			}
		}
	}
	return clonedForge;
}

export function totalRefiningStrength(forge: ISmithingForge, refiningStrength: number, dwarvenRefinement: number) {
	if (forge.forgeID === 2) {
		refiningStrength += dwarvenRefinement;
	}
	return refiningStrength;
}

export function refiningChance(
	forge: ISmithingForge,
	duration: number,
	refiningStrength: number,
	dwarvenRefinement: number
) {
	refiningStrength = totalRefiningStrength(forge, refiningStrength, dwarvenRefinement);
	const normalizedDuration = duration / FORGE_REFINING_BASE_TIME_ADJUSTMENT;
	const refiningStrengthMult = forge.refiningStrengthMult;
	return (normalizedDuration * refiningStrengthMult * refiningStrength) / FORGE_REFINING_PROBABILITY_ADJUSTMENT;
}

export function refiningTable(
	forge: ISmithingForge,
	bar: IItemData,
	intensity: number,
	refiningStrength: number,
	dwarvenRefinementStrength: number,
	obsidianForgery: number
): ILoot[] {
	refiningStrength = totalRefiningStrength(forge, refiningStrength, dwarvenRefinementStrength);
	const chancePower = (1 + refiningStrength / 8) * (1 + intensity / 16);
	const chanceAdd = (getBarTier(bar) + intensity) / 666;
	const table: ILoot[] = [];
	for (const loot of forge.refiningTable) {
		if (loot.chance === undefined) continue;
		let bonusChance = 0;
		if (loot.id === itemsIds.obsidian_glass && forge.forgeID === 1) {
			bonusChance = obsidianForgery;
		}
		table.push({
			...loot,
			chance: Math.max(0, Math.min(1, 1 - (1 - loot.chance - chanceAdd - bonusChance) ** chancePower)),
		});
	}
	// Should sort so lowest chances are first
	table.sort((a, b) => {
		if (b.chance && a.chance) {
			return a.chance - b.chance;
		}
		return 0;
	});
	const missingToOne = 1 - (table[table.length - 1].chance || 0);
	// Compute individual chances
	for (let i = table.length - 1; i > 0; i--) {
		const entry = table[i];
		if (!entry.chance) continue;
		entry.chance -= table[i - 1].chance || 0;
	}
	let added = false;
	for (const loot of table) {
		if (loot.id === itemsIds.sapphire && loot.chance) {
			loot.chance += missingToOne;
			added = true;
			break;
		}
	}
	if (!added) {
		table.push({ id: itemsIds.sapphire, chance: missingToOne });
	}
	return table.filter((loot) => loot.chance && loot.chance > 0);
}

export function getRefiningTableLoot(
	forge: ISmithingForge,
	bar: IItemData,
	intensity: number,
	refiningStrength: number,
	dwarvenRefinementStrength: number,
	obsidianForgery: number
): IFrequencyReturn {
	const table = refiningTable(forge, bar, intensity, refiningStrength, dwarvenRefinementStrength, obsidianForgery);
	let randomChance = Math.random();
	for (const loot of table) {
		if (loot.chance === undefined) continue;
		if (randomChance <= loot.chance) {
			const minAmount = loot.minAmount || 1;
			const maxAmount = loot.maxAmount || 1;
			const amount = Math.floor(Math.random() * (maxAmount - minAmount + 1) + minAmount);
			return {
				id: loot.id,
				amount,
			};
		}
		randomChance -= loot.chance;
	}
	// Hardcoded to a single sapphire :)
	return { id: 400, amount: 1 };
}

export function heatCostMultiplier(
	intensity: number,
	forge: ISmithingForge,
	barTier: number,
	heatReductionAffix: number,
	pyromancyStrength: number,
	crucibleStrength: number,
	dwarvenManufacturingStrength: number,
	elvenLogisticsStrength: number
) {
	return (
		(1 - heatReductionAffix) *
		(1 - pyromancyStrength) *
		forge.forgeHeatCost *
		getIntensityHeatMult(barTier, intensity, forge) *
		(1 + crucibleStrength * 1.5) *
		dwarvenElvenBonus(dwarvenManufacturingStrength, elvenLogisticsStrength)
	);
}

export function resourceCostMultiplier(
	intensity: number,
	forge: ISmithingForge,
	barTier: number,
	materialReductionAffix: number,
	pureMetalsStrength: number,
	crucibleStrength: number,
	dwarvenManufacturingStrength: number,
	elvenLogisticsStrength: number
) {
	return (
		(1 - materialReductionAffix) *
		(1 - pureMetalsStrength) *
		forge.forgeMaterialCost *
		getIntensityMaterialMult(barTier, intensity, forge) *
		(1 + crucibleStrength) *
		dwarvenElvenBonus(dwarvenManufacturingStrength, elvenLogisticsStrength)
	);
}

export function maxIntensity(forge: ISmithingForge, moltenMetalStrength: number, metallurgyStrength: number) {
	const moltenMetalBonus = forge.forgeID === 3 ? moltenMetalStrength : 0;
	return Math.floor(forge.forgeMaxIntensity + metallurgyStrength + moltenMetalBonus);
}

export function infernoSpeed(infernoStrength: number) {
	let infernoSpeed = 0;
	let chance = 1;
	for (let i = 0; i < INFERNO_CAP; i++) {
		chance *= infernoStrength;
		infernoSpeed += chance;
	}
	return infernoSpeed;
}

export function smithingOutput(forge: ISmithingForge, bar: IItemData, intensity: number, crucibleStrength: number) {
	const barTier = getBarTier(bar);
	return (forge.forgeBonusBars * getIntensityBonusBars(barTier, intensity, forge) + 1) * (1 + crucibleStrength);
}

export function smithingOutputRounded(
	forge: ISmithingForge,
	bar: IItemData,
	intensity: number,
	crucibleStrength: number
) {
	return Math.floor(Math.random() + smithingOutput(forge, bar, intensity, crucibleStrength));
}

export function smithingExperience(forge: ISmithingForge, bar: IItemData, intensity: number, crucibleStrength: number) {
	return Math.floor(
		(bar.experience ?? 0) * forge.forgeXPMult * smithingOutput(forge, bar, intensity, crucibleStrength)
	);
}
