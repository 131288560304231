import { Box, Flex, Grid, Image, Input, TabPanel, Textarea } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import React, { useState } from 'react';
import { AiFillCloseCircle, AiFillEdit, AiFillSave } from 'react-icons/ai';
import { TAccountPunishment, TPunishmentType } from '../../../../../../game-server/src/modules/moderator/Moderator';
import { usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';
import { chatIconList } from '../../../../utils/chatIconList';
import { modIcons } from '../../../game/Chat/ChatMessage';

function Punishments(props: {
	type: TPunishmentType;
	punishments: TAccountPunishment[] | undefined;
	accountName?: string;
	editPunishment: (type: TPunishmentType, punishment: TAccountPunishment) => void;
}) {
	const [editPunishment, setEditPunishment] = useState<TAccountPunishment | null>(null);
	const [newDuration, setNewDuration] = useState<string>('');
	const accountStatus = usePlayerField('accountStatus');
	const isChatMod = accountStatus === 'chat-mod';

	function handleSavePunishment() {
		if (editPunishment) {
			const command =
				'/editpunishment ' +
				props.accountName +
				' ' +
				props.type +
				' ' +
				(newDuration || '+0') +
				' ' +
				editPunishment.reason;

			const commandToSend = {
				channelId: 1,
				commandString: command,
			};

			socket.emit('chat:command:send', commandToSend);
			props.editPunishment(props.type, editPunishment);
		}
		setEditPunishment(null);
		setNewDuration('');
	}

	function renderPunishment(punishment: TAccountPunishment, index: number) {
		const active = new Date(punishment.endDate).getTime() > new Date().getTime();
		const icon = chatIconList[modIcons[punishment.issuerStatus]]?.source;
		return (
			<React.Fragment key={punishment.id}>
				<Box key={`${punishment.id}-issuedby`}>
					<Image src={icon} alt={punishment.issuerStatus} maxWidth='20px' maxHeight='20px' />
					{punishment.issuedBy}
				</Box>
				<Box key={`${punishment.id}-start`}>{new Date(punishment.startDate).toLocaleString()}</Box>
				{editPunishment && editPunishment.id === punishment.id ? (
					<>
						<IdlescapeWrappingTooltip
							content={`New duration of the ${props.type} allows the same time formats as commands: y/mo/w/d/h/m/s. 
								Use a +/- prefix to modify the old duration. Leave empty to keep the current duration and only change the reason.`}
						>
							<Input
								value={newDuration}
								onChange={(e) => setNewDuration(e.target.value)}
								borderRightRadius='0'
								placeholder='New duration'
								key={`${punishment.id}-editduration`}
							/>
						</IdlescapeWrappingTooltip>

						<Textarea
							value={editPunishment.reason}
							onChange={(e) => setEditPunishment({ ...editPunishment, reason: e.target.value })}
							borderLeftRadius='0'
						/>
						<Flex flexDirection='column' gap='20px'>
							<AiFillSave onClick={handleSavePunishment} cursor='pointer' />
							<AiFillCloseCircle onClick={() => setEditPunishment(null)} cursor='pointer' />
						</Flex>
					</>
				) : (
					<>
						<Box key={`${punishment.id}-date`}>
							{(!active ? '(EXPIRED) ' : '') + new Date(punishment.endDate).toLocaleString()}
						</Box>
						<Box wordBreak='break-word' key={`${punishment.id}-reason`}>
							{punishment.reason}
						</Box>
						{!isChatMod && index === 0 ? (
							<AiFillEdit
								onClick={() => setEditPunishment(punishment)}
								cursor='pointer'
								key={`${punishment.id}-edit`}
							/>
						) : (
							<Box key={`${punishment.id}-empty`} />
						)}
					</>
				)}
			</React.Fragment>
		);
	}

	if (!props.punishments) {
		return <TabPanel>No {props.type}s</TabPanel>;
	}

	const sortedPunishments = [...props.punishments].sort((a, b) => {
		return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
	});

	return (
		<TabPanel padding='0'>
			<Grid
				gridTemplateColumns='minmax(30px,120px) minmax(40px,180px) minmax(50px,250px) minmax(80px,500px) 20px'
				gridTemplateRows='25px'
				alignItems='center'
				gap='4px'
			>
				<Box borderBottom='1px solid var(--chakra-colors-gray-700)'>ISSUED BY</Box>
				<Box borderBottom='1px solid var(--chakra-colors-gray-700)'>START DATE</Box>
				<Box borderBottom='1px solid var(--chakra-colors-gray-700)'>END DATE</Box>
				<Box borderBottom='1px solid var(--chakra-colors-gray-700)' gridColumn='span 2'>
					REASON
				</Box>
				{sortedPunishments.map(renderPunishment)}
			</Grid>
		</TabPanel>
	);
}

export default Punishments;
