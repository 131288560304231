import React from 'react';
import { AbsoluteCenter } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { IItem, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { TCosmeticSlots } from '../../../../../game-server/src/modules/player/PlayerSettings';
import type { TEquipmentSlots } from '../../../../../game-server/src/modules/player/PlayerTypes';
import { shareItemSelector } from '../../../atoms/chat/chatHistoryAtom';
import { usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import { itemList } from '../../../utils/itemList';
import SelectionTooltipBox from '../Tooltips/Types/SelectionTooltipBox';
import { Item } from '../Inventory/Item';
import { stringCapitalize } from '../../../helper/StringFormatter';

interface ICombatGearItemProps {
	slot: TEquipmentSlots | TCosmeticSlots;
	type: 'equipment' | 'cosmetics';
}

export const CombatGearItem = (props: ICombatGearItemProps) => {
	const equipment = usePlayerField('equipment');
	const settings = usePlayerField('settings');
	const cosmetics = settings.cosmetic;
	const shareItem = useSetRecoilState(shareItemSelector);

	let equippedItem: TPartialItemWithItemID<IItem> | undefined;
	const selectedCosmetic = cosmetics.selectedCosmetics[props.slot];
	const selectedEquipment = props.slot in equipment && equipment[props.slot as TEquipmentSlots]; // Somehow still wants the typecast despite check
	if (props.type === 'cosmetics' && selectedCosmetic) {
		equippedItem = { itemID: selectedCosmetic };
	} else if (props.type === 'equipment' && selectedEquipment) {
		equippedItem = selectedEquipment;
	}

	let slotString = props.slot.replace('arrows', 'Quiver / Pendant') ?? 'Unknown Slot';
	slotString = stringCapitalize(slotString);

	//Search inventory for cosmetics in real time
	const originalStockpile = usePlayerField('stockpile');
	const stockpile = originalStockpile.map((item) => {
		if (props.type === 'cosmetics') {
			return { itemID: item.itemID, sourceInventory: 'stockpile' } as IItem;
		}
		item.sourceInventory = 'stockpile';
		return item;
	});
	const originalVault = usePlayerField('vault');
	const vault = originalVault.map((item) => {
		if (props.type === 'cosmetics') {
			return { itemID: item.itemID, sourceInventory: 'vault' } as IItem;
		}
		item.sourceInventory = 'vault';
		return item;
	});
	const possibleEquipment = stockpile.concat(vault).filter((item, index, array) => {
		const info = itemList[item.itemID];
		const slot = info.equipmentStats?.cosmeticSlot || info.equipmentStats?.slot;
		if (props.type === 'cosmetics') {
			// Don't show duplicate itemID
			if (array.findIndex((i) => i.itemID === item.itemID) !== index) return false;
			return (
				info.equipmentStats &&
				info.equipmentStats.slot &&
				(info.equipmentStats.isTransmog || info.equipmentStats.fullTransmog) &&
				slot == props.slot
			);
		} else {
			// Cannot equip cosmetics in equipment slots
			if (info.equipmentStats?.cosmeticSlot) {
				return false;
			}
			return (
				info.equipmentStats &&
				info.equipmentStats.slot &&
				(!info.equipmentStats.isTransmog || !info.equipmentStats.fullTransmog) &&
				info.equipmentStats?.slot == props.slot
			);
		}
	});

	function equip(item: IItem) {
		if (props.type === 'cosmetics') {
			socket.emit('settings:cosmetic', {
				slot: props.slot,
				option: item.itemID,
			});
		} else if (item && item.sourceInventory) {
			socket.emit('equipment:equip', {
				inventoryItemID: item.id,
				sourceInventory: item.sourceInventory,
			});
		}
	}

	function unequip() {
		if (props.type === 'cosmetics') {
			socket.emit('settings:cosmetic', {
				slot: props.slot,
				option: -1,
			});
		} else {
			socket.emit('equipment:unequip', { slot: props.slot as TEquipmentSlots });
		}
	}

	function handleOnClick(e: React.MouseEvent) {
		if (e.shiftKey) {
			unequip();
			return true;
		}
		if (e.ctrlKey || e.metaKey) {
			if (!equippedItem || props.type === 'cosmetics') return;
			shareItem((equippedItem.id ?? 0).toString());
			return true;
		} else {
			if (!equippedItem && !(possibleEquipment.length > 0)) return true;
		}
	}

	return (
		<SelectionTooltipBox
			items={possibleEquipment}
			clickItem={equip}
			clickEmpty={unequip}
			showAmount={false}
			id={'gear-' + props.slot}
			className='combat-gear-item'
			onClickBox={handleOnClick}
			cursor={props.type === 'cosmetics' && possibleEquipment.length > 0 ? 'pointer' : ''}
			currentItem={equippedItem}
			title={'Equip ' + slotString}
		>
			{equippedItem && <Item item={equippedItem} width='48px' height='48px' margin='1px' mobileTooltip={false} />}
			{possibleEquipment.length > 0 && (!equippedItem || props.type === 'cosmetics') && (
				<AbsoluteCenter
					textShadow='text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
					fontSize='24px'
					fontWeight='bold'
					color={props.type === 'cosmetics' ? 'rgb(0, 225, 255)' : ''}
				>
					{possibleEquipment.length}
				</AbsoluteCenter>
			)}
		</SelectionTooltipBox>
	);
};
