import React, { useEffect, useState } from 'react';
import { socket } from '../../../../services/socket.service';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import {
	IFarmingPlant,
	IFarmingPos,
	IFarmingSettings,
} from '../../../../../../game-server/src/modules/skills/farming/Farming.interface';
import { getPlantsOverlappingArea, normalizeFarmingSelection } from '../../../../utils/farmingFunctions';
import FarmingGrid from './FarmingGrid';
import FarmingSeeds from './FarmingSeeds';
import FarmingSettings from './FarmingSettings';
import { itemList } from '../../../../utils/itemList';
import { usePlayerField } from '../../../../hooks/hooks';
import { Flex, Grid } from '@chakra-ui/react';
import useIsMobile from '../../../../hooks/useIsMobile';
import { Item } from '../../Inventory/Item';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';

function Farming() {
	const farming = usePlayerField('farming');
	const stockpile = usePlayerField('stockpile');
	const [selectedSeed, setSelectedSeed] = useState<IItem | null>(null);
	const [selectedFertilizer, setSelectedFertilizer] = useState<boolean>(false);
	const [selectedArea, setSelectedArea] = useState<{ start: IFarmingPos; end: IFarmingPos } | null>(null);
	const [settings, setSettings] = useState<IFarmingSettings>({
		autoFertilize: false,
		autoHarvest: true,
		autoPlant: true,
	});
	const isMobile = useIsMobile();
	const cellSize = isMobile ? 50 : 60;
	const borderSize = cellSize / 2;
	const activePanel: 'seeds' | 'settings' = selectedArea === null ? 'seeds' : 'settings';

	useEffect(() => {
		if (selectedSeed) {
			const seedInStockpile = stockpile.find((item) => item.itemID === selectedSeed.itemID);
			if (!seedInStockpile) {
				setSelectedSeed(null);
			}
		} else if (selectedFertilizer) {
			const fertilizerInStockpile = stockpile.find((item) => item.itemID === itemsIds.fertilizer);
			if (!fertilizerInStockpile) {
				setSelectedFertilizer(false);
			}
		}
	}, [stockpile]);

	useEffect(() => {
		const overlappingPlants = getPlantsOverlappingArea(farming.plot, selectedArea?.start, selectedArea?.end);
		if (overlappingPlants.length === 0) {
			setSelectedArea(null);
		}
		setSettingsFromSelectedArea(selectedArea?.start, selectedArea?.end);
	}, [farming]);

	function selectSeed(seed: IItem) {
		if (seed.itemID === selectedSeed?.itemID) {
			setSelectedSeed(null);
		} else {
			setSelectedSeed(seed);
			setSelectedFertilizer(false);
		}
	}

	function selectFertilizer() {
		setSelectedFertilizer((prev) => {
			if (!prev) {
				setSelectedSeed(null);
			}
			return !prev;
		});
		return true;
	}

	function handleSelectedArea(start?: IFarmingPos, end?: IFarmingPos) {
		if (!start || !end) {
			setSelectedArea(null);
			return;
		}
		({ start, end } = normalizeFarmingSelection(start, end));
		const overlappingPlants = getPlantsOverlappingArea(farming.plot, start, end);

		for (const plant of overlappingPlants) {
			const seedData = itemList[plant.seedId];
			if (!seedData.farmingStats) continue;
			if (plant.stage >= seedData.farmingStats?.time) {
				socket.emit('farming:harvestPlants', { start, end });
				break;
			}
		}
		if (selectedSeed) {
			socket.emit('farming:plantSeeds', { start, end, seedID: selectedSeed.itemID, settings });
			setSelectedArea(null);
			return;
		}
		if (selectedFertilizer) {
			socket.emit('farming:fertilizePlants', { start, end });
			setSelectedArea(null);
			return;
		}
		if (overlappingPlants.length === 0) {
			setSelectedArea(null);
			return;
		}
		setSettingsFromPlants(overlappingPlants);
		setSelectedArea({ start, end });
	}

	function setSettingsFromSelectedArea(start?: IFarmingPos, end?: IFarmingPos) {
		const overlappingPlants = getPlantsOverlappingArea(farming.plot, start, end);
		if (overlappingPlants.length === 0) return;

		setSettingsFromPlants(overlappingPlants);
	}

	function setSettingsFromPlants(overlappingPlants: IFarmingPlant[]) {
		// setting is off unless all plants in the selected area have it on
		setSettings({
			autoFertilize: overlappingPlants.every((plant) => plant.autoFertilize),
			autoHarvest: overlappingPlants.every((plant) => plant.autoHarvest),
			autoPlant: overlappingPlants.every((plant) => plant.autoPlant),
		});
	}

	function renderFertilizer() {
		const fertilizer = stockpile.find((item) => item.itemID === itemsIds.fertilizer);
		if (!fertilizer) {
			return <Item item={{ itemID: itemsIds.fertilizer }} variant='disabled' gridArea='fertilizer' />;
		}
		return (
			<Item
				item={fertilizer}
				onClick={selectFertilizer}
				gridArea='fertilizer'
				style={
					selectedFertilizer
						? {
								transform: 'scale(1.05)',
								border: '3px solid white',
								boxShadow: 'black 4px 4px 5px',
						  }
						: {}
				}
			/>
		);
	}

	const farmingGrid = (
		<FarmingGrid
			selectedSeed={selectedSeed}
			resetSelectedSeed={() => {
				setSelectedSeed(null);
				setSelectedFertilizer(false);
			}}
			selectedArea={selectedArea}
			setSelectedArea={handleSelectedArea}
			cellSize={cellSize}
			borderSize={borderSize}
		/>
	);
	const farmingSeeds = <FarmingSeeds selectedSeed={selectedSeed} stockpile={stockpile} selectSeed={selectSeed} />;
	const farmingSettings = (
		<>
			{renderFertilizer()}
			<FarmingSettings
				settings={settings}
				setSettings={setSettings}
				selectedArea={selectedArea}
				setSelectedArea={handleSelectedArea}
			/>
		</>
	);

	if (isMobile) {
		return (
			<Flex gap='14px' direction='column' alignItems='center' justifyContent='center'>
				{farmingGrid}
				{activePanel === 'seeds' && farmingSeeds}
				{activePanel === 'settings' && farmingSettings}
			</Flex>
		);
	}

	return (
		<Flex
			gap='14px'
			flexWrap='wrap'
			justifyContent='center'
			height='100%'
			tabIndex={100}
			onKeyDown={(e) => {
				if (e.key === 'Escape') {
					setSelectedSeed(null);
					setSelectedArea(null);
				}
			}}
		>
			{/* <FarmingInfo /> */}
			<Grid
				gridTemplateAreas='"plot       plot    "
								   "fertilizer settings"'
				justifyItems='center'
				alignItems='center'
				height='fit-content'
				maxWidth={`${cellSize * 6 + borderSize * 2}px`}
				flex={`1 1 ${cellSize * 6 + borderSize * 2}px`}
			>
				{farmingGrid}
				{farmingSettings}
			</Grid>
			{farmingSeeds}
		</Flex>
	);
}

export default Farming;
