import { Grid, Heading, Image, Text } from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';
import { IdlescapeButton, IdlescapeContainer, IdlescapeInput } from '@idlescape/ui';
import _ from 'lodash';
import React, { useState } from 'react';
import { IoSettingsSharp } from 'react-icons/io5';
import { IItem } from '../../../../../../../game-server/src/modules/items/items.interface';
import { filterItem } from '../../../../../helper/helperFunctions';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { itemOrder } from '../../../../../services/PlayerManager';
import { getDustIDFromItemTier, getItemTier } from '../../../../../utils/augmentingFunctions';
import { AFFIX_DUST_PER_RARITY, AFFIX_GEAR_SCRAP_PER_RARITY } from '../../../../../utils/constantsCollection';
import { itemList } from '../../../../../utils/itemList';
import InventoryItem from '../../../Inventory/InventoryItem';
import { ToggleScrappingQueueDialog } from '../../../Inventory/ItemDialogs/ToggleQueueDialogs';

const availableItemFilters = _.concat(
	_.uniq(_.reverse(Object.values(AFFIX_DUST_PER_RARITY))),
	_.uniq(_.reverse(Object.values(AFFIX_GEAR_SCRAP_PER_RARITY)))
);
const utilityButtonStyle = {
	margin: 'auto',
	width: '100%',
	height: '100%',
	padding: '0 1px',
	size: 'large' as 'small' | 'medium' | 'large',
	fontSize: '1rem',
};

export default function ScrappingStockpile({
	items,
	onClick,
	isDragging,
}: {
	items: IItem[];
	onClick: (event: React.MouseEvent, item: IItem) => boolean;
	isDragging: boolean;
}) {
	const [filterString, setFilterString] = useState('');
	const [showFilters, setShowFilters] = useState(false);
	const [itemFilter, setItemFilter] = useState(-1);

	const isMobile = useIsMobile();
	const { setNodeRef } = useDroppable({
		id: 'scrapStockpile',
		data: { items },
	});

	function handleSetFilter(filter: number) {
		if (itemFilter === filter) {
			setItemFilter(-1);
		} else {
			setItemFilter(filter);
		}
	}

	function filterItemByOutput(item: IItem) {
		if (itemFilter === -1) return true;
		const itemData = itemList[item.itemID];
		if (!(itemData.noDustFromResearching ?? false)) {
			const itemTier = getItemTier(itemData);
			const dustID = getDustIDFromItemTier(itemTier);
			if (itemFilter === dustID) return true;
		}
		if (!(itemData.noScrapFromResearching ?? false)) {
			if (itemData.researchesIntoDust) {
				const itemTier = getItemTier(itemData);
				const dustID = getDustIDFromItemTier(itemTier);
				if (itemFilter === dustID) return true;
			} else {
				const scrapID = AFFIX_GEAR_SCRAP_PER_RARITY[itemData.rarity ?? 'common'];
				if (itemFilter === scrapID) return true;
			}
		}
		return false;
	}

	return (
		<IdlescapeContainer as={Grid} gridTemplateRows={'auto auto auto 1fr'}>
			<Heading size={'md'} textAlign='center'>
				Available Items
			</Heading>
			<Grid gridTemplateColumns='1fr auto' padding='0 0.5rem 0.5rem 0.5rem'>
				<IdlescapeInput
					variant='search'
					setValue={setFilterString}
					value={filterString}
					type='search'
					className={'anchor-inventory-filter'}
				/>
				<IdlescapeButton
					{...utilityButtonStyle}
					fontSize='1.8rem'
					onClick={() => setShowFilters(!showFilters)}
					variant={showFilters ? 'green' : 'red'}
				>
					<IoSettingsSharp />
				</IdlescapeButton>
			</Grid>
			{showFilters && (
				<Grid gridTemplateColumns='repeat(5, auto)' paddingX='0.5rem' textAlign='center'>
					{availableItemFilters.map((filter) => {
						const itemData = itemList[filter];
						return (
							<Text as='span' key={filter} onClick={() => handleSetFilter(filter)}>
								<Image
									className={`scrollcrafting-filter-image ${itemFilter === filter && 'clicked'}`}
									src={itemData.itemIcon || itemData.itemImage}
									alt={itemData.name}
								/>
							</Text>
						);
					})}
				</Grid>
			)}
			<Grid
				templateColumns={isMobile ? 'repeat(auto-fill, 50px)' : 'repeat(auto-fill, 60px)'}
				templateRows={isMobile ? 'repeat(auto-fill, 50px)' : 'repeat(auto-fill, 60px)'}
				justifyContent='center'
				gap='0.5rem'
				padding='2px'
				border={isDragging ? '2px dashed rgb(208, 108, 238)' : '2px solid transparent'}
				borderRadius='5px'
				className='anchor-scrapping-stockpile'
				ref={setNodeRef}
				style={{ touchAction: 'none' }}
			>
				{items
					.filter(filterItemByOutput)
					.filter((item) => filterItem(item, filterString))
					.sort(itemOrder)
					.map((item) => {
						return (
							<InventoryItem
								key={item.id}
								item={item}
								overwriteDialog={ToggleScrappingQueueDialog}
								overrideOnClick={(e) => onClick(e, item)}
								// showTooltip={false}
							/>
						);
					})}
			</Grid>
		</IdlescapeContainer>
	);
}
