import { Box, Grid, ImageProps } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import React from 'react';
import { IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { IInspectData } from '../../../../../../game-server/src/modules/profile/Profile';
import useIsMobile from '../../../../hooks/useIsMobile';
import { socket } from '../../../../services/socket.service';
import { ChatChestContent } from '../../../game/Chat/Links/ChatChest';
import { Item } from '../../../game/Inventory/Item';
import { ProfileAddIcon } from './ProfileAddIcon';
import ProfileRemoveIcon from './ProfileRemoveIcon';

export function ChestDisplay({
	chestID,
	amount,
	loot,
	date,
	allowRemove,
	...props
}: {
	chestID: IItemData['id'];
	amount?: number;
	loot?: { amount: number; id: number }[];
	date?: Date;
	allowRemove?: boolean;
} & ImageProps) {
	function removeChest(chestID: IItemData['id']) {
		socket.emit('profile:chest:remove', chestID);
	}
	return (
		<Box position='relative'>
			<IdlescapeWrappingTooltip
				content={
					<ChatChestContent chest={{ chestID, amount: amount ?? 0, loot: loot ?? [], date, type: '€' }} />
				}
			>
				<Item item={{ itemID: chestID, stackSize: amount }} showTooltip={false} {...props} />
			</IdlescapeWrappingTooltip>
			{allowRemove && <ProfileRemoveIcon onClick={() => removeChest(chestID)} />}
		</Box>
	);
}

export function ChestEditor({
	validChests,
	activeChests,
}: {
	validChests: IItemData['id'][];
	activeChests: IInspectData['chests'];
}) {
	const activeChestsIDs = activeChests.map((chest) => chest.chestID);
	const chests = validChests.filter((chest) => !activeChestsIDs.includes(chest));

	const isMobile = useIsMobile();

	if (chests.length === 0) return null;

	function addChest(chestID: IItemData['id']) {
		socket.emit('profile:chest:add', chestID);
	}

	function chestPicker() {
		return (
			<Grid gridTemplateColumns='repeat(auto-fit, 45px)' gap='5px' maxWidth='300px'>
				{chests.map((chest) => (
					<Item
						key={chest}
						item={{ itemID: chest }}
						onClick={() => addChest(chest)}
						height={isMobile ? '30px' : '40px'}
						width={isMobile ? '30px' : '40px'}
						style={{ cursor: 'pointer' }}
					/>
				))}
			</Grid>
		);
	}

	return (
		<IdlescapeWrappingTooltip content={chestPicker()} activateOnClick>
			<ProfileAddIcon />
		</IdlescapeWrappingTooltip>
	);
}
