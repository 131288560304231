import { Box, BoxProps, Image } from '@chakra-ui/react';
import { IdlescapeTooltip } from '@idlescape/ui';
import React from 'react';
import { useGroupField } from '../../../hooks/hooks';
import { locations } from '../../../utils/locationList';

interface CombatGroupSizeProps extends BoxProps {
	locationId: number;
	showPopup: boolean;
	variant?: 'default' | 'combatzone';
}

const combatGroupSizeVariants = {
	default: {
		imageSize: '30px',
		showImage: true,
	},
	combatzone: {
		imageSize: '20px',
		showImage: true,
	},
};

export function CombatGroupSize({ locationId, showPopup, variant = 'default', ...props }: CombatGroupSizeProps) {
	const location = locations[locationId];

	const groupMemberData = useGroupField('groupMemberData');
	const currentGroupSize = groupMemberData?.length ?? 1;
	const maxGroupSize = location.accessRequirements?.maxGroupSize ?? 1;
	const freeSpots = maxGroupSize - currentGroupSize;
	let groupSize = '1';
	let groupTooltip = '';

	if (currentGroupSize > 1) {
		groupSize = `${currentGroupSize}/${maxGroupSize}`;
	} else if (currentGroupSize < maxGroupSize) {
		groupSize = `1-${maxGroupSize}`;
	}

	if (showPopup) {
		if (currentGroupSize > maxGroupSize) {
			groupTooltip = 'Your group is too large to enter this location.';
		} else if (currentGroupSize < maxGroupSize) {
			groupTooltip = `Up to ${freeSpots} additional ${
				freeSpots > 1 ? 'mercenaries' : 'mercenary'
			} will join you if you configured them in your loadouts.`;
		} else {
			groupTooltip = 'There are no free spots in your group. No mercenary will join.';
		}
	}

	if (maxGroupSize <= 1 && currentGroupSize <= maxGroupSize) {
		return <></>;
	}

	const { imageSize, showImage } = combatGroupSizeVariants[variant];

	return (
		<Box color={currentGroupSize > maxGroupSize ? 'red' : ''} position='relative' {...props}>
			<div className={`group-size-${variant}`} style={{ display: 'flex', alignItems: 'center' }}>
				{groupSize}
				{showImage && (
					<Image
						src='/images/ui/social_icon.png'
						alt='group-size'
						height={imageSize}
						width={imageSize}
						marginLeft='7px'
					/>
				)}
			</div>
			{groupTooltip !== '' && <IdlescapeTooltip>{groupTooltip}</IdlescapeTooltip>}
		</Box>
	);
}
