import React, { useEffect, ReactElement } from 'react';
import { locations } from '../../utils/locationList';
import { formatNumberToString } from '../../helper/helperFunctions';
import { EnchantmentData } from './EnchantmentData';
import { tabs } from '../../helper/NavigationTabsList';
import { Box, Flex, Image } from '@chakra-ui/react';
import { usePlayerField } from '../../hooks/hooks';
import { closeTab, openTab } from '../../helper/SendData';
import { IItem, IItemData, TPartialItemWithItemID } from '../../../../game-server/src/modules/items/items.interface';
import { Item } from './Inventory/Item';

const SessionStats = () => {
	const sessionStats = usePlayerField('sessionStats');
	const lootlog = usePlayerField('lootlog');
	const expLog = usePlayerField('explog');

	const itemGain: ReactElement[] = [];
	const itemLoss: ReactElement[] = [];
	const levelChange: ReactElement[] = [];
	const expChange: ReactElement[] = [];
	const enchantProcs: string[] = [];
	const combatDeaths: string[] = [];

	useEffect(() => {
		openTab('session-stats', ['sessionStats', 'lootlog', 'explog']);
		return () => {
			closeTab('session-stats');
		};
	}, []);

	const itemsGained: { id: IItemData['id']; count: number }[] = [];
	const itemsLost: { id: IItemData['id']; count: number }[] = [];
	for (const source of lootlog) {
		for (const item of source.items) {
			let addHere = itemsGained;
			if (item.consumed) {
				addHere = itemsLost;
			}
			const found = addHere.find((i) => i.id === item.itemID);
			if (found) {
				found.count += item.count;
			} else {
				addHere.push({ id: item.itemID, count: item.count });
			}
		}
	}

	for (const skill of expLog) {
		const imagePath = tabs[skill.skillName].icon ?? '';
		const val = formatNumberToString(skill.experience);
		const text = `${val}`;
		const element = StatsSquare({ imagePath, text, key: skill.skillName });
		if (!element) continue;
		expChange.push(element);
	}

	for (const item of itemsGained) {
		const partialItem: TPartialItemWithItemID<IItem> = {
			itemID: item.id,
			stackSize: item.count,
		};
		const element = <Item item={partialItem} variant={'small'} />;
		if (!element) continue;
		itemGain.push(element);
	}

	for (const item of itemsLost) {
		const partialItem: TPartialItemWithItemID<IItem> = {
			itemID: item.id,
			stackSize: item.count,
		};
		const element = <Item item={partialItem} variant={'small'} />;
		if (!element) continue;
		itemLoss.push(element);
	}

	for (const stat of sessionStats) {
		switch (stat.statName) {
			case 'Levels Gained':
				for (const valueIdx of stat.statValue) {
					for (const [key, value] of Object.entries(valueIdx)) {
						const imagePath = tabs[key].icon ?? '';
						const val = formatNumberToString(value);
						const text = `${val}`;
						const element = StatsSquare({ imagePath, text, key });
						if (!element) continue;
						levelChange.push(element);
					}
				}
				break;
			case 'Enchantments':
				for (const valueIdx of stat.statValue) {
					for (const [key, value] of Object.entries(valueIdx)) {
						enchantProcs.push(
							`${EnchantmentData.findEnchantmentByID(key)?.name ?? 'ERROR'}: ${formatNumberToString(
								value
							)} | `
						);
					}
				}
				break;
			case 'Combat Deaths':
				for (const valueIdx of stat.statValue) {
					for (const [key, value] of Object.entries(valueIdx)) {
						combatDeaths.push(`${locations[Number(key)]?.name ?? 'ERROR'}: ${value}, `);
					}
				}
				break;
		}
	}

	// If all the arrays are empty, return the text "No Stats to Display"
	if (
		itemGain.length === 0 &&
		itemLoss.length === 0 &&
		levelChange.length === 0 &&
		expChange.length === 0 &&
		enchantProcs.length === 0 &&
		combatDeaths.length === 0
	) {
		return <div>No Stats to Display</div>;
	}

	return (
		<div>
			<>
				{expChange.length > 0 && (
					<>
						<h2>Experience Change</h2>
						<div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>{expChange}</div>
						<br />
						<hr />
					</>
				)}
				{levelChange.length > 0 && (
					<>
						<h2>Level Change</h2>
						<div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>{levelChange}</div>
						<hr />
					</>
				)}
				{itemGain.length > 0 && (
					<>
						<h2>Item Gain</h2>
						<div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>{itemGain}</div>
						<hr />
					</>
				)}
				{itemLoss.length > 0 && (
					<>
						<h2>Item Loss</h2>
						<div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>{itemLoss}</div>
						<hr />
					</>
				)}
				{enchantProcs.length > 0 && (
					<>
						<h2>Enchantment Tracking</h2>
						{enchantProcs}
						<hr />
					</>
				)}
				{combatDeaths.length > 0 && (
					<>
						<h2>Combat Deaths</h2>
						{combatDeaths}
					</>
				)}
			</>
		</div>
	);
};

export default SessionStats;

type Props = {
	imagePath: string;
	text: string;
	key: string;
	sortValue?: number;
};

const StatsSquare: React.FC<Props> = ({ imagePath, text, key }) => {
	return (
		<Box key={key} width='50px' height='50px' position='relative'>
			<Image src={imagePath} alt='square' width='100%' height='100%' objectFit='contain' />
			<Flex position='absolute' bottom='0' alignItems='center' justifyContent='center' width='100%'>
				<Box
					color='white'
					fontWeight='bold'
					fontSize='16px'
					textShadow='4px 4px 4px black'
					backgroundColor='rgba(0, 0, 0, 0.5)'
					padding='1% 5% 1% 5%'
				>
					{text}
				</Box>
			</Flex>
		</Box>
	);
};
