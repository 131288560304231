import React, { useEffect, useState } from 'react';
import './StatusBar.css';
import { socket } from '../../../services/socket.service';
import Tooltip from '../UI/Tooltip';
import { Buffs } from './Buffs';
import { useGlobalField, usePlayerField } from '../../../hooks/hooks';
import { useSetRecoilState } from 'recoil';
import { IUserBuff } from '../../../../../game-server/src/repositories/UserBuff.repository';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { Box } from '@chakra-ui/react';
import { leagueList } from '../../../utils/leagueList';
import { leaguesIds } from '../../../utils/lookup-dictionaries/lookupLeagueList';
import { capitalize } from 'lodash';
import { ActiveEffects } from './ActiveEffects';
import { changeFaviconToNotify, playNotificationAudio } from '../../../helper/helperFunctions';
import { animationAtom, IAnimationWithTime } from '../../../atoms/animationAtom';
import Timer from '../drawer/timer';

export const StatusBar = () => {
	const setActiveTab = useSetRecoilState(activeTabAtom);

	const [timeToRespawn, setTimeToRespawn] = useState<Date | undefined>();
	const league = usePlayerField('league');
	const madness =
		leagueList[league].id === leaguesIds.magic_catastrophe ||
		leagueList[league].id === leaguesIds.magic_catastrophe_ironman;
	const madnessTheme = useGlobalField('madnessTheme');
	const globalUpdateTime = useGlobalField('updateTime');
	const globalUpdateString = useGlobalField('updateString');

	const settings = usePlayerField('settings');
	const subscription = usePlayerField('subscription');
	const isSubscribed = subscription.active;

	const currentHealth = usePlayerField('currentHealth');
	const buffs = usePlayerField('buffs');
	const icons: React.ReactElement[] = [];
	const actionQueue = usePlayerField('actionQueue');
	const setAnimation = useSetRecoilState(animationAtom);

	useEffect(() => {
		if (!actionQueue) {
			setAnimation(undefined);
		}
		if (isSubscribed) {
			const timeUntilPing = 10000; // 10 seconds
			const timeoutId = setTimeout(() => {
				if (!actionQueue) {
					if (settings.sounds.playSoundOnIdle) {
						playNotificationAudio(settings.sounds.volume);
					}
					changeFaviconToNotify();
				}
			}, timeUntilPing);

			// Cleanup the timeout if actionQueue changes before time is up
			return () => clearTimeout(timeoutId);
		}
	}, [actionQueue]);

	useEffect(() => {
		socket.emit('animation:resume');
		socket.on('combat:respawnTimer', (data) => {
			if (data.timeToRespawn > 0) {
				setTimeToRespawn(new Date(data.timeToRespawn + Date.now()));
			} else {
				setTimeToRespawn(undefined);
			}
		});

		socket.on('animation:start', (data) => {
			if (!data.startTime) data.startTime = Date.now();
			setAnimation(data as IAnimationWithTime);
		});

		return () => {
			socket.off('combat:respawnTimer');
			socket.off('animation:start');
		};
	}, []);

	function openCombatTab() {
		setActiveTab('strength'); // any combat stat works
	}

	if (madness) {
		icons.push(
			<Box
				key='icon-madness'
				onClick={() => setActiveTab('shrine')}
				className='buff'
				backgroundImage='url(/images/combat/stats-icon-enemy.png)'
				backgroundSize='110%'
			>
				<img src='/images/misc/shrine_icon.png' alt='Shrine' />
				<Tooltip>Go to Shrine ... and embrace the {capitalize(madnessTheme)} Madness!</Tooltip>
			</Box>
		);
	}

	if (actionQueue && actionQueue.actionType === 'Action-Combat') {
		//message = 'Fighting: ' + locations[actionQueue.location].name;
		icons.push(
			<React.Fragment key='icon-combat'>
				<div data-tip data-for={'inCombat'} onClick={openCombatTab} className='buff personal'>
					<div className='status-bar-hp'>{currentHealth}</div>
					<img src='/images/combat/constitution_icon.png' alt='Idlescape buff effect'></img>
					<Tooltip>
						<span>You are in combat!</span>
						{actionQueue?.options?.combatWavesToComplete && (
							<>
								<br />
								<span>
									Waves Left:{' '}
									{actionQueue?.options?.combatWavesToComplete -
										(actionQueue?.info?.combatCurrentWave ?? 0)}
								</span>
							</>
						)}
						{!actionQueue?.options?.combatWavesToComplete && (
							<>
								<br />
								<span>Current Wave: {actionQueue?.info?.combatCurrentWave ?? 0}</span>
							</>
						)}
						<br />
						<span>Current Difficulty: {actionQueue?.options?.combatDifficulty ?? 0}</span>
						<br />
						<span>Click to view combat.</span>
					</Tooltip>
				</div>
			</React.Fragment>
		);
		if (timeToRespawn && timeToRespawn > new Date()) {
			icons.push(
				<React.Fragment key='icon-respawn'>
					<div data-tip data-for={'respawnTimer'} onClick={openCombatTab} className='buff personal'>
						<div className='status-bar-hp'>
							<Timer propTime={timeToRespawn} countdown />
						</div>
						<img src='/images/runecrafting/death_essence.png' alt='Idlescape buff effect'></img>
						<Tooltip>
							<span>You are waiting to respawn.</span>
						</Tooltip>
					</div>
				</React.Fragment>
			);
		}
	}

	if (globalUpdateTime > 0) {
		let updateString = globalUpdateString || 'Update pending...';
		// If the updateString contains a hyperlink, remove it
		if (updateString.includes('<a')) {
			updateString = updateString.replace(/<a.*?>/g, '');
			updateString = updateString.replace(/<\/a>/g, '');
		}
		icons.push(
			<React.Fragment key='icon-update'>
				<div data-tip className='buff personal'>
					<div className='status-bar-hp'>{globalUpdateTime}</div>
					<img src='/images/ui/options_gear.png' alt='Idlescape buff effect'></img>
					<Tooltip>
						<span>{updateString}</span>
						<br />
						<span>Time Until Update: {globalUpdateTime} minutes</span>
					</Tooltip>
				</div>
			</React.Fragment>
		);
	}

	const activeProcessed: IUserBuff[] = [];
	const inactiveProcessed: IUserBuff[] = [];

	buffs.forEach((buff) => {
		// Check if buff is already present in activeProcessed and if so put it in inactive processed
		const foundIndex = activeProcessed.findIndex((activeBuff) => activeBuff.enchantmentID === buff.enchantmentID);
		if (foundIndex === -1) {
			// Not in active processed yet
			activeProcessed.push(buff);
		} else {
			// It's in there, check if the current strength > the one in activeProcessed
			if (buff.enchantmentStrength > activeProcessed[foundIndex].enchantmentStrength) {
				inactiveProcessed.push(activeProcessed[foundIndex]);
				activeProcessed[foundIndex] = buff;
			} else {
				inactiveProcessed.push(buff);
			}
		}
	});

	function sortProcessed(processed: IUserBuff[]) {
		processed.sort((a, b) => {
			if (a.enchantmentID < b.enchantmentID) return -1;
			if (a.enchantmentID > b.enchantmentID) return 1;
			if (a.enchantmentStrength > b.enchantmentStrength) return -1;
			if (a.enchantmentStrength < b.enchantmentStrength) return 1;
			return 0;
		});
	}

	// sort the arrays
	sortProcessed(activeProcessed);
	sortProcessed(inactiveProcessed);

	return (
		<div className='status-bar'>
			<div className='buffs-container'>
				<Buffs type={'global'} title={'Global Buffs'} image={'images/chaticons/chat-icon-global.png'} />
				<Buffs type='madness' title='Madness Buffs' image='/images/combat/stats-icon-enemy.png' />
				<Buffs type={'league'} title={'League Buffs'} image={'images/chaticons/chat-icon-global.png'} />
				<Buffs
					type={'active'}
					title={'Active Buffs'}
					image={'images/pontification/skill_clicking_icon.png'}
					buffs={activeProcessed}
				/>
				<Buffs
					type={'inactive'}
					title={'Inactive Buffs'}
					image={'images/pontification/skill_waiting_icon.png'}
					buffs={inactiveProcessed}
				/>
				<ActiveEffects />
				{icons}
			</div>
		</div>
	);
};
