import { Box, Heading, Modal, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined';
import React, { useEffect, useRef } from 'react';
import { TSkillTab } from '../../../../../game-server/src/Atypes/Skills';
import { IItemData } from '../../../../../game-server/src/modules/items/items.interface';
import { ILocationData } from '../../../../../game-server/src/modules/locations/location.interface';
import { usePlayerEffectiveSkillLevel, usePlayerEnchantmentStrength, usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import {} from '../../../utils/constantsCollection';
import {
	getFishingGatherChance,
	getFishingGatherLength,
	getFishingSearchChance,
	getFishingSearchLength,
} from '../../../utils/fishingFunctions';
import { itemList } from '../../../utils/itemList';
import { enchantmentsIds } from '../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { itemsIds } from '../../../utils/lookup-dictionaries/lookupItemList';
import { formatNumber } from '../../../helper/StringFormatter';
import { IProgressBarRef, ProgressBar } from '../../layout/UI/ProgressBar';
import ActionQueueButton from '../ActionQueue/ActionQueueButton';
import { IFishingData } from './Fishing/FishingData';
import './GatheringResource.css';
import { useRecoilValue } from 'recoil';
import { animationAtom } from '../../../atoms/animationAtom';
import GatheringResourceTooltip, { getGatheringTime } from './GatheringResourceTooltip';
import useIsMobile from '../../../hooks/useIsMobile';

export default function GatheringResource(props: { location: ILocationData; fishingData?: IFishingData }) {
	const actionQueue = usePlayerField('actionQueue');
	const skills = usePlayerField('skills');
	const skill = props.location.actionType.replace('Action-', '').toLowerCase() as TSkillTab;
	const effectiveLevel = usePlayerEffectiveSkillLevel(skill);
	const affixes = usePlayerField('affixes');
	const settings = usePlayerField('settings');
	const highPerformance = settings.miscellaneous.highPerformance;
	const running =
		actionQueue?.location === props.location.locID && actionQueue.actionType === props.location.actionType;

	const hasteStrength = usePlayerEnchantmentStrength(enchantmentsIds.haste, skill);
	const fishingStrength = usePlayerEnchantmentStrength(enchantmentsIds.fishing, skill);
	const spiralingOutOfControlStrength = usePlayerEnchantmentStrength(enchantmentsIds.spiraling_out_of_control, skill);
	const hamsterHunterStrength = usePlayerEnchantmentStrength(enchantmentsIds.hamster_hunter, skill);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const isMobile = useIsMobile();

	const fishData = props.fishingData;

	const overallTime = getGatheringTime(
		props.location,
		hasteStrength,
		effectiveLevel,
		spiralingOutOfControlStrength,
		skills.runecrafting.masteryLevel,
		hamsterHunterStrength
	);

	const animation = useRecoilValue(animationAtom);
	const progressBarRef = useRef<IProgressBarRef>(null);
	useEffect(() => {
		if (running && animation) {
			progressBarRef.current?.startAnimation(animation.length, animation.startTime);
		}
	}, [animation]);

	useEffect(() => {
		if (!running) {
			progressBarRef.current?.stopAnimation();
		}
	}, [running]);

	function startWorking() {
		const locID = props.location.locID;
		if (running) {
			socket.emit('action:stop');
		} else {
			socket.emit('action:start', {
				action: skill,
				location: locID,
			});
		}
	}

	function queueButton() {
		const locID = props.location.locID;
		return <ActionQueueButton action={skill} location={locID} className='resource-button' />;
	}

	// calculate required resource list
	const requiredResources: React.ReactElement[] = [];

	//level
	let skillItem: IItemData = itemList[itemsIds.bronze_pickaxe];
	if (props.location.accessRequirements?.requiredSkills) {
		for (const requiredSkill of props.location.accessRequirements.requiredSkills) {
			const reqSkill = requiredSkill.skill;
			const reqLevel = requiredSkill.level;
			if (reqSkill === 'mining') {
				if (reqLevel >= 60) {
					skillItem = itemList[itemsIds.stygian_pickaxe];
				} else if (reqLevel >= 50) {
					skillItem = itemList[itemsIds.runite_pickaxe];
				} else if (reqLevel >= 40) {
					skillItem = itemList[itemsIds.adamantite_pickaxe];
				} else if (reqLevel >= 30) {
					skillItem = itemList[itemsIds.mithril_pickaxe];
				} else if (reqLevel >= 10) {
					skillItem = itemList[itemsIds.iron_pickaxe];
				} else {
					skillItem = itemList[itemsIds.bronze_pickaxe];
				}
			}
			if (reqSkill === 'foraging') {
				if (reqLevel >= 60) {
					skillItem = itemList[itemsIds.stygian_hatchet];
				} else if (reqLevel >= 50) {
					skillItem = itemList[itemsIds.runite_hatchet];
				} else if (reqLevel >= 40) {
					skillItem = itemList[itemsIds.adamantite_hatchet];
				} else if (reqLevel >= 30) {
					skillItem = itemList[itemsIds.mithril_hatchet];
				} else if (reqLevel >= 10) {
					skillItem = itemList[itemsIds.iron_hatchet];
				} else {
					skillItem = itemList[itemsIds.bronze_hatchet];
				}
			}
			if (reqSkill === 'fishing') {
				if (reqLevel >= 85) {
					skillItem = itemList[itemsIds.supreme_tacklebox];
				} else if (reqLevel >= 65) {
					skillItem = itemList[itemsIds.pro_tacklebox];
				} else if (reqLevel >= 50) {
					skillItem = itemList[itemsIds.advanced_tacklebox];
				} else if (reqLevel >= 20) {
					skillItem = itemList[itemsIds.novice_tacklebox];
				} else {
					skillItem = itemList[itemsIds.basic_tacklebox];
				}
			}
			requiredResources.push(
				<IdlescapeWrappingTooltip content='Level Required'>
					<div className='resource-node-time-tooltip'>
						<img src={skillItem.itemImage} alt='level' className='icon16' />
						<span>{reqLevel}</span>
					</div>
				</IdlescapeWrappingTooltip>
			);
		}
	}
	const requiredLevel = props.location?.accessRequirements?.requiredSkills?.[0].level ?? 0;

	// time and chance
	if (!fishData) {
		requiredResources.push(
			<IdlescapeWrappingTooltip content='Time required'>
				<div className='resource-node-time-tooltip'>
					<img src='/images/clock.png' alt='time' className='icon16' />
					<span>{formatNumber(overallTime)}s</span>
				</div>
			</IdlescapeWrappingTooltip>
		);
	} else {
		const searchTime = getFishingSearchLength(props.location, 1 + hasteStrength, fishData.bait, 0);
		const gatherTime = getFishingGatherLength(props.location, 1 + hasteStrength, fishData.reel);

		requiredResources.push(
			<IdlescapeWrappingTooltip content='Searching Time | Gathering Time'>
				<div className='resource-node-time-tooltip'>
					<img src='/images/clock.png' alt='time' className='icon16' />
					<span>
						~{formatNumber(searchTime / 1000)}s | ~{formatNumber(gatherTime / 1000)}s
					</span>
				</div>
			</IdlescapeWrappingTooltip>
		);

		const fishingSuccessBonus = affixes.find((a) => a.path === 'fishing.success_chance')?.value ?? 0;

		let findChance = getFishingSearchChance(
			fishingStrength,
			fishData.levelLoc[props.location.locID],
			fishData.bait,
			fishingSuccessBonus,
			1
		);
		findChance = Math.min(1, findChance) * 100;

		let gatherChance = getFishingGatherChance(
			fishingStrength,
			fishData.levelLoc[props.location.locID],
			fishData.reel,
			1,
			1,
			fishingSuccessBonus
		);
		gatherChance = Math.min(1, gatherChance) * 100;

		requiredResources.push(
			<IdlescapeWrappingTooltip
				content={
					<>
						<span>Base Find Chance | Base Gather Chance</span>
						<br />
						<span>Will generally be higher in practice</span>
					</>
				}
			>
				<div className='resource-node-time-tooltip'>
					<img src='/images/fishing/chance_icon.png' alt='chance' className='icon16' />
					<span>
						~{formatNumber(findChance)}% | ~{formatNumber(gatherChance)}%
					</span>
				</div>
			</IdlescapeWrappingTooltip>
		);
	}

	// Experience
	if (props.location.xpPerCompletion && props.location.xpPerCompletion.length && props.location.actionType) {
		let xp = 0;
		const found = props.location.xpPerCompletion.find((e) =>
			props.location.actionType.toLowerCase().includes(e.skill)
		);
		if (found) {
			xp = found.amount;
		}
		xp = Math.floor(xp);
		if (xp > 0) {
			requiredResources.push(
				<IdlescapeWrappingTooltip content='Experience Per Action'>
					<div className='resource-node-time-tooltip'>
						<img src='/images/total_level.png' alt='total_level' className='icon16' />
						<span>{xp}</span>
					</div>
				</IdlescapeWrappingTooltip>
			);
		}
	}

	return (
		<Box className='resource-container' backgroundImage={props.location.locationImage}>
			{requiredLevel > skills[skill].level && (
				<div className='locked-cover'>
					<div>
						Recommended Tool: <img src={skillItem.itemImage} className='icon60' alt={skillItem.name} />
						<hr></hr>
						Required Level: {requiredLevel}
					</div>
				</div>
			)}
			<Heading
				as={'h3'}
				size='md'
				textAlign={'center'}
				textShadow={'0 0 5px black'}
				backgroundColor={'#0000005e'}
				marginTop={'0'}
				padding={'10px 0'}
				backdropFilter={'blur(3px)'}
				borderTopRadius={'10px'}
			>
				{props.location.name}
			</Heading>
			<IdlescapeWrappingTooltip
				activateOnClick
				content={isMobile ? '' : <GatheringResourceTooltip location={props.location} fishData={fishData} />}
				positions={['left', 'right', 'top', 'bottom']}
			>
				<div className='resource-node-tooltip-container' onClick={onOpen}>
					<InfoOutlineIcon id='resource-node-tooltip' />
				</div>
			</IdlescapeWrappingTooltip>
			<img
				className='resource-container-image'
				src={'/images/transparent-do-not-delete.png'}
				alt={props.location.name}
			/>
			{!highPerformance && (
				<ProgressBar
					className='resource-container-progress'
					ref={progressBarRef}
					value={0}
					max={100}
					theme='default'
					gridArea='bar'
				/>
			)}

			<div className='resource-required-resources'>
				{requiredResources.map((requiredResource, index) => (
					<div key={props.location.locID + 'requiredResource' + index} className='resource-property'>
						{requiredResource}
					</div>
				))}
			</div>

			<div className='resource-container-button'>
				<button
					className='resource-button'
					disabled={requiredLevel > skills[skill].level}
					onClick={startWorking}
				>
					{actionQueue && props.location.locID === actionQueue.location
						? 'Stop'
						: props.location.actionVerb ?? props.location.actionType.replace('Action-', '')}
				</button>
				{queueButton()}
			</div>
			{isMobile && (
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent width='unset'>
						<ModalCloseButton />
						<GatheringResourceTooltip location={props.location} fishData={fishData} />
					</ModalContent>
				</Modal>
			)}
		</Box>
	);
}
