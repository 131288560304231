import { Box, Flex, Grid, Heading, Image, Text } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer, IdlescapeFrameBox, IdlescapeWrappingTooltip } from '@idlescape/ui';
import React, { useEffect, useRef, useState } from 'react';
import {
	IItem,
	IItemData,
	TPartialItemWithItemID,
} from '../../../../../../../game-server/src/modules/items/items.interface';
import { usePlayerAffixStrength, usePlayerEnchantmentStrength, usePlayerField } from '../../../../../hooks/hooks';
import { socket } from '../../../../../services/socket.service';
import {
	augmentingBlockReason,
	getScrapExperience,
	scrappingTime,
	getAugmentChance,
	getAugmentingTrialCount,
	getAugmentItemCopyCost,
	itemCanBeAugmented,
} from '../../../../../utils/augmentingFunctions';
import { getTimeString } from '../../../../../utils/helperFunctions';
import { itemList } from '../../../../../utils/itemList';
import { enchantmentsIds } from '../../../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { locationsIds } from '../../../../../utils/lookup-dictionaries/lookupLocationList';
import { IProgressBarRef, ProgressBar } from '../../../../layout/UI/ProgressBar';
import Tooltip from '../../../../layout/UI/Tooltip';
import ActionQueueButton from '../../../ActionQueue/ActionQueueButton';
import ResourceCost from '../../ResourceCost';
import AugmentingCost from './AugmentingCost';
import AugmentingItemStats from './AugmentingItemStats';
import { augmentingLootList } from '../../../../../utils/augmentingLootList';
import { Item } from '../../../Inventory/Item';
import { useRecoilValue } from 'recoil';
import { animationAtom } from '../../../../../atoms/animationAtom';

export default function AugmentingItemInfo(props: { previewItem: IItem | null }) {
	const augmentingItemSlot = usePlayerField('augmentingItemSlot');
	const settings = usePlayerField('settings');
	const maxAugmentLevel = settings.augmenting.maxAugmentLevel;
	const stockpile = usePlayerField('stockpile');
	const skills = usePlayerField('skills');
	const enchantingLevel = skills.enchanting.level;
	const masteryEnchantingLevel = skills.enchanting.masteryLevel;
	const skillEquipmentStats = usePlayerField('skillEquipmentStats');
	const enchantingToolBoost = skillEquipmentStats.enchanting;
	const effectiveEnchantingLevel = enchantingLevel + masteryEnchantingLevel + enchantingToolBoost;

	const hasteStrength = usePlayerEnchantmentStrength(enchantmentsIds.haste, 'enchanting');
	const augmentationAfficionadoStrength = usePlayerEnchantmentStrength(
		enchantmentsIds.augmentation_afficionado,
		'enchanting'
	);
	const chanceAffixStrength = usePlayerAffixStrength('enchanting.augmenting_success_chance');
	const successChanceBoost = chanceAffixStrength + augmentationAfficionadoStrength;

	const displayedItem = props.previewItem ?? (augmentingItemSlot.length !== 0 ? augmentingItemSlot[0] : undefined);
	const displayedItemData = displayedItem ? itemList[displayedItem.itemID] : undefined;
	const baseCopyCost =
		displayedItem && displayedItemData ? getAugmentItemCopyCost(displayedItem, displayedItemData) : 0;

	const augmentThreshold = getAugmentingTrialCount(
		displayedItem ?? ({ augmentations: 0 } as IItem),
		displayedItemData ?? ({} as IItemData)
	);
	const transform = displayedItem
		? augmentingLootList[displayedItem.itemID]?.transforms?.filter((t) => t.augmentingTransform)[0]
		: undefined;
	const transformData = transform ? itemList[transform.newItemID] : undefined;
	const transformItem: TPartialItemWithItemID<IItem> | undefined = transformData
		? {
				itemID: transformData.id,
				enchantmentID: transformData.forcedEnchant,
				enchantmentStrength: transformData.forcedEnchantAmount,
		  }
		: undefined;

	const blockReason = displayedItem
		? augmentingBlockReason(
				displayedItem,
				effectiveEnchantingLevel,
				masteryEnchantingLevel,
				stockpile,
				maxAugmentLevel,
				itemList
		  )
		: false;

	const highPerformance = settings.miscellaneous.highPerformance;
	const actionQueue = usePlayerField('actionQueue');
	const active = actionQueue?.location === locationsIds.the_academy_of_augmentation;

	const [animating, setAnimating] = useState('');

	useEffect(() => {
		socket.on('augmenting:response', (data) => {
			if (data.response) {
				setAnimating(data.response);
				setTimeout(() => {
					setAnimating('');
				}, 1000);
			}
		});

		return () => {
			socket.off('augmenting:response');
		};
	}, []);

	const animation = useRecoilValue(animationAtom);
	const progressBarRef = useRef<IProgressBarRef>(null);
	useEffect(() => {
		if (active && animation) {
			progressBarRef.current?.startAnimation(animation.length, animation.startTime);
		}
	}, [animation]);

	useEffect(() => {
		if (!active) {
			progressBarRef.current?.stopAnimation();
		}
	}, [active]);

	function startStopAugmenting() {
		if (active) {
			socket.emit('action:stop');
		} else {
			socket.emit('action:start', { action: 'enchanting', location: locationsIds.the_academy_of_augmentation });
		}
	}

	function queueButton() {
		return (
			<ActionQueueButton
				action='enchanting'
				location={locationsIds.the_academy_of_augmentation}
				variant='orange'
			/>
		);
	}

	function canStartAugmenting() {
		if (displayedItem) {
			return itemCanBeAugmented(
				displayedItem,
				effectiveEnchantingLevel,
				skills.enchanting.masteryLevel,
				stockpile,
				settings.augmenting.maxAugmentLevel,
				itemList
			);
		}
		return false;
	}

	return (
		<IdlescapeContainer as={Flex} flexDirection='column' alignItems='center' width='100%' order={-1}>
			<Heading size={'md'} textAlign='center'>
				Item Details{props.previewItem !== null ? ' (Preview)' : ' '}
			</Heading>
			{displayedItem !== undefined && displayedItemData !== undefined ? (
				<>
					<Grid
						gridTemplateColumns='2fr 1fr'
						gridTemplateRows='auto auto'
						alignItems='center'
						justifyItems='center'
						gap='5px'
						marginBottom='5px'
					>
						<Text fontSize='lg' textAlign='center' marginBottom='0'>
							{displayedItem.name}
						</Text>
						<Item
							item={displayedItem}
							gridRow='span 2'
							animation={
								props.previewItem === null && active && animating !== ''
									? `augmenting-${animating} 1000ms linear`
									: ''
							}
						/>
						{props.previewItem === null && (
							<IdlescapeButton
								onClick={startStopAugmenting}
								variant={canStartAugmenting() ? 'purple' : 'disabled'}
								className={canStartAugmenting() ? 'enchanting-glowing-button' : ''}
								margin='12px'
							>
								{active ? 'Stop' : 'Start'}
							</IdlescapeButton>
						)}
						{queueButton()}
					</Grid>
					<IdlescapeWrappingTooltip content="Each action increases the counter by 1. When the bar is filled, the item's augmentation level is increased by 1 and the counter resets.">
						<Flex
							position='relative'
							width='100%'
							maxWidth='250px'
							height='20px'
							justifyContent='center'
							alignItems='center'
						>
							<ProgressBar
								position='absolute'
								height='100%'
								value={displayedItem.augmentCounter ?? 0}
								min={0}
								max={augmentThreshold}
								theme='augment-counter'
							/>
							<Box position='relative'>
								Augment Counter: {displayedItem.augmentCounter ?? 0}/{augmentThreshold}
							</Box>
						</Flex>
					</IdlescapeWrappingTooltip>
					{blockReason && (
						<Text fontSize='lg' textAlign='center' color='red.500' margin='0 0 5px 0'>
							{blockReason}
						</Text>
					)}
					{!highPerformance && props.previewItem === null && (
						<Box width='100%'>
							<ProgressBar
								ref={progressBarRef}
								value={0}
								max={100}
								theme='augmenting'
								height='17px'
								marginY='8px'
							/>
						</Box>
					)}
				</>
			) : (
				<Text fontSize='lg' textAlign='center'>
					No Item in the queue
				</Text>
			)}
			<Flex alignItems='center' flexDirection='column' minWidth='200px'>
				{baseCopyCost > 0 && displayedItemData && (
					<IdlescapeFrameBox
						variant='primary'
						padding='8px 15px'
						width='100%'
						_hover={{}} // This overrides the default hover effect to get rid of it
					>
						<ResourceCost
							resourceCosts={[{ resource: displayedItemData, count: baseCopyCost }]}
							replaceTooltip={'Consumed only when increasing the item level.'}
						/>
					</IdlescapeFrameBox>
				)}
				<AugmentingCost item={displayedItem} />
				{transform && transformItem && (
					<>
						Transforms at +{transform.augmentationLevel}
						<Item item={transformItem} />
					</>
				)}
				{displayedItem !== undefined && displayedItemData !== undefined && (
					<IdlescapeFrameBox
						as={Grid}
						gridTemplateColumns='repeat(3, 1fr)'
						alignItems='center'
						justifyItems='center'
						width='100%'
						padding='8px 15px'
						_hover={{}}
					>
						<Image src='/images/clock.png' alt='Duration' width='25px' height='25px' />
						<IdlescapeWrappingTooltip content='Critical Augmentation! Chance for an additional augment counter.'>
							<Image src='/images/fishing/chance_icon.png' alt='Chance' width='25px' height='25px' />
						</IdlescapeWrappingTooltip>
						<IdlescapeWrappingTooltip content='Experience per augmentation.'>
							<Image src='/images/total_level.png' alt='Experience' width='25px' height='25px' />
						</IdlescapeWrappingTooltip>
						<Text margin='2px' className='anchor-augmenting-time'>
							{getTimeString(scrappingTime(displayedItemData, effectiveEnchantingLevel, hasteStrength))}
						</Text>
						<Text margin='2px' className='anchor-augmenting-chance'>
							{getAugmentChance(
								effectiveEnchantingLevel,
								displayedItem,
								displayedItemData,
								successChanceBoost
							).toLocaleString('en-us', { style: 'percent', minimumFractionDigits: 2 })}
						</Text>
						<Text margin='2px' className='anchor-augmenting-exp'>
							{getScrapExperience(displayedItemData, displayedItem)}
						</Text>
					</IdlescapeFrameBox>
				)}
				<Box position='relative'>
					<AugmentingItemStats item={displayedItem} />
					<Tooltip>Item stats gained per augmentation level.</Tooltip>
				</Box>
			</Flex>
		</IdlescapeContainer>
	);
}
