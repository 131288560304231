import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IItem } from '../../../game-server/src/modules/items/items.interface';
import { PlayerData } from '../../../game-server/src/modules/player/PlayerData';
import { EnchantmentData } from '../components/game/EnchantmentData';
import type { RootState, AppDispatch } from '../store';
import { TAffixesPaths } from '../../../game-server/src/modules/affixes/affixes.interface';
import { TSkillTab } from '../../../game-server/src/Atypes/Skills';
import { IGlobals } from '../../../game-server/src/modules/globals/Globals';
import { TSettingsListClient, TSettingsPath } from '../../../game-server/src/modules/player/PlayerSettings';
import _ from 'lodash';
import { IGroupClientData } from '../../../game-server/src/modules/group/Group';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useGlobalField = <K extends keyof IGlobals>(field: K) =>
	useAppSelector((state) => state.game.globals?.[field]);

// Group data can be uninitialized
export const useGroupField = <K extends keyof IGroupClientData>(field: K): IGroupClientData[K] | undefined =>
	useAppSelector((state) => state.group.groupData?.[field]);

export const usePlayerField = <K extends keyof PlayerData>(field: K) =>
	useAppSelector((state) => state.game.playerInformation?.[field]);

// For components that can be loaded before the player data is available
export const useUnsavePlayerField = <K extends keyof PlayerData>(field: K): PlayerData[K] | undefined =>
	useAppSelector((state) => state.game.playerInformation?.[field]);

export const usePlayerEffectiveSkillLevel = (skillName: TSkillTab): number =>
	useAppSelector((state) =>
		Math.max(
			1,
			state.game.playerInformation.skills[skillName].level +
				state.game.playerInformation.skills[skillName].masteryLevel +
				state.game.playerInformation.skillEquipmentStats[skillName]
		)
	);

export const usePlayerResourceStockpileAmount = (itemID: number): number =>
	useAppSelector(
		(state) => state.game.playerInformation.stockpile.find((item: IItem) => item.itemID === itemID)?.stackSize || 0
	);

export const usePlayerEnchantmentStrength = (enchantmentID: number, skillName: TSkillTab): number =>
	useAppSelector((state) =>
		EnchantmentData.getEnchantmentStrength(
			enchantmentID,
			state.game.playerInformation,
			state.game.globals,
			skillName
		)
	);

export const usePlayerAffixStrength = (affixPath: TAffixesPaths): number =>
	useAppSelector((state) => {
		const affixes = state.game.playerInformation?.affixes ?? [];
		const affix = affixes.find((affix) => affix.path === affixPath);
		return affix?.value ?? 0;
	});

export const usePlayerQuestProgress = (questID: number): number =>
	useAppSelector((state) => {
		const questProgress = state.game.playerInformation?.questProgress ?? [];
		const quest = questProgress.filter((quest) => quest.id === questID);
		if (!quest.length) {
			return -1;
		}
		const step = quest.reduce((acc, curr) => (acc.stepID > curr.stepID ? acc : curr));
		return step.stepID;
	});

export const usePlayerQuestCompleted = (questID: number): boolean =>
	useAppSelector((state) => {
		const questsCompleted = state.game.playerInformation?.questsCompleted ?? [];
		return questsCompleted.some((quest) => quest.id === questID);
	});

export const usePlayerSetting = <K extends TSettingsPath>(setting: K) =>
	useAppSelector((state) => {
		const settings = state.game.playerInformation.settings;
		return _.get(settings, setting) as TSettingsListClient[K]['default'];
	});
