import { atom } from 'recoil';
import { IDetailsCommandView } from '../../../game-server/src/modules/chat/Chat.interface';

export const DetailsAtom = {
	currentCharacter: atom({
		key: 'currnetCharacterDetailsAtom',
		default: -1,
	}),
	command: atom({
		key: 'commandDetailsAtom',
		default: undefined as { channelId: number | null; channelName?: string; commandString: string } | undefined,
	}),
	accountCreated: atom({
		key: 'accountCreatedDetailsAtom',
		default: new Date() as IDetailsCommandView['accountCreated'],
	}),
	muteHistory: atom({
		key: 'muteHistoryDetailsAtom',
		default: undefined as IDetailsCommandView['muteHistory'],
	}),
	shadowMuteHistory: atom({
		key: 'shadowMuteHistoryDetailsAtom',
		default: undefined as IDetailsCommandView['shadowMuteHistory'],
	}),
	tradebanHistory: atom({
		key: 'tradebanHistoryDetailsAtom',
		default: undefined as IDetailsCommandView['tradebanHistory'],
	}),
	banHistory: atom({
		key: 'banHistoryDetailsAtom',
		default: undefined as IDetailsCommandView['banHistory'],
	}),
	usernameHistory: atom({
		key: 'usernameHistoryDetailsAtom',
		default: undefined as IDetailsCommandView['usernameHistory'],
	}),
	notes: atom({
		key: 'notesDetailsAtom',
		default: undefined as IDetailsCommandView['notes'],
	}),
	characterData: atom({
		key: 'characterDataDetailsAtom',
		default: undefined as IDetailsCommandView['characterData'],
	}),
	accountData: atom({
		key: 'accountDataDetailsAtom',
		default: undefined as IDetailsCommandView['accountData'],
	}),
	chatChannels: atom({
		key: 'chatChannelsDetailsAtom',
		default: {} as IDetailsCommandView['chatChannels'],
	}),
	actionQueues: atom({
		key: 'actionQueuesDetailsAtom',
		default: {} as IDetailsCommandView['actionQueues'],
	}),
	subscriptions: atom({
		key: 'subscriptionsDetailsAtom',
		default: [] as IDetailsCommandView['subscriptions'],
	}),
	subsGiftedBy: atom({
		key: 'subsGiftedByDetailsAtom',
		default: [] as IDetailsCommandView['subsGiftedBy'],
	}),
};
