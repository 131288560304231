import React from 'react';
import { locations } from '../../../utils/locationList';
import { Box, Flex, Image } from '@chakra-ui/react';
import { IdlescapeTooltip, IdlescapeWrappingTooltip } from '@idlescape/ui';
import { cloneDeep } from 'lodash';
import { CombatGroupSize } from './CombatGroupSize';
import { usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import { locationsIds } from '../../../utils/lookup-dictionaries/lookupLocationList';
import { itemsIds } from '../../../utils/lookup-dictionaries/lookupItemList';
import { itemList } from '../../../utils/itemList';
import { tabs } from '../../../helper/NavigationTabsList';
import { enchantmentsList } from '../../../utils/enchantmentList';
import { enchantmentsIds } from '../../../utils/lookup-dictionaries/lookupEnchantmentList';

const locationScroll = {
	[locationsIds.farm]: itemsIds.clustercluck,
	[locationsIds.caves]: itemsIds.stew_stained_page,
	[locationsIds.city]: itemsIds.black_knights_trial,
	[locationsIds.fallen_academy]: itemsIds.fallen_academy_scroll,
	[locationsIds.lava_maze]: itemsIds.demonic_trial,
	[locationsIds.corrupted_lands]: itemsIds.corroded_scroll,
	[locationsIds.valley_of_giants]: itemsIds.giant_scroll,
	[locationsIds.overgrown_woods]: itemsIds.overgrown_woods_scroll,
	[locationsIds.enchanted_armory]: itemsIds.enchanted_armory_scroll,
	[locationsIds.elven_encampment]: itemsIds.elven_encampment_scroll,
	[locationsIds.chaos_wastes]: itemsIds.shapeless_scroll,
};

export const CombatZone = (props: {
	zoneID: number;
	zoneName: string;
	eliteChallenge: number;
	recommendedLevel: string;
	recommendedStyle: string;
	openLocationInfo?: (locationID: number) => void;
}) => {
	const skillIcons: Record<string, string> = {
		Melee: tabs.strength.icon,
		Magic: tabs.magic.icon,
		Range: tabs.range.icon,
		'Master/Legendary Skilling Gear': enchantmentsList[enchantmentsIds.danger_zone].buffIcon,
		Lightning: '/images/combat/splashes/Lightning_splash.png',
	};
	const settings = usePlayerField('settings');
	const quickStart = settings.combat.zoneSpecificSettings[props.zoneID]?.quickStart;

	const location = locations[props.zoneID];
	const imageStyle = location.locationImageStyle ? cloneDeep(location.locationImageStyle) : {};
	let backgroundImage = `${location.locationImage}`;
	if (imageStyle.linearGradient) {
		backgroundImage = `linear-gradient(${imageStyle.linearGradient}), url(${location.locationImage})`;
		delete imageStyle.linearGradient;
	}

	function startCombat() {
		if (location.relatedKey !== undefined) {
			// This shouldn't happen, since the option is only shown for normal combat locations
			return;
		}
		socket.emit('action:start', { action: 'combat', location: props.zoneID });
	}

	let scrollImage = '/images/misc/Elite_Scroll.png';
	let scrollName = '';
	if (locationScroll[props.zoneID] && itemList[locationScroll[props.zoneID]]) {
		scrollImage = itemList[locationScroll[props.zoneID]].itemImage ?? scrollImage;
		scrollName = itemList[locationScroll[props.zoneID]].name ?? scrollName;
	}

	// Determine which styles to show based on props.recommendedStyle
	let styles: string[] = [];
	if (props.recommendedStyle === 'Any') {
		styles = ['Melee', 'Magic', 'Range'];
	} else {
		styles = props.recommendedStyle.split(';');
	}

	return (
		<Box className='combat-zone' backgroundImage={backgroundImage} {...imageStyle}>
			<Flex>
				{props.openLocationInfo && (
					<Box
						className='combat-container-button idlescape-button idlescape-button-green'
						onClick={() => props.openLocationInfo?.(props.zoneID)}
						flex='2'
					>
						Go to Location
					</Box>
				)}
				{quickStart && (
					<Box
						className='combat-container-button idlescape-button idlescape-button-blue'
						onClick={() => startCombat()}
						flex='1'
					>
						Quick Start
					</Box>
				)}
			</Flex>
			<Flex justifyContent='space-between' alignItems='flex-start'>
				<Box display='flex' flexDirection='column' alignItems='flex-start' width='calc(60% - 5px)'>
					<div className='combat-zone-text combat-zone-text-name'>{props.zoneName}</div>
					<div className='combat-zone-text' style={{ display: 'flex', alignItems: 'center' }}>
						{styles.map((style, index) => {
							const styleIcon = skillIcons[style];
							return (
								<React.Fragment key={index}>
									{styleIcon ? (
										<IdlescapeWrappingTooltip content={style}>
											<Image
												src={styleIcon}
												alt={style}
												width='20px'
												height='20px'
												marginRight='2px'
											/>
										</IdlescapeWrappingTooltip>
									) : (
										<Box color='#818181'>{style}</Box>
									)}
									{index < styles.length - 1 && <Box color='#818181'>/</Box>}
								</React.Fragment>
							);
						})}
						<Box marginLeft={'5px'}>{props.recommendedLevel}</Box>
					</div>
				</Box>
				<Box display='flex' flexDirection='column' alignItems='flex-end' width='calc(40% - 5px)'>
					<CombatGroupSize locationId={props.zoneID} showPopup={false} variant='combatzone' />
					{typeof props.eliteChallenge === 'number' && props.eliteChallenge > 0 && (
						<div className='combat-zone-elite-challenge'>
							{props.eliteChallenge}
							<Image
								src={scrollImage ?? '/images/misc/Elite_Scroll.png'}
								alt={scrollName ?? 'Elite Scroll'}
								height='20px'
								width='20px'
								marginLeft='7px'
							/>
							{scrollName !== '' && <IdlescapeTooltip>{scrollName}</IdlescapeTooltip>}
						</div>
					)}
				</Box>
			</Flex>
		</Box>
	);
};
