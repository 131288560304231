import React, { ReactElement, useState } from 'react';
import Tooltip from './UI/Tooltip';
import { leagueList } from '../../utils/leagueList';
import { useGlobalField } from '../../hooks/hooks';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Button } from '@chakra-ui/react';
import { playerCountAtom } from '../../atoms/playerCountAtom';
import { useRecoilState } from 'recoil';
import { ITab, tabs } from '../../helper/NavigationTabsList';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';

export default function PlayerCount() {
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const [playerCountTab, setPlayerCountTab] = useRecoilState(playerCountAtom);
	const characterOnlineData = useGlobalField('characterOnlineData');
	const sumOnline = Object.values(characterOnlineData.online).reduce((acc, online) => acc + online, 0);

	function getTab(actionType: string): ITab {
		if (actionType === 'idle') {
			return tabs['action-queue'];
		}
		const thisTab = tabs[actionType as keyof typeof tabs];
		return thisTab;
	}

	function renderActivity() {
		const activity = characterOnlineData.globalActivity?.activity;
		if (!activity) {
			return <>Activity Stats Loading...</>;
		}
		// Activity is an object with keys of action types and a value of another object with a key of league id and value of number of players
		const activityEntries = Object.entries(activity);
		return (
			<>
				{activityEntries.map(([actionType, leagueData]) => {
					const actionString = actionType.replace('Action-', '').toLowerCase();
					const tab = getTab(actionString);
					const activitySum = Object.values(leagueData).reduce((acc, count) => acc + count, 0);
					const leagueContent: ReactElement[] = [];
					const leagueObj = <>{leagueContent}</>;
					return (
						<div key={`activity-${actionString}`}>
							<IdlescapeWrappingTooltip content={leagueObj} positions={['bottom', 'top']}>
								{/* I don't know what to style it as; it's not actually a button but I like the way the button looks lmao */}
								<Button
									variant='unstyled'
									display='flex'
									alignItems='center'
									height='auto'
									padding='2px'
									minWidth='auto'
								>
									<img
										src={tab?.overrideTabIcon ?? tab?.icon ?? '/images/ui/equipment_icon.png'}
										className='header-league-icon'
										alt={tab?.name ?? actionString}
									/>
									{activitySum}
								</Button>
							</IdlescapeWrappingTooltip>
							{Object.entries(leagueData).map(([leagueId, count]) => {
								if (count === 0) return null;
								const leagueData = leagueList[Number(leagueId)];
								const content = (
									<div key={`activity-${actionString}-${leagueId}`}>
										<img
											src={leagueData.icon}
											className='header-league-icon'
											alt={leagueData.name}
										/>
										{count}
									</div>
								);
								leagueContent.push(content);
							})}
						</div>
					);
				})}
			</>
		);
	}

	function renderPlayerCount() {
		return (
			<>
				{(sumOnline <= 0 || isNaN(sumOnline)) && <span>Online Counter Loading...</span>}
				{sumOnline > 0 &&
					Object.entries(characterOnlineData.online).map(([leagueId, count]) => {
						if (count === 0) return;
						const leagueData = leagueList[Number(leagueId)];
						return (
							<div key={`leagueheader-${leagueId}`}>
								<img src={leagueData.icon} className='header-league-icon' alt={leagueData.name} />
								{count}
							</div>
						);
					})}
			</>
		);
	}

	return (
		<div className='navbar1-box player-counter' id='usersOnline' onClick={() => setIsTooltipOpen(!isTooltipOpen)}>
			<img src='/images/ui/social_icon.png' className='header-league-icon' alt='Player Count' />
			{Number.isNaN(sumOnline) ? 'X' : sumOnline}
			<Tooltip
				className={'player-counter-tooltip'}
				isOpen={isTooltipOpen}
				onClickOutside={() => setIsTooltipOpen(false)}
			>
				<Tabs align='center' index={playerCountTab} onChange={setPlayerCountTab}>
					<TabList>
						<Tab onMouseDown={(e) => e.preventDefault()}>Online</Tab>
						<Tab onMouseDown={(e) => e.preventDefault()}>Activity</Tab>
					</TabList>
					<TabPanels>
						<TabPanel paddingX='0' paddingBottom='0'>
							{renderPlayerCount()}
						</TabPanel>
						<TabPanel paddingX='0' paddingBottom='0'>
							{renderActivity()}
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Tooltip>
		</div>
	);
}
