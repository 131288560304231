import React from 'react';
import { IChatSkill } from '../../../../../../game-server/src/modules/chat/ChatUtils';
import { stringCapitalize } from '../../../../helper/StringFormatter';
import { maxTotalLevel } from '../../../../utils/experienceFunctions';
import { leagueList } from '../../../../utils/leagueList';
import Tooltip from '../../../layout/UI/Tooltip';
import './ChatSkill.css';

const skills = {
	mining: { icon: '/images/mining/iron_pickaxe.svg', class: 'ore' },
	foraging: { icon: '/images/foraging/foraging_icon.png', class: 'log' },
	fishing: { icon: '/images/fishing/fishing_logo.png', class: 'fish' },
	smithing: { icon: '/images/smithing/smithing_icon.png', class: 'bar' },
	crafting: { icon: '/images/ui/crafting_icon.png', class: 'cookedFish' },
	cooking: { icon: '/images/cooking/cooking_icon.png', class: 'cooking-ingredient' },
	constitution: { icon: '/images/combat/constitution_icon.png', class: 'equipment' },
	attack: { icon: '/images/combat/attack_icon.png', class: 'equipment' },
	strength: { icon: '/images/combat/strength_icon.png', class: 'equipment' },
	defense: { icon: '/images/combat/defense_icon.png', class: 'equipment' },
	runecrafting: { icon: '/images/runecrafting/RuneCraftingIcon.png', class: 'rune' },
	enchanting: { icon: '/images/enchanting/enchanting_logo.png', class: 'enchanted-scroll' },
	farming: { icon: '/images/farming/farming_icon.png', class: 'seed' },
	magic: { icon: '/images/magic/magic_logo.png', class: 'equipment' },
	range: { icon: '/images/combat/range_icon.png', class: 'equipment' },
	total: { icon: '/images/total_level.png', class: 'equipment' },
};

export default function ChatSkill(props: {
	skill: Partial<IChatSkill> & { totalExp: number; league: number };
	hideName?: boolean;
}) {
	const skill = props.skill;
	if (!skill.name) return <div>Invalid Skill</div>;

	const leagueData = leagueList[skill.league];
	const maxLevel = maxTotalLevel(leagueData.rules.blacklistedSkills, leagueData.rules.expFormula);

	let imageSource = skills[skill.name].icon;
	if (skill.name === 'total' && skill.skillLvl && skill.skillLvl >= maxLevel) {
		imageSource = '/images/total_level_mastery_icon.png';
	}

	const nameCapitalized = stringCapitalize(skill.name);
	return (
		<div className={`chat-item ${skills[skill.name].class}`}>
			<img className='chat-skill-icon' src={imageSource} alt={skill.name} /> {!props.hideName && nameCapitalized}
			<Tooltip>
				<div className='skill-tooltip'>
					<div className='skill-tooltip-image-container'>
						<span className='skill-tooltip-image'>
							<img className='skill-tooltip-image' src={imageSource} alt={skill.name} />
						</span>
					</div>
					<div className='skill-tooltip-level-container'>
						<span className='skill-tooltip-level'>
							{skill.skillLvl && <span className='skill-tooltip-level-normal'>{skill.skillLvl}</span>}
							{skill.masteryLvl ? (
								<span className='skill-tooltip-level-mastery'> + {skill.masteryLvl}</span>
							) : (
								''
							)}
							{!skill.skillLvl && nameCapitalized}
						</span>
						<span className='skill-tooltip-level-exp'>
							{Math.floor(skill.totalExp).toLocaleString('en-us')}
						</span>
					</div>
				</div>
			</Tooltip>
		</div>
	);
}
