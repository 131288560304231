import React, { useEffect, useState } from 'react';
import { leagueList } from '../../../../utils/leagueList';

import { socket } from '../../../../services/socket.service';
import {
	Box,
	Flex,
	Grid,
	Select,
	Tab,
	Table,
	TableContainer,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer, IdlescapeInput, IdlescapeSelect } from '@idlescape/ui';
import TradeHistory from './TradeHistory';
import ChatHistory from './ChatHistory';
import Notes from './Notes';
import { IAccountNote } from '../../../../../../game-server/src/repositories/AccountNote.repository';
import Punishments from './Punishments';
import { usePlayerField } from '../../../../hooks/hooks';
import { TAccountPunishment, TPunishmentType } from '../../../../../../game-server/src/modules/moderator/Moderator';
import Inventories from './Inventories';
import MarketListings from './MarketListings';
import GeneralShopHistory from './GeneralShopHistory';
import DetailsGeneral from './DetailsGeneral';
import useIsMobile from '../../../../hooks/useIsMobile';
import { useRecoilState } from 'recoil';
import { DetailsAtom } from '../../../../atoms/detailsAtom';
import { GENERAL_CHAT_ID } from '../../../../utils/constantsCollection';
import { itemList } from '../../../../utils/itemList';
import { locations } from '../../../../utils/locationList';
import Subscriptions from './Subscriptions';

function Details() {
	const moderatorName = usePlayerField('username');
	const accountStatus = usePlayerField('accountStatus');
	if (accountStatus === 'normal') return null; // We don't care if it breaks
	const isChatMod = accountStatus === 'chat-mod';
	const [accountCreated, setAccountCreated] = useRecoilState(DetailsAtom.accountCreated);
	const [muteHistory, setMuteHistory] = useRecoilState(DetailsAtom.muteHistory);
	const [shadowMuteHistory, setShadowMuteHistory] = useRecoilState(DetailsAtom.shadowMuteHistory);
	const [tradebanHistory, setTradebanHistory] = useRecoilState(DetailsAtom.tradebanHistory);
	const [banHistory, setBanHistory] = useRecoilState(DetailsAtom.banHistory);
	const [usernameHistory, setUsernameHistory] = useRecoilState(DetailsAtom.usernameHistory);
	const [notes, setNotes] = useRecoilState(DetailsAtom.notes);
	const [characterData, setCharacterData] = useRecoilState(DetailsAtom.characterData);
	const [currentCharacter, setCurrentCharacter] = useRecoilState(DetailsAtom.currentCharacter);
	const [accountData, setAccountData] = useRecoilState(DetailsAtom.accountData);
	const [chatChannels, setChatChannels] = useRecoilState(DetailsAtom.chatChannels);
	const [actionQueues, setActionQueues] = useRecoilState(DetailsAtom.actionQueues);
	const [subscriptions, setSubscriptions] = useRecoilState(DetailsAtom.subscriptions);
	const [subsGiftedBy, setSubsGiftedBy] = useRecoilState(DetailsAtom.subsGiftedBy);

	const [chatDetailsCommand, setChatDetailsCommand] = useRecoilState(DetailsAtom.command);
	const categories = [
		'General',
		'Mutes',
		'Chat History',
		'Notes',
		'Shadow Mutes',
		'Bans',
		'Tradebans',
		'Name History',
		'Trade History',
		'General Shop History',
		'Market Listings',
		'Inventories',
		'Subscriptions',
	] as const;
	const chatModCategories = ['Mutes', 'Chat History', 'Notes'] as const;
	const selectedCharacter = characterData?.find((el) => el.id === currentCharacter);
	const currentAction = actionQueues[currentCharacter];

	const [searchName, setSearchName] = useState('');
	const [searchType, setSearchType] = useState('');

	const isMobile = useIsMobile();

	let actionString = '';
	if (currentAction) {
		const { location, actionType } = currentAction;
		actionString = actionType.replace('Action-', '');
		const itemID =
			currentAction.options?.runecraftingId ??
			currentAction.options?.scrollcraftingId ??
			currentAction.options?.smithing?.barId;
		if (itemID) {
			actionString += ': ' + itemList[itemID].name;
		} else {
			actionString += ': ' + locations[location].name;
		}
		actionString += `, Leader: ${currentAction.leaderID}`;
		if (currentAction.playerIDs.length > 1) {
			actionString += `, Members: ${currentAction.playerIDs
				.filter((memberId) => memberId !== currentAction.leaderID)
				.join(', ')}`;
		}
	}

	useEffect(() => {
		socket.on('moderator:details:view', (data) => {
			setAccountCreated(data.accountCreated);
			setMuteHistory(data.muteHistory);
			setTradebanHistory(data.tradebanHistory);
			setBanHistory(data.banHistory);
			setShadowMuteHistory(data.shadowMuteHistory);
			setUsernameHistory(data.usernameHistory);
			setNotes(data.notes);
			setCharacterData(data.characterData);
			setCurrentCharacter(data.currentCharacter);
			setAccountData(data.accountData);
			setChatChannels(data.chatChannels);
			setSubscriptions(data.subscriptions ?? []);
			setSubsGiftedBy(data.subsGiftedBy ?? []);
			setActionQueues(data.actionQueues);
		});
		socket.on('moderator:note:add', (note: IAccountNote) => {
			addNote(note);
		});
		socket.on('moderator:punishment:edit', (data) => {
			editPunishment(data.type, data.punishment);
		});

		return () => {
			socket.off('moderator:details:view');
			socket.off('moderator:note:add');
			socket.off('moderator:punishment:edit');
		};
	}, []);

	useEffect(() => {
		if (chatDetailsCommand) {
			socket.emit('chat:command:send', chatDetailsCommand);
			setChatDetailsCommand(undefined);
		}
	}, [chatDetailsCommand]);

	function editPunishment(type: TPunishmentType, punishment: TAccountPunishment) {
		const editCallback = (prev: TAccountPunishment[] | undefined) => {
			return prev?.map((el) => {
				if (el.id === punishment.id) {
					return punishment;
				}
				return el;
			});
		};
		punishment.issuedBy = moderatorName;
		switch (type) {
			case 'mute':
				setMuteHistory(editCallback);
				break;
			case 'ban':
				setBanHistory(editCallback);
				break;
			case 'tradeban':
				setTradebanHistory(editCallback);
				break;
			case 'shadowmute':
				setShadowMuteHistory(editCallback);
				break;
		}
	}

	function addNote(note: IAccountNote) {
		setNotes((prev) => {
			return [note, ...(prev ?? [])];
		});
	}

	function editNote(note: IAccountNote) {
		if (!notes) {
			return;
		}
		setNotes((prev) => {
			return prev?.map((el) => {
				if (el.id === note.id) {
					return note;
				}
				return el;
			});
		});
	}

	function removeNote(note: IAccountNote) {
		if (!notes) {
			return;
		}
		setNotes((prev) => {
			return prev?.filter((el) => el.id !== note.id);
		});
	}

	function renderCharacterSelection() {
		if (!characterData) return null;
		if (isMobile) {
			return (
				<IdlescapeSelect
					options={characterData.reduce((acc, user) => {
						acc[String(user.id)] = { label: user.username, image: leagueList[user.league].icon };
						return acc;
					}, {} as Record<string, { label: string; image: string }>)}
					select={(userId) => setCurrentCharacter(Number(userId))}
					selected={String(selectedCharacter?.id)}
					placeholder='Select a character'
					margin='5px 0'
				/>
			);
		}
		return (
			<Grid gridTemplateColumns='repeat(auto-fill, minmax(200px, 1fr))' justifyContent='center'>
				{characterData.map((character) => {
					return (
						<IdlescapeContainer
							key={character.id}
							variant={currentCharacter === character.id ? 'platinum' : 'primary'}
							onClick={() => setCurrentCharacter(character.id)}
						>
							{character.username}
							<img
								className='header-league-icon'
								src={leagueList[character.league].icon}
								alt={leagueList[character.league].name}
							/>
						</IdlescapeContainer>
					);
				})}
			</Grid>
		);
	}

	function renderCategorySelection() {
		return (
			<TabList flexWrap='wrap'>
				{isChatMod
					? chatModCategories.map((category) => <Tab key={category}>{category}</Tab>)
					: categories.map((category) => <Tab key={category}>{category}</Tab>)}
			</TabList>
		);
	}

	function renderUsernameHistory() {
		if (!usernameHistory) return null;
		return (
			<TabPanel>
				<TableContainer>
					<Table size='small'>
						<Thead>
							<Tr>
								<Th>OLD USERNAME</Th>
								<Th>NEW USERNAME</Th>
								<Th>DATE CHANGED</Th>
							</Tr>
						</Thead>
						<Tbody>
							{usernameHistory.map((name) => (
								<Tr key={name.id}>
									<Td>{name.oldUsername}</Td>
									<Td>{name.newUsername}</Td>
									<Td>{new Date(name.date).toLocaleString()}</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</TabPanel>
		);
	}

	function renderSearchSelect() {
		return (
			<Select defaultValue='forceuser' onChange={(e) => setSearchType(e.target.value)} width='120px'>
				<option value='forceuser'>Char Name</option>
				<option value='userid'>Char ID</option>
				<option value='forceaccount'>Acc Name</option>
				<option value='accountid'>Acc ID</option>
			</Select>
		);
	}

	function sendDetailsCommand() {
		const command = `/details ${searchName} ${searchType}`;
		const commandToSend = {
			channelId: GENERAL_CHAT_ID,
			commandString: command,
		};
		socket.emit('chat:command:send', commandToSend);
	}

	return (
		<Box userSelect='text'>
			<div>
				<Flex gap={2}>
					<IdlescapeInput
						setValue={setSearchName}
						variant='simple'
						placeholder={isChatMod ? 'Player name' : ''}
					/>
					{!isChatMod && renderSearchSelect()}
					<IdlescapeButton onClick={sendDetailsCommand} variant='green'>
						Search
					</IdlescapeButton>
				</Flex>
				{`Char ID: ${selectedCharacter?.id} | Char Name: ${selectedCharacter?.username}${
					!isChatMod
						? ` | Acc ID: ${accountData?.id} | Acc Name: ${accountData?.username} | ${
								actionString || 'Idling'
						  }`
						: ''
				}`}
			</div>
			<div>
				<Tabs isLazy lazyBehavior={'keepMounted'}>
					{renderCharacterSelection()}
					{renderCategorySelection()}
					<TabPanels>
						{!isChatMod && (
							<DetailsGeneral
								accountCreated={accountCreated}
								mutes={muteHistory?.length ?? 0}
								bans={banHistory?.length ?? 0}
								shadowMutes={shadowMuteHistory?.length ?? 0}
								tradebans={tradebanHistory?.length ?? 0}
							/>
						)}
						<Punishments
							type='mute'
							punishments={muteHistory}
							editPunishment={editPunishment}
							accountName={accountData?.username}
						/>
						<TabPanel>
							{selectedCharacter ? (
								<ChatHistory
									playerId={selectedCharacter.id}
									chatChannels={chatChannels[selectedCharacter.id] ?? []}
									reverse={true}
								/>
							) : (
								'Please select a character above first'
							)}
						</TabPanel>
						<Notes
							notes={notes}
							accountId={accountData?.id}
							accountName={accountData?.username}
							editNote={editNote}
							removeNote={removeNote}
						/>
						<Punishments
							type='shadowmute'
							punishments={shadowMuteHistory}
							editPunishment={editPunishment}
							accountName={accountData?.username}
						/>
						<Punishments
							type='ban'
							punishments={banHistory}
							editPunishment={editPunishment}
							accountName={accountData?.username}
						/>
						<Punishments
							type='tradeban'
							punishments={tradebanHistory}
							editPunishment={editPunishment}
							accountName={accountData?.username}
						/>
						{renderUsernameHistory()}
						<TabPanel>
							{selectedCharacter ? (
								<TradeHistory playerId={selectedCharacter.id} />
							) : (
								'Please select a character above first'
							)}
						</TabPanel>
						<TabPanel>
							{selectedCharacter ? (
								<GeneralShopHistory playerId={selectedCharacter.id} />
							) : (
								'Please select a character above first'
							)}
						</TabPanel>
						<TabPanel>
							{selectedCharacter ? (
								<MarketListings playerId={selectedCharacter.id} key={selectedCharacter.id} />
							) : (
								'Please select a character above first'
							)}
						</TabPanel>
						<TabPanel paddingX='0'>
							{selectedCharacter ? (
								<Inventories playerId={selectedCharacter.id} username={selectedCharacter.username} />
							) : (
								'Please select a character above first'
							)}
						</TabPanel>
						<TabPanel paddingX='0'>
							<Subscriptions subscriptions={subscriptions} subsGiftedBy={subsGiftedBy} />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</div>
		</Box>
	);
}

export default Details;

export function details(username: string, extra?: string) {
	const command = '/details ' + username + (extra ? ' ' + extra : '');

	const commandToSend = {
		channelId: 1,
		commandString: command,
	};

	socket.emit('chat:command:send', commandToSend);
}
