import { useMediaQuery } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { shrineDonationAtom } from '../../atoms/shrineAtom';
import useIsMobile from '../../hooks/useIsMobile';
import useNavbarPinned from '../../hooks/useNavbarPinned';
import { socket } from '../../services/socket.service';
import { getShrineTime } from '../game/Shrine/Shrine';
import FullWidth from './levels/FullWidth';
import SmallWidth from './levels/SmallWidth';

export const Levels = () => {
	const { navbarPinned } = useNavbarPinned();
	const isMobile = useIsMobile();
	const [isSmallerThan1300] = useMediaQuery('(max-width: 1300px)', { ssr: false });

	//It's here because it's always open and socket is loaded by this point
	const setShrineDonation = useSetRecoilState(shrineDonationAtom);
	useEffect(() => {
		socket.on('shrine:personal:contribution', (data) => {
			setShrineDonation(data);
		});

		// Reset contribution if next shrine
		const nextShrine = getShrineTime();
		setTimeout(() => {
			setShrineDonation(0);
		}, nextShrine.getTime() - Date.now());

		socket.emit('shrine:personal:contribution');
		return () => {
			socket.off('shrine:personal:contribution');
		};
	}, []);

	function getSkillLevels() {
		if (isMobile || (navbarPinned && isSmallerThan1300)) return <SmallWidth />;
		return <FullWidth />;
	}

	return <div className='header-level-container'>{getSkillLevels()}</div>;
};
