import {
	Box,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { IChatChestData } from '../../../../../../game-server/src/modules/chat/ChatUtils';
import { IItem, TPartialItemWithItemID } from '../../../../../../game-server/src/modules/items/items.interface';
import useIsMobile from '../../../../hooks/useIsMobile';
import { itemList } from '../../../../utils/itemList';
import Tooltip from '../../../layout/UI/Tooltip';
import { Item } from '../../Inventory/Item';
import './ChatChest.css';

export default function ChatChest(props: { chest: IChatChestData }) {
	const chestData = props.chest;
	if (!chestData) return <div>Invalid Chest</div>;
	const amount = chestData.amount;
	const genericChest = itemList[chestData.chestID];
	const itemName = genericChest.name;
	const chestIcon = <img className='chat-chest-icon' src={genericChest.itemImage} alt={genericChest.name} />;
	const isMobile = useIsMobile();
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<div className={`chat-item gem ${props.chest.date ? 'chat-chest-combined' : ''}`} onClick={onOpen}>
			{chestIcon} Opened {itemName} x{amount}
			{isMobile ? (
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent className='sell-item-dialog'>
						<ModalCloseButton />
						<ModalBody>
							<ChatChestContent chest={props.chest} />
						</ModalBody>
					</ModalContent>
				</Modal>
			) : (
				<Tooltip>
					<ChatChestContent chest={props.chest} />
				</Tooltip>
			)}
		</div>
	);
}

export function ChatChestContent({ chest }: { chest: IChatChestData }) {
	const itemName = itemList[chest.chestID].name;

	return (
		<div className='chest-tooltip'>
			<div className='chest-tooltip-title-container'>
				<span>
					Opened {itemName} x{chest.amount}
				</span>
			</div>
			<Box>
				<div className='chest-tooltip-loot-container'>
					{chest.loot &&
						chest.loot.map((item, index) => {
							const { id: itemID, amount: stackSize } = item;
							const fakeItem: TPartialItemWithItemID<IItem> = {
								itemID,
								stackSize,
							};
							return <Item key={index} item={fakeItem} variant='small' />;
						})}
				</div>
				{chest.date && <Text textAlign='center'>since {new Date(chest.date).toLocaleDateString()}</Text>}
			</Box>
		</div>
	);
}
