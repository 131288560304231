import axios from 'axios';
import setAuthToken from '../helper/setAuthToken';
import { SET_CURRENT_USER } from './types';
import { Dispatch } from 'redux';
import { ICharacterJWTData } from '../../../game-server/src/modules/session/SessionStarter';
import { gameUrls, openGameUrl } from '../GameUrls';
import { clientType } from 'idlescape-globals';

// Set logged in user
export const setCurrentUser = (decoded: Partial<ICharacterJWTData>) => {
	return {
		type: SET_CURRENT_USER,
		payload: decoded,
	};
};

/**
 * Logout user and redirects to the login page
 */
export const logoutUser = async (dispatch: Dispatch) => {
	try {
		// Wait for the logout API call to complete
		await axios.post('/api/auth/logout', undefined, { withCredentials: true });
	} catch (error) {
		console.error('Logout failed', error);
		// Optionally, handle the error (e.g., display a message to the user)
	}

	// Clear cookies, res.clearCookies from auth server + { withCredentials: true } should delete it
	// document.cookie.split(';').forEach((cookie) => {
	// 	const eqPos = cookie.indexOf('=');
	// 	const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
	// 	document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
	// });

	// Clear local storage and session storage
	// localStorage.clear(); //Interferes with user scripts
	sessionStorage.clear();

	// Remove auth header for future requests
	setAuthToken(false);
	// Set current user to empty object {} which will set isAuthenticated to false
	dispatch(setCurrentUser({}));

	openGameUrl('login');
};
