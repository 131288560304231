import {
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import { BsEye } from 'react-icons/bs';
import { HiEyeOff } from 'react-icons/hi';
import { useLocalStorage } from 'usehooks-ts';
import { getMaxQueueCount } from '../../../helper/helperFunctions';
import { usePlayerField } from '../../../hooks/hooks';
import { questsIds } from '../../../utils/lookup-dictionaries/lookupQuestList';
import { questList } from '../../../utils/questList';
import QuestEntry from './QuestEntry';
import { checkRequirements } from './QuestFunctions';
import { IQuestData, TQuestCategory } from '../../../../../game-server/src/modules/quest/Quest.interface';
import React from 'react';

interface QuestListProps {
	league: number;
	isAchievements: boolean;
}

const difficultyOrder = { Tutorial: 1, Easy: 2, Medium: 3, Hard: 4, Elite: 5, Master: 6 };

export interface IQuestFilters {
	difficulties: string[];
	completed: boolean;
	locked: boolean;
	inProgress: boolean;
	pinSelected: boolean;
}

export default function QuestList({ league, isAchievements }: QuestListProps) {
	const [filters, setFilters] = useLocalStorage<IQuestFilters>('questFilters', {
		difficulties: [''],
		completed: true,
		locked: false,
		inProgress: false,
		pinSelected: true,
	});
	const questsCompleted = usePlayerField('questsCompleted');
	const questProgress = usePlayerField('questProgress');
	const skills = usePlayerField('skills');
	const questIdsCompleted = questsCompleted.map((quest) => quest.id);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const subscription = usePlayerField('subscription');
	const bonusActionQueueCount = usePlayerField('bonusActionQueueCount');
	const hasQueue = getMaxQueueCount(subscription.active, bonusActionQueueCount) > 1;

	const difficultyList = ['Easy', 'Medium', 'Hard', 'Elite', 'Master'];
	const availableCategories: TQuestCategory[] = ['Adventure'];

	// Building the categories, Adventure is the catch all
	for (const quest of Object.values(questList)) {
		if (quest.category && shouldShowQuest(quest)) {
			if (!availableCategories.includes(quest.category)) {
				availableCategories.push(quest.category);
			}
		}
	}

	const sortedQuestList = Object.values(questList).sort((a, b) => {
		// First all quests with a defined order and then by difficulty
		if (a.order !== undefined || b.order !== undefined) {
			return (a.order ?? 999) - (b.order ?? 999);
		}
		const diffSort = difficultyOrder[a.difficulty] - difficultyOrder[b.difficulty];
		const catSort =
			availableCategories.indexOf(a.category ?? 'Adventure') -
			availableCategories.indexOf(b.category ?? 'Adventure');
		if (catSort !== 0) return catSort;
		return diffSort;
	});

	function questObjsWithCategoryTitle() {
		let lastCategory = '';
		const questObjsWithCategoryTitle = sortedQuestList.map((quest, index) => {
			if (!shouldShowQuest(quest)) return null;
			const questCategory = quest.category ?? 'Adventure';
			if (questCategory !== lastCategory) {
				lastCategory = questCategory;
				return (
					<React.Fragment key={index}>
						<div className='quest-category-title'>{lastCategory}</div>
						<QuestEntry quest={quest} questProgress={questProgress} />
					</React.Fragment>
				);
			} else {
				return <QuestEntry key={index} quest={quest} questProgress={questProgress} />;
			}
		});
		return questObjsWithCategoryTitle;
	}

	function shouldShowQuest(quest: IQuestData) {
		if (!quest) return false;
		if (!hasQueue && quest.id === questsIds.the_action_queue) return false;
		const locked = !checkRequirements(quest, skills, questIdsCompleted);
		const shouldShowRestrictions = !(
			quest?.questRestrictions?.inactive ||
			quest?.questRestrictions?.blacklistedLeagues?.includes(league) ||
			(quest?.questRestrictions?.questStartDate &&
				quest?.questRestrictions?.questEndDate &&
				(new Date() < new Date(quest.questRestrictions.questStartDate) ||
					new Date() > new Date(quest.questRestrictions.questEndDate)))
		);
		const shouldShowFilter =
			(!filters.completed || !questIdsCompleted.includes(quest.id)) &&
			(!filters.inProgress || !questProgress.find((questProgress) => questProgress.id === quest.id)) &&
			!filters.difficulties.includes(quest.difficulty) &&
			(!filters.locked || !locked);
		const shouldShowQuest = isAchievements ? quest.achievement : !quest.achievement;
		return shouldShowQuest && shouldShowFilter && shouldShowRestrictions;
	}

	function renderSettings() {
		return (
			<div className='quest-menu'>
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Quest Settings</ModalHeader>
						<ModalCloseButton />
						<ModalBody className='quest-settings'>
							<div onClick={() => togglePinSelected()} className='quest-type'>
								Pin Quest <span>{filters.pinSelected ? 'Auto' : 'Manual'}</span>
							</div>
							<div onClick={() => toggleCompletedFilter()} className='quest-type'>
								Completed {filters.completed ? <HiEyeOff /> : <BsEye />}
							</div>
							<div onClick={() => toggleLockedFilter()} className='quest-type'>
								Locked {filters.locked ? <HiEyeOff /> : <BsEye />}
							</div>
							<div onClick={() => toggleInProgressFilter()} className='quest-type'>
								In Progress {filters.inProgress ? <HiEyeOff /> : <BsEye />}
							</div>
							{difficultyList.map((difficulty) => {
								return (
									<div
										onClick={() => toggleDifficultyFilter(difficulty)}
										className='quest-type'
										key={`difficulty-filter-${difficulty}`}
									>
										{difficulty}{' '}
										{filters.difficulties.includes(difficulty) ? <HiEyeOff /> : <BsEye />}
									</div>
								);
							})}
						</ModalBody>
						<ModalFooter>
							<IdlescapeButton onClick={onClose} variant={'red'}>
								Close
							</IdlescapeButton>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</div>
		);
	}

	function toggleDifficultyFilter(difficulty: string) {
		const newDifficultyFilter = [...filters.difficulties];
		if (newDifficultyFilter.includes(difficulty)) {
			newDifficultyFilter.splice(newDifficultyFilter.indexOf(difficulty), 1);
		} else {
			newDifficultyFilter.push(difficulty);
		}
		setFilters((filters) => {
			filters.difficulties = newDifficultyFilter;
			return filters;
		});
	}

	function toggleCompletedFilter() {
		setFilters((filters) => {
			filters.completed = !filters.completed;
			return filters;
		});
	}

	function toggleLockedFilter() {
		setFilters((filters) => {
			filters.locked = !filters.locked;
			return filters;
		});
	}

	function toggleInProgressFilter() {
		setFilters((filters) => {
			filters.inProgress = !filters.inProgress;
			return filters;
		});
	}

	function togglePinSelected() {
		setFilters((filters) => {
			filters.pinSelected = !filters.pinSelected;
			return filters;
		});
	}

	return (
		<>
			{questObjsWithCategoryTitle()}
			<Image
				position='absolute'
				top='10px'
				right='10px'
				height='25px'
				onClick={onOpen}
				src='/images/ui/options_gear.png'
				cursor='pointer'
				filter='brightness(1.8)'
			/>
			{renderSettings()}
		</>
	);
}
