import { AbsoluteCenter } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { IItem, TPartialItemWithItemID } from '../../../../../../game-server/src/modules/items/items.interface';
import { TEquipmentSlots } from '../../../../../../game-server/src/modules/player/PlayerTypes';
import { loadoutEquipmentAtom } from '../../../../atoms/loadoutAtom';
import { stringCapitalize } from '../../../../helper/StringFormatter';
import { usePlayerField } from '../../../../hooks/hooks';
import { addNotification, NotificationType } from '../../../../services/NotificationManager';
import { enchantmentsList } from '../../../../utils/enchantmentList';
import { checkEquipmentRequirements, doItemsMatch } from '../../../../utils/itemFunctions';
import { itemList } from '../../../../utils/itemList';
import { Item } from '../../Inventory/Item';
import '../../Inventory/Item.css';
import SelectionTooltipBox from '../../Tooltips/Types/SelectionTooltipBox';

const RED = 'rgba(255, 0, 0, 0.5)';
const GREEN = 'rgba(0, 255, 0, 0.5)';
const BLUE = 'rgba(0, 0, 255, 0.5)';

export default function LoadoutSelectEquipment(props: { slot: TEquipmentSlots }) {
	const equipment = usePlayerField('equipment');
	const stockpile = usePlayerField('stockpile');
	const vault = usePlayerField('vault');
	const skills = usePlayerField('skills');
	const [loadoutEquipment, setLoadoutEquipment] = useRecoilState(loadoutEquipmentAtom);
	const equippedItem = loadoutEquipment.equipment[props.slot]?.itemID
		? loadoutEquipment.equipment[props.slot]
		: undefined;

	const possibleEquipment = stockpile.concat(vault).filter((item) => {
		const info = itemList[item.itemID];

		// Cannot equip cosmetics in equipment slots
		if (info.equipmentStats?.cosmeticSlot) {
			return false;
		}
		return (
			info.equipmentStats &&
			info.equipmentStats.slot &&
			(!info.equipmentStats.isTransmog || !info.equipmentStats.fullTransmog) &&
			info.equipmentStats?.slot == props.slot
		);
	});
	const selectedEquipment = equipment[props.slot];
	if (selectedEquipment) {
		possibleEquipment.push(selectedEquipment);
	}

	// Unequip if the item is moved
	useEffect(() => {
		if (!equippedItem?.id) {
			return;
		}
		let owned = false;
		let similarId = 0;
		for (const item of possibleEquipment) {
			if (item.id === equippedItem.id) {
				owned = true;
				break;
			}
			if (doItemsMatch(item, equippedItem)) {
				similarId = item.id;
			}
		}
		if (!owned) {
			// If a similar item exists, replace it instead of removing
			if (similarId) {
				const newEquipment = structuredClone(loadoutEquipment.equipment);
				const newSlot = newEquipment[props.slot];
				if (newSlot?.id) newSlot.id = similarId;
				setLoadoutEquipment({ changed: true, equipment: newEquipment });
				return;
			}
			unequip();
		}
	}, [stockpile, vault, equipment]);

	let slotString = props.slot.replace('arrows', 'Quiver / Pendant') ?? 'Unknown Slot';
	slotString = stringCapitalize(slotString);

	function equip(item: IItem) {
		const itemData = itemList[item.itemID];
		const requirementResult = checkEquipmentRequirements(item, itemData, skills, enchantmentsList);
		if (requirementResult !== 'success') {
			addNotification('Equipment', requirementResult, NotificationType.Success);
			return false;
		}
		const newEquipment = structuredClone(loadoutEquipment.equipment);
		newEquipment[props.slot] = {
			id: item.id,
			itemID: item.itemID,
			augmentations: item.augmentations,
			enchantmentStrength: item.enchantmentStrength,
			enchantmentID: item.enchantmentID,
			affixes: item.affixes,
		};
		if (props.slot === 'weapon' && newEquipment.shield) {
			const itemData = itemList[item.itemID];
			if (!itemData.equipmentStats?.oneHanded) {
				newEquipment.shield = undefined;
			}
		} else if (props.slot === 'shield' && newEquipment.weapon) {
			const itemData = itemList[newEquipment.weapon.itemID];
			if (!itemData.equipmentStats?.oneHanded) {
				newEquipment.weapon = undefined;
			}
		}
		setLoadoutEquipment({ changed: true, equipment: newEquipment });
		return true;
	}

	function unequip() {
		const newEquipment = structuredClone(loadoutEquipment.equipment);
		// Leave the key instead of deleting to remove from loadout
		newEquipment[props.slot] = undefined;
		setLoadoutEquipment({ changed: true, equipment: newEquipment });
	}

	function getEquipmentBorder(equippedItem: TPartialItemWithItemID<IItem> | undefined) {
		// Check if it's equipped already, then if it's in the stockpile, then if it's in vault, and if not in vault then it's not found
		const currentlyEquippedItem = Object.values(equipment).find((e) => doItemsMatch(e, equippedItem));
		if (currentlyEquippedItem) {
			return BLUE;
		}
		const stockpileItem = stockpile.find((e) => doItemsMatch(e, equippedItem));
		if (!stockpileItem) {
			// Find it in the vault
			const vaultItem = vault.find((e) => doItemsMatch(e, equippedItem));
			if (!vaultItem) {
				// Couldn't be found
				return RED;
			}
		}
		return GREEN;
	}

	return (
		<SelectionTooltipBox
			items={possibleEquipment}
			clickItem={equip}
			clickEmpty={unequip}
			id={'gear-' + props.slot}
			className='combat-gear-item'
			currentItem={equippedItem}
			title={'Loadout ' + slotString}
		>
			{equippedItem && (
				<Item
					item={equippedItem}
					width='48px'
					height='48px'
					margin='1px'
					mobileTooltip={false}
					borderWidth='2px'
					borderStyle='solid'
					borderColor={getEquipmentBorder(equippedItem)}
				/>
			)}
			{possibleEquipment.length > 0 && !equippedItem && (
				<AbsoluteCenter
					textShadow='text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
					fontSize='24px'
					fontWeight='bold'
				>
					{possibleEquipment.length}
				</AbsoluteCenter>
			)}
		</SelectionTooltipBox>
	);
}
