import { Box, Flex, Grid, Img, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { BiMenu } from 'react-icons/bi';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TActiveTab } from '../../../../../game-server/src/Atypes/Skills';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { shareSkillSelector } from '../../../atoms/chat/chatHistoryAtom';
import { usePlayerField } from '../../../hooks/hooks';
import { leagueList } from '../../../utils/leagueList';
import { tabs } from '../../../helper/NavigationTabsList';
import '../Levels.css';
import PinnedQuest from '../StatusBar/PinnedQuest';
import { StatusBar } from '../StatusBar/StatusBar';
import Tooltip from '../UI/Tooltip';
import SkillLevel from './SkillLevel';
import StatusAction from '../StatusBar/StatusAction';
import { shrineDonationAtom } from '../../../atoms/shrineAtom';

export default function FullWidth() {
	const skills = usePlayerField('skills');
	const totalLevel = skills.total.level;
	const totalExp = skills.total.experience;
	const masteryLevel = skills.total.masteryLevel;
	const masteryExp = skills.total.masteryExperience;
	const shareSkill = useSetRecoilState(shareSkillSelector);
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const leagueId = usePlayerField('league');
	const league = leagueList[leagueId];
	const hideGathering = league.name === 'No Gather Ironman';

	const shrineDonation = useRecoilValue(shrineDonationAtom);
	const subscription = usePlayerField('subscription');
	const settings = usePlayerField('settings');
	const showShrineNotification =
		subscription.active && settings.miscellaneous.showUnfundedShrine && shrineDonation === 0;

	function linkTotal(event: React.MouseEvent, isMastery = false) {
		if (event.ctrlKey || event.metaKey) {
			shareSkill('total');
		} else if (isMastery) {
			setActiveTab('talents');
		}
	}

	function shrineNotification() {
		if (!showShrineNotification) return null;
		return (
			<Box
				position='absolute'
				top='0'
				right='0'
				width='15px'
				height='15px'
				backgroundColor='#ff0000'
				borderRadius='50%'
			/>
		);
	}

	function otherTab(tabName: TActiveTab) {
		const tab = tabs[tabName];
		return (
			<Box
				position='relative'
				cursor='pointer'
				onClick={() => {
					setActiveTab(tabName);
				}}
			>
				<Img width='clamp(40px, 4vw, 50px)' marginTop='auto' src={tab.icon} objectFit='contain' alt={tabName} />
				<Tooltip>{tab.name}</Tooltip>
				{tabName === 'shrine' && shrineNotification()}
			</Box>
		);
	}

	function handleOnClick(e: React.MouseEvent<HTMLElement>) {
		if (isOpen) {
			onClose();
		} else {
			onOpen();
		}
		e.stopPropagation();
	}

	return (
		<div className='navbar3'>
			<Box
				className='exp-tooltip total-level'
				position='fixed'
				top='0'
				left='40vw'
				data-tip
				data-for='total-level-tooltipHeader'
				onClick={(e) => linkTotal(e)}
			>
				<img src='/images/total_level.png' alt='Total level icon' className='icon30' />
				<Box as='span' fontSize='25px' verticalAlign='middle'>
					{totalLevel}
				</Box>
				<Tooltip>Experience: {totalExp.toLocaleString('en-us')}</Tooltip>
			</Box>
			<Box
				className='exp-tooltip total-level'
				cursor='pointer'
				id='totalCombatLevelHeader'
				position='fixed'
				top='0'
				right='40vw'
				data-tip
				data-for='combat-level-tooltipHeader'
				onClick={(e) => linkTotal(e, true)}
			>
				<img src='/images/total_level_mastery_icon.png' alt='Total Mastery Level' className='icon30' />
				<Box as='span' fontSize='25px' verticalAlign='middle'>
					{masteryLevel}
				</Box>
				<Tooltip>Mastery Experience: {masteryExp.toLocaleString('en-us')}</Tooltip>
			</Box>
			<Box className='skill-levels-total-combat' width='20vw'>
				<StatusAction />
				<StatusBar />
			</Box>
			<Grid
				gridTemplateColumns={
					hideGathering
						? 'repeat(6, 1.5fr) 20vw repeat(6, 1fr) 2.4fr 0.6fr'
						: 'repeat(9, 1fr) 20vw repeat(6, 1fr) 2.4fr 0.6fr'
				}
				justifyItems='center'
				alignItems='center'
				className='skill-levels-grid-1'
			>
				{!hideGathering && (
					<>
						<SkillLevel type='circle' skill='mining' />
						<SkillLevel type='circle' skill='foraging' />
						<SkillLevel type='circle' skill='fishing' />
					</>
				)}
				<SkillLevel type='circle' skill='farming' />
				<SkillLevel type='circle' skill='enchanting' />
				<SkillLevel type='circle' skill='runecrafting' />
				<SkillLevel type='circle' skill='smithing' />
				<SkillLevel type='circle' skill='cooking' />
				<SkillLevel type='circle' skill='crafting' />
				<div />
				{/* next skill goes here */}
				<SkillLevel type='circle' skill='constitution' />
				<SkillLevel type='circle' skill='attack' />
				<SkillLevel type='circle' skill='defense' />
				<SkillLevel type='circle' skill='strength' />
				<SkillLevel type='circle' skill='magic' />
				<SkillLevel type='circle' skill='range' />
				<PinnedQuest />
				<Box position='relative' onClick={handleOnClick} cursor='pointer' fontSize='30px'>
					<BiMenu />
					{isOpen && (
						<Tooltip isOpen={true} onClickOutside={onClose} className='idlescape-tooltip'>
							<Flex flexWrap='wrap' gap='10px' flexDirection='column'>
								{otherTab('quests')}
								{otherTab('achievements')}
								{otherTab('talents')}
								{otherTab('shrine')}
								{otherTab('general-shop')}
								{otherTab('event-shop')}
							</Flex>
						</Tooltip>
					)}
					{shrineNotification()}
				</Box>
			</Grid>
		</div>
	);
}
