import { Flex, Box, Text, Image, Heading } from '@chakra-ui/react';
import { IdlescapeFrameBox, IdlescapeContainer } from '@idlescape/ui';
import {
	IQuestCompleted,
	IQuestProgress,
	TQuestCategory,
	TQuestDifficulty,
} from '../../../../../game-server/src/modules/quest/Quest.interface';
import { questPointIcon } from '../../../helper/itemHelperFunctions';
import { questList } from '../../../utils/questList';
import useIsMobile from '../../../hooks/useIsMobile';
import { usePlayerField } from '../../../hooks/hooks';
import { TSkillName, TSkills } from '../../../../../game-server/src/Atypes/Skills';
import { getMaxQueueCount } from '../../../helper/helperFunctions';
import { questsIds } from '../../../utils/lookup-dictionaries/lookupQuestList';
import { sum } from 'lodash';
import React from 'react';

interface QuestSummaryProps {
	isAchievements: boolean;
	questProgress: IQuestProgress[];
	questsCompleted: IQuestCompleted[];
	category?: TQuestCategory;
}

export default function QuestSummary({ isAchievements, questProgress, questsCompleted, category }: QuestSummaryProps) {
	const skills = usePlayerField('skills');
	const questIdsCompleted = questsCompleted.map((quest) => quest.id);
	const subscription = usePlayerField('subscription');
	const bonusActionQueueCount = usePlayerField('bonusActionQueueCount');

	const isMobile = useIsMobile();

	const totalQuests = Object.values(questList).filter((quest) => {
		if (isAchievements) {
			return quest.achievement && quest.category === category;
		} else {
			return quest.achievement === undefined;
		}
	}).length;

	// Filter questIds by whether they are achievements or not
	const filteredCompleted = questIdsCompleted.filter((questId) => {
		const quest = questList[questId];
		if (isAchievements) {
			return quest?.achievement && quest?.category === category;
		} else {
			return quest?.achievement === undefined;
		}
	});
	// Same for questProgress
	const filteredProgress = questProgress.filter((questId) => {
		const quest = questList[questId.id];
		if (isAchievements) {
			return quest?.achievement && quest?.category === category;
		} else {
			return quest?.achievement === undefined;
		}
	});

	let questPoints = 0;
	Object.values(questList).forEach((quest) => {
		if (questIdsCompleted.includes(quest.id)) {
			questPoints += quest?.questRewards?.questPoints ?? 0;
		}
	});

	if (isAchievements) {
		return (
			<Heading size='l' textShadow='-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black' textAlign='center'>
				{category ?? 'Adventure'} ({filteredCompleted.length} / {totalQuests})
			</Heading>
		);
	}

	function getSumCompletedByDifficulty(difficulty: TQuestDifficulty) {
		let sum = 0;
		Object.values(questList).forEach((quest) => {
			if (questIdsCompleted.includes(quest.id)) {
				if (quest.difficulty === difficulty) {
					sum += 1;
				}
			}
		});
		return sum;
	}

	const targetName = isAchievements ? 'Achievement' : 'Quest';

	const totalEasy = getSumCompletedByDifficulty('Easy');
	const totalMedium = getSumCompletedByDifficulty('Medium');
	const totalHard = getSumCompletedByDifficulty('Hard');
	const totalElite = getSumCompletedByDifficulty('Elite');
	const totalMaster = getSumCompletedByDifficulty('Master');

	let alert: React.ReactNode = null;
	if (!isAchievements) {
		const unacceptedCount = unacceptedQuestCount(
			questIdsCompleted,
			questProgress,
			questPoints,
			skills,
			subscription.active,
			bonusActionQueueCount
		);
		if (unacceptedCount > 0) {
			alert = (
				<h4 style={{ color: 'red', textShadow: '2px 2px 4px black' }}>{unacceptedCount} unaccepted quests</h4>
			);
		}
		// Get the count of quests that are complete but not turned in
		const completedButNotTurnedIn = completedButNotTurnedInQuests(questProgress, questIdsCompleted);
		if (completedButNotTurnedIn > 0) {
			alert = (
				<>
					{alert !== null && alert}
					<h4 style={{ color: 'green', textShadow: '2px 2px 4px black' }}>
						{completedButNotTurnedIn} completed quests ready for turn in
					</h4>
				</>
			);
		}
	}

	return (
		<Box textAlign='center'>
			<IdlescapeContainer>
				<Flex alignItems='center' height='150px'>
					<IdlescapeFrameBox padding='10px' margin='5px' _hover={{}}>
						{questPointIcon(100)}
					</IdlescapeFrameBox>
					<Text fontSize={isMobile ? '35px' : '50px'}>{targetName} Summary</Text>
				</Flex>
			</IdlescapeContainer>
			<IdlescapeContainer fontSize='28px'>
				{alert}
				<p>
					Total {targetName} Points: {questPoints}
					<br />
					Completed: {filteredCompleted.length}
					<br />
					In Progress: {filteredProgress.length}
				</p>
				<p>
					Easy Completed: {totalEasy}
					<br />
					Medium Completed: {totalMedium}
					<br />
					Hard Completed: {totalHard}
					<br />
					Elite Completed: {totalElite}
					<br />
					Master Completed: {totalMaster}
				</p>
			</IdlescapeContainer>
		</Box>
	);
}

export function completedButNotTurnedInQuests(questProgress: IQuestProgress[], questIdsCompleted: number[]) {
	return sum(
		Object.values(questList).map((quest) => {
			const progress = questProgress.find((q) => q.id === quest.id);
			if (!progress) {
				return 0;
			}
			const onLastStep = quest.questSteps.length === progress.stepID;
			const progressExceedsOrMatchesTarget =
				quest.questSteps[quest.questSteps.length - 1].targetAmount <= progress.current;
			if (!questIdsCompleted.includes(quest.id) && onLastStep && progressExceedsOrMatchesTarget) {
				return 1;
			}
			return 0;
		})
	);
}

export function unacceptedQuestCount(
	questIdsCompleted: number[],
	questProgress: IQuestProgress[],
	questPoints: number,
	skills: TSkills,
	isSubscribed: boolean,
	bonusActionQueueCount: number
) {
	return sum(
		Object.values(questList).map((quest) => {
			if (questIdsCompleted.includes(quest.id) || questProgress.find((q) => q.id === quest.id)) {
				return 0;
			}
			// Check if they have the requirements to start the quest
			const requirements = quest.questRequirements;
			if (requirements) {
				// Quest Points Check
				if (requirements.questPoints) {
					if (questPoints < requirements.questPoints) {
						return 0;
					}
				}
				// Required Quests Check
				if (requirements.requiredQuests) {
					return 0;
				}
				// Required Skills Check
				if (requirements.skillRequirements) {
					for (const [skillName, level] of Object.entries(requirements.skillRequirements)) {
						const skill = skills[skillName as TSkillName];
						if (!skill || skill.level < level) {
							return 0;
						}
					}
				}
			}
			if (
				quest.id === questsIds.the_action_queue &&
				getMaxQueueCount(isSubscribed, bonusActionQueueCount ?? 0) <= 1
			)
				return 0;

			return 1;
		})
	);
}
