import { Box, Heading, Text } from '@chakra-ui/react';
import { TPaymentMessageType } from '../../../../game-server/src/modules/discord-manager/discordManager';

const HEADERS: Record<TPaymentMessageType, string> = {
	platinum: 'Thank you for your purchase!',
	bond: 'Thank you for your purchase!',
	'idlescape-plus': 'Thank you for subscribing!',
};
const TEXTS: Record<TPaymentMessageType, string> = {
	platinum: 'Platinum has been added to your character. You can now close this page.',
	bond: 'Idlescape Bonds have been added to your character. You can now close this page.',
	'idlescape-plus': 'You can now return to the game and enjoy your new perks!',
};

export const PaymentSuccessful = ({ type }: { type: TPaymentMessageType }) => {
	const heading = HEADERS[type];
	const text = TEXTS[type];

	return (
		<Box
			className='email-registered-body page-header'
			backgroundImage='url(/images/landing_page.png)'
			backgroundPosition='center'
			backgroundSize='cover'
		>
			<div className='motto text-center'>
				<img className='img-fluid logo mb-5' alt='Idlescape Logo' src={'/images/landing/logo_2.0.png'} />
				<div className='email-registered-box'>
					<Heading>{heading}</Heading>
					<br></br>
					<Text>{text}</Text>
				</div>
			</div>
		</Box>
	);
};
