import { AbsoluteCenter, Box, Flex, Image, Text, VStack } from '@chakra-ui/react';
import Tooltip from '../../layout/UI/Tooltip';
import { ICombatOverTimeEvents } from '../../../../../game-server/src/modules/combat/Combat.interface';
import { useGroupField } from '../../../hooks/hooks';
import { useRecoilValue } from 'recoil';
import { monsterAtom } from '../../../atoms/monsterAtom';
import { abilities } from '../../../utils/abilityList';

export default function CombatOvertime({ events }: { events: ICombatOverTimeEvents[] }) {
	const groupMemberData = useGroupField('groupMemberData');
	const monsters = useRecoilValue(monsterAtom);

	return (
		<Flex gap='2px' flexDirection='row-reverse'>
			{events?.map((event, index) => {
				// If the sourceName is 'Passive Heal', skip this event, unless we want it? It's kinda messy
				if (event.sourceName === 'Passive Heal') return;
				const backgroundColor = event.overTimeValue < 0 ? '#962828' : '#238318';
				const borderColor = event.overTimeValue < 0 ? '#ff0000' : '#00ff00';
				const border = `2px solid ${borderColor}`;

				// I feel as if this could be better lol
				let icon =
					event.overTimeValue > 0
						? '/images/combat/splashes/Heal_splash.png'
						: '/images/combat/stats-icon-enemy.png';
				const ability = Object.values(abilities).find((ability) => ability.abilityName === event.sourceName);
				if (ability && ability.abilityImage) {
					icon = ability.abilityImage;
				}

				const sourceCharacter =
					groupMemberData?.find((player) => player.id === event.sourceID)?.username ??
					monsters?.find((monster) => monster.id === event.sourceID)?.monsterName ??
					'Unknown';
				return (
					<Box
						key={index}
						borderRadius='5px'
						cursor='pointer'
						position='relative'
						padding='2px'
						width='40px'
						height='40px'
						border={border}
						backgroundColor={backgroundColor}
					>
						<Image src={icon} height='100%' width='100%' />
						<AbsoluteCenter
							fontSize='16px'
							fontWeight='bold'
							bottom='20%'
							textShadow='-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
						>
							{event.overTimeTicksLeft?.toLocaleString('en-us', { notation: 'compact' })}
						</AbsoluteCenter>
						<Tooltip>
							<VStack textAlign='center'>
								<Text color='rgb(201, 30, 207)' fontSize='1.5rem' fontWeight='bold' margin='0'>
									{event.sourceName}
								</Text>
								<Text>From {sourceCharacter}</Text>
								<Text>{event.overTimeValue.toLocaleString('en-us')} hp per tick</Text>
								<Text>{(event.overTimeTickTime / 1000).toLocaleString('en-us')}s between ticks</Text>
								<Text>{event.overTimeTicksLeft.toLocaleString('en-us')} ticks remaining</Text>
							</VStack>
						</Tooltip>
					</Box>
				);
			})}
		</Flex>
	);
}
