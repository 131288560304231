import React, { useMemo } from 'react';
import {
	IChannelSettings,
	IChatMessage,
	IWhisperMessage,
} from '../../../../../game-server/src/modules/chat/Chat.interface';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import ChatMessageTooltip from './ChatMessageTooltip';
import ChatItem from './Links/ChatItem';
import ChatChest from './Links/ChatChest';
import ChatItemSet from './Links/ChatItemSet';
import ChatMarketplaceListing from './Links/ChatMarketplaceListing';
import ChatSkill from './Links/ChatSkill';
import { socket } from '../../../services/socket.service';
import { ErrorBoundary } from '../ErrorBoundary';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Linkify from 'linkify-react';
import { AiOutlineRollback, AiOutlineStop } from 'react-icons/ai';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Link,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { usePlayerField } from '../../../hooks/hooks';
import { IdlescapeButton } from '@idlescape/ui';
import ChatIcon from './ChatIcon';
import { leagueList } from '../../../utils/leagueList';
import ChatMarketplaceOffer from './Links/ChatMarketplaceOffer';
import ChatMessageTimestamp from './ChatMessageTimestamp';
import { PlayerData } from '../../../../../game-server/src/modules/player/PlayerData';
import ChatQuest from './Links/ChatQuest';
import { chatIconsIds } from '../../../utils/lookup-dictionaries/lookupChatIconList';
import { maxTotalLevel } from '../../../utils/experienceFunctions';

export const modIcons = {
	normal: -1,
	admin: chatIconsIds.admin,
	mod: chatIconsIds.moderator,
	'lead-mod': chatIconsIds.moderator,
	'chat-mod': chatIconsIds.chat_moderator,
	dev: chatIconsIds.dev_wizard_hat,
};

export default function ChatMessage({
	message,
	settings,
	showTimestamp = false,
}: {
	message: (IChatMessage | IWhisperMessage) & {
		read?: boolean;
		hasMention?: boolean;
	};
	settings: IChannelSettings | undefined;
	showTimestamp?: boolean;
}) {
	const playerSettings = usePlayerField('settings');
	const { showMeMessages, showLinks } = playerSettings.chat;
	const accountStatus = usePlayerField('accountStatus');
	const isMod = accountStatus !== 'normal';
	const ownUsername = usePlayerField('username');
	const isChannelModerator = settings?.channelModerators?.includes(ownUsername) ?? false;

	const [isTooltipEnabled, setIsTooltipEnabled] = React.useState(false);

	const { isOpen: isDeleteConfirmOpen, onOpen: onDeleteConfirmOpen, onClose: onDeleteConfirmClose } = useDisclosure();
	const confirmRef = React.useRef(null);

	let statusID = message.userStatus ? modIcons[message.userStatus] : -1;
	// For special people, like ForeverKing
	if (message.username === 'ForeverKing') {
		statusID = 15;
	}
	function askDeleteMessage(e: React.MouseEvent, restore: boolean) {
		if (typeof settings?.channelId === 'number') {
			if (e.ctrlKey || e.metaKey) {
				deleteMessage(restore);
			} else {
				onDeleteConfirmOpen();
			}
		}
	}

	function deleteMessage(restore: boolean) {
		if (typeof settings?.channelId === 'number') {
			socket.emit('chat:message:delete', {
				messageId: message.messageId,
				channelId: settings.channelId,
				deleted: !restore,
			});
		}
	}

	const renderModeratorDeleteMessage = (
		accountStatus: PlayerData['accountStatus'],
		owner: string | undefined,
		moderators: string[] | undefined,
		ownUsername: string,
		deleteFunction: (e: React.MouseEvent, restore: boolean) => void
	) => {
		const globalRoles = ['admin', 'lead-mod', 'mod', 'chat-mod', 'dev'];

		if (
			!globalRoles.includes(accountStatus) &&
			owner !== ownUsername &&
			(!moderators || !moderators.includes(ownUsername))
		) {
			return null;
		}
		const mode = message.deleted ? 'restore' : 'delete';
		const modeCapitalized = mode.charAt(0).toUpperCase() + mode.slice(1);
		const deleteConfirm = (
			<AlertDialog isOpen={isDeleteConfirmOpen} onClose={onDeleteConfirmClose} leastDestructiveRef={confirmRef}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader>{modeCapitalized} message</AlertDialogHeader>
						<AlertDialogBody>Are you sure you want to {mode} this message?</AlertDialogBody>
						<AlertDialogFooter>
							<IdlescapeButton variant='gray' onClick={onDeleteConfirmClose}>
								Cancel
							</IdlescapeButton>

							<IdlescapeButton
								ref={confirmRef}
								variant='red'
								onClick={() => {
									deleteMessage(message.deleted ?? false);
									onDeleteConfirmClose();
								}}
							>
								{modeCapitalized}
							</IdlescapeButton>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		);
		if (!message.deleted) {
			return (
				<>
					<span onClick={(e) => deleteFunction(e, false)} className='chat-message-moderator-delete'>
						<AiOutlineStop color='gray' />
					</span>
					{deleteConfirm}
				</>
			);
		} else {
			return (
				<>
					<span onClick={(e) => deleteFunction(e, true)} className='chat-message-moderator-delete'>
						<AiOutlineRollback color='gray' />
					</span>
					{deleteConfirm}
				</>
			);
		}
	};

	const renderUsername = (
		username: string,
		isTooltipEnabled: boolean,
		setIsTooltipEnabled: React.Dispatch<React.SetStateAction<boolean>>
	) => {
		if (!username) return <></>;

		return (
			<Text as='span' onClick={() => setIsTooltipEnabled(!isTooltipEnabled)} className='chat-message-username'>
				{/* We want to add an extra space after username if a 'me' message */}
				{message.visualClass === 'me-message' ? username + ' ' : username}
			</Text>
		);
	};

	const renderLevel = (league: number, totalLevel: number, masteryTotalLevel?: number) => {
		if (!totalLevel || !league || message.visualClass === 'me-message') return null;

		const leagueData = leagueList[league];
		const totalMaxLevel = maxTotalLevel(leagueData.rules.blacklistedSkills, leagueData.rules.expFormula);

		const levelToShow = totalLevel >= totalMaxLevel ? '★' + masteryTotalLevel : totalLevel;

		return (
			<Text as='span' className='chat-message-level' color={leagueList[league].levelColor}>
				[{levelToShow}]:{' '}
			</Text>
		);
	};

	const renderContent = (message: IChatMessage | IWhisperMessage) => {
		const content = message.message;
		if (!content) return null;

		const contentAsArray = content.split(/(\|{.*?}\|)/g);

		const contentWithLinks = parseLinks(contentAsArray);

		return (
			<Text as='span' className='chat-message-content'>
				{contentWithLinks}
			</Text>
		);
	};

	const parseLinks = (messageAsArray: string[]) => {
		const contentToRender = messageAsArray.map((item, index) => {
			if (item.startsWith('|{') && item.endsWith('}|')) {
				const itemToRender = JSON.parse(item.substring(1, item.length - 1));
				let toRender;
				try {
					switch (itemToRender.type) {
						case '@':
							toRender = <ChatItem item={itemToRender} />;
							break;
						case '%':
							toRender = <ChatItemSet itemSet={itemToRender} />;
							break;
						case '$':
							toRender = <ChatMarketplaceListing listing={itemToRender} />;
							break;
						case '¥':
							toRender = <ChatMarketplaceOffer offer={itemToRender} />;
							break;
						case '#':
							toRender = <ChatSkill skill={itemToRender} />;
							break;
						case '€':
							toRender = <ChatChest chest={itemToRender} />;
							break;
						case '?':
							toRender = <ChatQuest quest={itemToRender} />;
							break;
					}
				} catch (e) {
					console.error('FAILED TO RENDER A CHAT LINK', e);
				}
				if (toRender) return <ErrorBoundary key={index}>{toRender}</ErrorBoundary>;
				return item + ' (BROKEN CHAT LINK)';
			}

			return item;
		});

		return contentToRender;
	};

	const renderMessage = () => {
		if (!isMod && !isChannelModerator && message.deleted) return null;
		switch (message.visualClass) {
			case 'me-message':
				if (!showMeMessages) return null;
				break;
		}
		return (
			<div
				className={`chat-message ${message.hasMention ? 'chat-message-mention' : ''} ${message.visualClass} ${
					message.deleted ? 'deleted' : ''
				}`}
			>
				{renderModeratorDeleteMessage(
					accountStatus,
					settings?.channelOwner,
					settings?.channelModerators,
					ownUsername,
					askDeleteMessage
				)}
				<ChatMessageTimestamp message={message} show={showTimestamp} />
				{message.userPremium && message.iconID !== chatIconsIds.idlescape_plus && (
					<ChatIcon iconID={chatIconsIds.idlescape_plus} message={message} />
				)}
				{statusID !== -1 && message.iconID !== statusID && <ChatIcon iconID={statusID} message={message} />}
				<ChatIcon iconID={message.iconID} message={message} />

				<Popover
					isOpen={isTooltipEnabled}
					positions={['top']}
					padding={10}
					onClickOutside={() => setIsTooltipEnabled(false)}
					content={({ position, childRect, popoverRect }) => (
						<ArrowContainer
							position={position}
							childRect={childRect}
							popoverRect={popoverRect}
							arrowColor={'white'}
							arrowSize={8}
							arrowStyle={{ zIndex: 1000, opacity: 1 }}
						>
							<ChatMessageTooltip
								username={message.username}
								status={message.userStatus}
								setIsTooltipEnabled={setIsTooltipEnabled}
							></ChatMessageTooltip>
						</ArrowContainer>
					)}
				>
					{renderUsername(message.username, isTooltipEnabled, setIsTooltipEnabled)}
				</Popover>

				{renderLevel(message.league, message.totalLevel, message.masteryTotalLevel)}

				{showLinks ? (
					<Linkify options={{ attributes: { rel: 'noopener noreferrer' }, render: renderLink }}>
						{renderContent(message)}
					</Linkify>
				) : (
					renderContent(message)
				)}
			</div>
		);
	};
	return useMemo(renderMessage, [message.deleted, isTooltipEnabled, isDeleteConfirmOpen, showMeMessages, showLinks]);
}

const renderLink = ({ attributes, content }: { attributes: { [attr: string]: string }; content: string }) => {
	const { href, ...props } = attributes;
	return (
		<Link color={'teal.500'} href={href} {...props} target={'_blank'}>
			{content}
		</Link>
	);
};
