import { useGroupField } from '../../../hooks/hooks';
import CombatGroupInfoPlayer from './CombatGroupInfoPlayer';
import { useRecoilValue } from 'recoil';
import { monsterAtom } from '../../../atoms/monsterAtom';

export const CombatGroupInfo = () => {
	const groupMemberData = useGroupField('groupMemberData');
	const monsters = useRecoilValue(monsterAtom);
	const summons = monsters.filter((monster) => monster.faction === 'playerSummon');
	return (
		<div className='combat-group-info-container'>
			{groupMemberData?.map((elem) => {
				return <CombatGroupInfoPlayer key={'group' + elem.id} id={elem.id} groupPlayer={elem} />;
			})}
			{summons?.map((elem) => {
				return <CombatGroupInfoPlayer key={'summon' + elem.id} id={elem.id} groupMerc={elem} />;
			})}
		</div>
	);
};
