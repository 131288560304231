import {
	Flex,
	FormLabel,
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Switch,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { PiTreasureChestBold } from 'react-icons/pi';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { IChestData } from '../../../../../game-server/src/modules/client/ClientManager';
import { shareChestSelector } from '../../../atoms/chat/chatHistoryAtom';
import { openedChestAtom } from '../../../atoms/chat/openedChestAtom';
import useIsMobile from '../../../hooks/useIsMobile';
import { socket } from '../../../services/socket.service';
import { Item } from '../Inventory/Item';

export default function ChatChestShortcut() {
	const [shareAll, setShareAll] = useState(false);
	const { isOpen, onClose, onOpen } = useDisclosure();
	const [chests, setChests] = useRecoilState(openedChestAtom);
	const shareChest = useSetRecoilState(shareChestSelector);
	const isMobile = useIsMobile();

	useEffect(() => {
		socket.on('chest', handleChest);

		return () => {
			socket.off('chest', handleChest);
		};
	}, []);

	function handleChest(data: IChestData) {
		setChests((chests) => {
			const chestCopy = structuredClone(chests);
			const existing = chestCopy.find((chest) => chest.itemID === data.chestID);
			if (existing) {
				existing.stackSize = data.amount;
				existing.totalStackSize += data.amount;
			} else {
				chestCopy.push({
					id: data.id,
					itemID: data.chestID,
					stackSize: data.amount,
					totalStackSize: data.amount,
				});
			}
			return chestCopy;
		});
	}

	return (
		<>
			{chests.length > 0 && (
				<div className='chat-channel-function' onClick={onOpen}>
					<PiTreasureChestBold />
				</div>
			)}
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent className='sell-item-dialog'>
					<ModalHeader>Share opened chests</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Flex alignItems='center'>
							<FormLabel htmlFor='share-all-chests-switch'>Share All</FormLabel>
							<Switch
								id='share-all-chests-switch'
								isChecked={shareAll}
								onChange={(e) => setShareAll(e.target.checked)}
							/>
						</Flex>
						<Text>Click on any chest to share your latest loot.</Text>
						<Grid
							gridTemplateColumns={isMobile ? 'repeat(auto-fit, 50px)' : 'repeat(auto-fit, 64px)'}
							maxWidth='300px'
							justifyContent='center'
						>
							{chests.map((chest) => (
								<Item
									key={chest.itemID}
									item={{ ...chest, stackSize: shareAll ? chest.totalStackSize : chest.stackSize }}
									onClick={() => {
										shareChest([chest.itemID.toString(), shareAll ? chest.id : null]);
										onClose();
									}}
								/>
							))}
						</Grid>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}
