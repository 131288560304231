import './Achievements.css';
import { usePlayerField } from '../../../hooks/hooks';
import { leagueList } from '../../../utils/leagueList';
import { Box, Flex, Heading, Image } from '@chakra-ui/react';
import AchievementList from './AchievementList';
import QuestSummary from './QuestSummary';
import { TQuestCategory } from '../../../../../game-server/src/modules/quest/Quest.interface';
import { questList } from '../../../utils/questList';
import { sum, uniq } from 'lodash';

/**
 * This is used for both Quests AND Achievements
 */
export default function Achievements() {
	const league = usePlayerField('league');
	const leagueData = leagueList[league];
	const questsCompleted = usePlayerField('questsCompleted');
	const questProgress = usePlayerField('questProgress');
	if (!leagueData?.rules?.quests) {
		return <>You cannot do achievements in this league or season.</>;
	}
	const achievementCategories = uniq(
		Object.values(questList)
			.filter((q) => q.category && q.achievement)
			.map((q) => q.category)
	);
	const achievementPoints = sum(questsCompleted.map((q) => questList[q.id].questRewards?.achievementPoints));

	return (
		<Box className='achievement-container' height='calc(100% - 30px)'>
			<Flex justifyContent='center' alignItems='center' columnGap='10px' flexWrap='wrap'>
				<Heading textShadow='-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'>Achievements</Heading>
				<Heading size='l' textShadow='-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'>
					Points: {achievementPoints}
					<Image
						src={'/images/tutorial/achievement_point.png'}
						alt='Achievement Points'
						height='40px'
						width='40px'
						marginLeft='5px'
					/>
				</Heading>
			</Flex>
			<QuestSummary isAchievements={true} questsCompleted={questsCompleted} questProgress={questProgress} />
			<AchievementList league={league} />
			{achievementCategories.map((cat) => (
				<>
					<QuestSummary
						isAchievements={true}
						questsCompleted={questsCompleted}
						questProgress={questProgress}
						category={cat as TQuestCategory}
					/>
					<AchievementList league={league} category={cat as TQuestCategory} />
				</>
			))}
		</Box>
	);
}
