// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */

export const itemsIds = {
	gold: 1,
	heat: 2,
	air_essence: 3,
	earth_essence: 4,
	fire_essence: 5,
	water_essence: 6,
	blood_essence: 7,
	death_essence: 8,
	chaos_essence: 9,
	nature_essence: 10,
	mind_essence: 11,
	cosmic_essence: 12,
	platinum: 20,
	book: 50,
	task: 51,
	raw_shrimp: 60,
	raw_anchovy: 61,
	raw_trout: 62,
	raw_salmon: 63,
	raw_lobster: 64,
	raw_tuna: 65,
	raw_shark: 66,
	raw_megalodon: 67,
	raw_ancient_lobster: 68,
	raw_tentacle_meat: 69,
	raw_huge_tuna: 70,
	seaweed: 71,
	raw_magnetic_minnow: 72,
	raw_tigerfish: 73,
	raw_slippery_eel: 74,
	raw_great_white_shark: 75,
	raw_hammerhead_shark: 76,
	raw_crab: 77,
	burnt_fish: 95,
	burnt_food: 96,
	copper_ore: 101,
	tin_ore: 102,
	iron_ore: 103,
	gold_ore: 104,
	mithril_ore: 105,
	adamantite_ore: 106,
	runite_ore: 107,
	clay: 108,
	stone: 109,
	sand: 110,
	silver: 111,
	coal: 112,
	rune_slate: 113,
	stygian_ore: 114,
	void_stone: 115,
	fertilizer: 150,
	carrot_seed: 151,
	peppercorn_seed: 152,
	sugarcane_seed: 153,
	wheat_seed: 154,
	potato_seed: 155,
	rice_seed: 156,
	tomato_seed: 157,
	wildberry_bush_seed: 158,
	chili_pepper_seed: 159,
	pumpkin_seed: 160,
	mushroom_spore: 161,
	tree_seed: 170,
	oak_tree_seed: 171,
	willow_tree_seed: 172,
	maple_tree_seed: 173,
	yew_tree_seed: 174,
	banana_tree_seed: 175,
	apple_tree_seed: 176,
	elder_tree_seed: 177,
	sageberry_bush_seed: 180,
	mysterious_seed: 185,
	durian_seed: 186,
	bronze_bar: 201,
	iron_bar: 202,
	gold_bar: 203,
	mithril_bar: 204,
	adamantite_bar: 205,
	runite_bar: 206,
	stygian_bar: 207,
	chaotic_crystal: 208,
	obsidian_glass: 209,
	golem_marrow: 210,
	chaos_stone: 211,
	living_shard: 212,
	runed_stone: 213,
	magma_core: 214,
	heavy_chaos_shard: 215,
	worn_ancient_splinter: 216,
	royal_ice_shard: 217,
	branch: 301,
	log: 302,
	oak_log: 303,
	willow_log: 304,
	maple_log: 305,
	yew_log: 306,
	elder_log: 307,
	sapphire: 400,
	emerald: 401,
	ruby: 402,
	diamond: 403,
	black_opal: 404,
	runic_etchant: 405,
	corrupted_substrate: 406,
	reactive_powder: 407,
	polishing_paste: 408,
	sanguine_infusion: 409,
	air_talisman: 500,
	earth_talisman: 501,
	fire_talisman: 502,
	water_talisman: 503,
	blood_talisman: 504,
	death_talisman: 505,
	chaos_talisman: 506,
	nature_talisman: 507,
	mind_talisman: 508,
	cosmic_talisman: 509,
	air_rune: 510,
	earth_rune: 511,
	fire_rune: 512,
	water_rune: 513,
	blood_rune: 514,
	death_rune: 515,
	chaos_rune: 516,
	nature_rune: 517,
	mind_rune: 518,
	cosmic_rune: 519,
	unstable_talisman: 520,
	basic_runic_dust: 550,
	unusual_runic_dust: 551,
	singular_runic_dust: 552,
	prime_runic_dust: 553,
	mythical_runic_dust: 554,
	common_gear_scraps: 555,
	uncommon_gear_scraps: 556,
	rare_gear_scraps: 557,
	epic_gear_scraps: 558,
	legendary_gear_scraps: 559,
	bronze_pickaxe: 600,
	iron_pickaxe: 601,
	mithril_pickaxe: 602,
	adamantite_pickaxe: 603,
	runite_pickaxe: 604,
	stygian_pickaxe: 605,
	dwarven_rocksmasher: 606,
	bronze_hatchet: 610,
	iron_hatchet: 611,
	mithril_hatchet: 612,
	adamantite_hatchet: 613,
	runite_hatchet: 614,
	stygian_hatchet: 615,
	the_grovekeeper: 616,
	sapphire_ring: 620,
	sapphire_necklace: 621,
	emerald_ring: 622,
	emerald_necklace: 623,
	ruby_ring: 624,
	ruby_necklace: 625,
	diamond_ring: 626,
	diamond_necklace: 627,
	prismatic_necklace: 628,
	prismatic_ring: 629,
	black_opal_ring: 630,
	black_opal_necklace: 631,
	gold_ring: 640,
	gold_necklace: 641,
	corrupted_ring: 642,
	ancient_nature_amulet: 643,
	ancient_fire_amulet: 644,
	ancient_water_amulet: 645,
	ancient_blood_amulet: 646,
	ancient_air_amulet: 647,
	ancient_chaos_amulet: 648,
	bronze_hoe: 660,
	iron_hoe: 661,
	mithril_hoe: 662,
	adamantite_hoe: 663,
	runite_hoe: 664,
	stygian_hoe: 665,
	bronze_tongs: 666,
	iron_tongs: 667,
	mithril_tongs: 668,
	adamantite_tongs: 669,
	runite_tongs: 670,
	stygian_tongs: 671,
	lesser_magic_tome: 672,
	major_magic_tome: 673,
	greater_magic_tome: 674,
	elder_magic_tome: 675,
	ancient_magic_tome: 676,
	apprentice_runic_chisel: 677,
	journeyman_runic_chisel: 678,
	master_runic_chisel: 679,
	ancient_runic_chisel: 680,
	fishing_net: 690,
	fly_fishing_rod: 691,
	cage: 692,
	harpoon: 693,
	feather: 700,
	fish_oil: 701,
	pyre_log: 702,
	pyre_oak_log: 703,
	pyre_willow_log: 704,
	pyre_maple_log: 705,
	pyre_yew_log: 706,
	pyre_elder_log: 707,
	inert_heat_stone: 708,
	charged_heat_stone: 709,
	ichor: 800,
	phoenixs_feather: 801,
	geode: 900,
	birds_nest: 901,
	sunken_treasure: 902,
	satchel: 903,
	ancient_treasure: 904,
	greater_geode: 905,
	greater_birds_nest: 906,
	bronze_scimitar: 1000,
	bronze_battleaxe: 1001,
	bronze_daggers: 1002,
	bronze_greatsword: 1003,
	iron_scimitar: 1004,
	iron_battleaxe: 1005,
	iron_daggers: 1006,
	iron_greatsword: 1007,
	obsidian_scimitar: 1008,
	obsidian_battleaxe: 1009,
	obsidian_daggers: 1010,
	obsidian_greatsword: 1011,
	mithril_scimitar: 1012,
	mithril_battleaxe: 1013,
	mithril_daggers: 1014,
	mithril_greatsword: 1015,
	adamantite_scimitar: 1016,
	adamantite_battleaxe: 1017,
	adamantite_daggers: 1018,
	adamantite_greatsword: 1019,
	runite_scimitar: 1020,
	runite_battleaxe: 1021,
	runite_daggers: 1022,
	runite_greatsword: 1023,
	stygian_scimitar: 1024,
	stygian_battleaxe: 1025,
	stygian_daggers: 1026,
	stygian_greatsword: 1027,
	bronze_helm: 1050,
	iron_helm: 1051,
	obsidian_helm: 1052,
	mithril_helm: 1053,
	adamantite_helm: 1054,
	runite_helm: 1055,
	stygian_helm: 1056,
	bronze_full_helm: 1060,
	iron_full_helm: 1061,
	obsidian_full_helm: 1062,
	mithril_full_helm: 1063,
	adamantite_full_helm: 1064,
	runite_full_helm: 1065,
	stygian_full_helm: 1066,
	bronze_shield: 1070,
	iron_shield: 1071,
	obsidian_shield: 1072,
	mithril_shield: 1073,
	adamantite_shield: 1074,
	runite_shield: 1075,
	stygian_shield: 1076,
	castle_door: 1077,
	bronze_breastplate: 1090,
	bronze_chainmail: 1091,
	iron_breastplate: 1092,
	iron_chainmail: 1093,
	obsidian_breastplate: 1094,
	obsidian_chainmail: 1095,
	mithril_breastplate: 1096,
	mithril_chainmail: 1097,
	adamantite_breastplate: 1098,
	adamantite_chainmail: 1099,
	runite_breastplate: 1100,
	runite_chainmail: 1101,
	stygian_breastplate: 1102,
	stygian_chainmail: 1103,
	bronze_plate_leggings: 1110,
	iron_plate_leggings: 1111,
	obsidian_plate_leggings: 1112,
	mithril_plate_leggings: 1113,
	adamantite_plate_leggings: 1114,
	runite_plate_leggings: 1115,
	stygian_plate_leggings: 1116,
	bronze_boots: 1130,
	iron_boots: 1131,
	obsidian_boots: 1132,
	mithril_boots: 1133,
	adamantite_boots: 1134,
	runite_boots: 1135,
	stygian_boots: 1136,
	bronze_gloves: 1150,
	iron_gloves: 1151,
	obsidian_gloves: 1152,
	mithril_gloves: 1153,
	adamantite_gloves: 1154,
	runite_gloves: 1155,
	stygian_gloves: 1156,
	hide_body: 1157,
	fur_body: 1158,
	thick_hide_body: 1159,
	leather_body: 1160,
	reinforced_leather_body: 1161,
	chitin_body: 1162,
	scale_body: 1163,
	hide_coif: 1164,
	fur_coif: 1165,
	thick_hide_coif: 1166,
	leather_coif: 1167,
	reinforced_leather_coif: 1168,
	chitin_coif: 1169,
	scale_coif: 1170,
	hide_trousers: 1171,
	fur_trousers: 1172,
	thick_hide_trousers: 1173,
	leather_trousers: 1174,
	reinforced_leather_trousers: 1175,
	chitin_trousers: 1176,
	scale_trousers: 1177,
	robe_top: 1178,
	practitioner_robe_top: 1179,
	academy_robe_top: 1180,
	imbued_robe_top: 1181,
	major_imbued_robe_top: 1182,
	greater_imbued_robe_top: 1183,
	elder_robe_top: 1184,
	robe_hood: 1185,
	practitioner_hood: 1186,
	academy_hood: 1187,
	imbued_hood: 1188,
	major_imbued_hood: 1189,
	greater_imbued_hood: 1190,
	elder_hood: 1191,
	robe_bottom: 1192,
	practitioner_robe_bottom: 1193,
	academy_robe_bottom: 1194,
	imbued_robe_bottom: 1195,
	major_imbued_robe_bottom: 1196,
	greater_imbued_robe_bottom: 1197,
	elder_robe_bottom: 1198,
	flimsy_recurve_bow: 1199,
	sturdy_recurve_bow: 1200,
	oak_recurve_bow: 1201,
	willow_recurve_bow: 1202,
	maple_recurve_bow: 1203,
	yew_recurve_bow: 1204,
	elder_recurve_bow: 1205,
	flimsy_longbow: 1206,
	sturdy_longbow: 1207,
	oak_longbow: 1208,
	willow_longbow: 1209,
	maple_longbow: 1210,
	yew_longbow: 1211,
	elder_longbow: 1212,
	flimsy_crossbow: 1213,
	light_crossbow: 1214,
	bone_crossbow: 1215,
	heavy_crossbow: 1216,
	reinforced_crossbow: 1217,
	arbalest: 1218,
	elder_crossbow: 1219,
	rough_throwing_knife: 1220,
	throwing_knife: 1221,
	light_throwing_knife: 1222,
	refined_throwing_knife: 1223,
	throwing_axe: 1224,
	throwing_javelin: 1225,
	throwing_spinner: 1226,
	driftwood_wand: 1227,
	sturdy_wand: 1228,
	academy_wand: 1229,
	imbued_wand: 1230,
	major_imbued_wand: 1231,
	greater_imbued_wand: 1232,
	elder_wand: 1233,
	driftwood_staff: 1234,
	sturdy_staff: 1235,
	academy_staff: 1236,
	imbued_staff: 1237,
	major_imbued_staff: 1238,
	greater_imbued_staff: 1239,
	elder_staff: 1240,
	arcane_notes: 1241,
	simple_arcane_focus: 1242,
	academy_focus: 1243,
	imbued_focus: 1244,
	major_imbued_focus: 1245,
	greater_imbued_focus: 1246,
	elder_focus: 1247,
	blademasters_shroud: 1300,
	blademasters_memory: 1301,
	royal_banner: 1302,
	torn_banner: 1303,
	necromancers_cowl: 1304,
	necromancers_robe_top: 1305,
	necromancers_robe_bottoms: 1306,
	book_of_death: 1307,
	necromancers_wand: 1308,
	necromancers_remains: 1309,
	royal_elven_remnants: 1310,
	gargoyle_shards: 1311,
	mantis_shrimp_carapace: 1312,
	mantis_shrimp_greaves: 1313,
	mantis_shrimp_helm: 1314,
	mantis_shrimp_shell: 1315,
	mantis_shrimp_gauntlets: 1316,
	voidblessed_shrimp: 1317,
	witchhunter_handgonne: 1318,
	personal_cannon: 1319,
	illegal_gun_parts: 1320,
	shoddy_ammo: 1450,
	amateur_ammo: 1451,
	apprentice_ammo: 1452,
	journeyman_ammo: 1453,
	professional_ammo: 1454,
	master_ammo: 1455,
	experimental_ammo: 1456,
	void_ammo: 1457,
	lowgrade_whetstone: 1458,
	midgrade_whetstone: 1459,
	highgrade_whetstone: 1460,
	lesser_imbued_charm: 1461,
	major_imbued_charm: 1462,
	greater_imbued_charm: 1463,
	ancient_imbued_charm: 1464,
	dwarven_whetstone: 1465,
	elven_ammo: 1466,
	santa_hat: 1500,
	snowman_head: 1501,
	tophat: 1502,
	lesser_ladle: 1503,
	ladle: 1504,
	greater_ladle: 1505,
	moss_maul: 1506,
	modified_diving_gloves: 1507,
	kings_crown: 1508,
	fire_orb: 1509,
	kalanahmatti: 1510,
	shard_of_kalanahmatti: 1511,
	zero_edge: 1512,
	chaos_crest: 1513,
	forgotten_sadness: 1514,
	forgotten_madness: 1515,
	forgotten_anger: 1516,
	gargoyle_chainmail: 1517,
	gargoyle_legs: 1518,
	gargoyle_boots: 1519,
	gargoyle_helm: 1520,
	gargoyle_shield: 1521,
	gargoyle_falchion: 1522,
	gargoyle_daggers: 1523,
	gargoyle_glaive: 1524,
	infernal_lance: 1525,
	black_knight_great_helm: 1526,
	chorus_of_souls: 1527,
	decayed_cloak: 1528,
	reinforced_cloak: 1529,
	mysterious_man: 1530,
	corny_joke: 1531,
	death_metal_wig: 1532,
	shrimp_carapace: 1540,
	shrimp_greaves: 1541,
	shrimp_helm: 1542,
	shrimp_shell: 1543,
	nagas_bracelet: 1544,
	titanic_gauntlet: 1545,
	awakened_world_shaper: 1546,
	shapeless_scythe: 1547,
	giant_kings_jewel: 1548,
	giant_scraps: 1549,
	world_walkers: 1550,
	giant_kings_nails: 1551,
	cooked_giant_kings_nails: 1552,
	crystal_mace: 1553,
	signet_ring: 1554,
	mantle_of_flame: 1555,
	bestial_shawl: 1556,
	hands_of_khaast: 1557,
	shrimp_scraps: 1558,
	black_knight_titans_helm: 1559,
	elven_hood: 1560,
	elven_heavy_armor: 1561,
	elven_leggings: 1562,
	elven_boots: 1563,
	searing_ladle: 1564,
	black_knights_gauntlets: 1565,
	bestial_dagger: 1566,
	empowered_bestial_dagger: 1567,
	bestial_defender: 1568,
	empowered_bestial_defender: 1569,
	elven_kings_longbow: 1570,
	ancient_trident: 1571,
	fishtail: 1572,
	razorfin: 1573,
	elven_quiver: 1574,
	magic_quiver: 1575,
	defenders_band: 1576,
	defenders_pendant: 1577,
	scaled_circuit: 1578,
	devourers_band: 1579,
	slayers_pendant: 1580,
	alacrity_pendant: 1581,
	ring_of_taxes: 1582,
	wrath_and_war: 1583,
	shrimp_scepter: 1584,
	shrimp_stinger: 1585,
	shrimp_tail: 1586,
	shrimp_eye: 1587,
	hunters_band: 1588,
	dwarven_hammer_necklace: 1589,
	ancient_token: 1590,
	ancient_ring: 1591,
	golem_ring: 1592,
	phroglin_pheromones: 1593,
	elven_light_armor: 1594,
	elven_hat: 1595,
	sylvan_staff: 1596,
	master_parrying_gauntlet: 1597,
	expert_parrying_gauntlet: 1598,
	beginner_parrying_gauntlet: 1599,
	scroll: 1600,
	scroll_of_embers: 1601,
	scroll_of_the_prospector: 1602,
	scroll_of_the_scholar: 1603,
	scroll_of_gathering: 1604,
	scroll_of_superheating: 1605,
	scroll_of_pyromancy: 1606,
	scroll_of_wealth: 1607,
	scroll_of_haste: 1608,
	scroll_of_naturalist: 1609,
	scroll_of_acrobatics: 1610,
	scroll_of_critical_strike: 1611,
	scroll_of_poisoning: 1612,
	scroll_of_accuracy: 1613,
	scroll_of_deflect: 1614,
	scroll_of_force: 1615,
	scroll_of_healing: 1616,
	scroll_of_weakening: 1617,
	scroll_of_reinforcement: 1618,
	scroll_of_protection: 1619,
	scroll_of_parry: 1620,
	scroll_of_false_equilibrium: 1621,
	scroll_of_efficiency: 1622,
	scroll_of_fishing: 1623,
	scroll_of_cooking: 1624,
	scroll_of_crafting: 1625,
	scroll_of_refining: 1626,
	scroll_of_runecrafting: 1627,
	scroll_of_chances: 1628,
	scroll_of_the_shrimp_lord: 1629,
	scroll_of_enlightenment: 1630,
	scroll_of_prolonging: 1631,
	scroll_of_the_treasure_hunter: 1632,
	scroll_of_inferno: 1633,
	scroll_of_destructive_testing: 1634,
	scroll_of_nature: 1635,
	scroll_of_root_digging: 1636,
	scroll_of_patience: 1637,
	scroll_of_overhealing: 1638,
	scroll_of_farming: 1639,
	scroll_of_the_gold_digger: 1640,
	scroll_of_fertilizing: 1641,
	scroll_of_cultivation: 1642,
	scroll_of_fortitude: 1643,
	scroll_of_pungent_bait: 1644,
	scroll_of_reinforced_line: 1645,
	scroll_of_fishing_magnetism: 1646,
	scroll_of_bait_preservation: 1647,
	scroll_of_oil_spill: 1648,
	scroll_of_deadliest_catch: 1649,
	scroll_of_the_master_chef: 1650,
	scroll_of_the_herbalist: 1651,
	scroll_of_seed_harvesting: 1652,
	scroll_of_metallurgy: 1653,
	scroll_of_pure_metals: 1654,
	scroll_of_essence_concentration: 1655,
	scroll_of_the_fiber_finder: 1656,
	scroll_of_archaeology: 1657,
	scroll_of_endless_ammo: 1658,
	scroll_of_rune_reserves: 1659,
	scroll_of_the_soul_wisdom: 1660,
	scroll_of_overly_well_done: 1661,
	scroll_of_recklessness: 1662,
	scroll_of_engraving: 1663,
	scroll_of_resistance: 1664,
	scroll_of_supercooling: 1665,
	scroll_of_dwarven_engineering: 1666,
	scroll_of_taunting: 1667,
	scroll_of_convergence: 1668,
	scroll_of_finesse: 1669,
	scroll_of_fibrous_fabrication: 1670,
	scroll_of_crucible: 1671,
	scroll_of_forge_enhancement: 1672,
	scroll_of_forge_maintenance: 1673,
	scroll_of_leftovers: 1674,
	scroll_of_blitzing: 1675,
	scroll_of_duster: 1676,
	scroll_of_scrapper: 1677,
	scroll_of_immunity: 1678,
	scroll_of_quick_study: 1679,
	scroll_of_elemental_accuracy: 1680,
	scroll_of_physical_accuracy: 1681,
	scroll_of_fishy_fortune: 1682,
	scroll_of_fiber_snagging: 1683,
	scroll_of_junk_snagging: 1684,
	scroll_of_threadbare_charm: 1685,
	scroll_of_researchers_boon: 1686,
	scroll_of_the_hunter_gatherer: 1687,
	scroll_of_overgrowth: 1688,
	scroll_of_dot_dealer: 1689,
	elven_royal_leggings: 1800,
	elven_royal_tunic: 1801,
	elven_royal_hat: 1802,
	infernal_chainmail: 1803,
	infernal_legs: 1804,
	infernal_helm: 1805,
	symphony_of_spirits: 1806,
	crown_of_spikes: 1807,
	dark_ring: 1808,
	pendant_of_virulence: 1809,
	gargoyle_battleaxe: 1810,
	infernal_battleaxe: 1811,
	infernal_daggers: 1812,
	infernal_falchion: 1813,
	infernal_shield: 1814,
	militia_quiver: 1815,
	rune_bag: 1816,
	goblin_club: 1817,
	demon_tooth_spear: 1818,
	log_maul: 1819,
	damaged_goblin_club: 1820,
	sharp_demon_tooth: 1821,
	damaged_log_maul: 1822,
	soul_fragment: 1823,
	damaged_militia_sling: 1824,
	damaged_shaman_staff: 1825,
	militia_sling: 1826,
	shaman_staff: 1827,
	shattered_forging_hammer: 1828,
	broken_hammer_head: 1829,
	giant_champions_belt: 1830,
	spiked_cuffs: 1831,
	bestial_cloak: 1832,
	charred_pelt: 1833,
	wand_of_the_phoenix: 1834,
	dormant_phoenix_egg: 1835,
	all_potions: 1998,
	all_foods: 1999,
	raw_chicken: 2000,
	raw_beef: 2001,
	spider_legs: 2009,
	goblin_brain: 2010,
	pepper: 2012,
	salt: 2013,
	ashes: 2014,
	sugar: 2015,
	honey: 2016,
	milk: 2017,
	butter: 2018,
	egg: 2019,
	mushroom: 2020,
	carrot: 2021,
	chili_pepper: 2022,
	potato: 2023,
	pumpkin: 2024,
	tomato: 2025,
	apple: 2026,
	wildberry: 2027,
	banana: 2028,
	wheat: 2029,
	rice: 2030,
	sageberry: 2031,
	choccy_milk: 2032,
	filleted_fish: 2034,
	ground_flour: 2036,
	churned_dairy: 2037,
	diced_fruit: 2039,
	chopped_vegetables: 2040,
	minced_meat: 2043,
	butchered_monster: 2044,
	sour_essence: 2045,
	bitter_essence: 2046,
	sweet_essence: 2047,
	spicy_essence: 2048,
	seed_oil: 2049,
	questionable_food: 2100,
	monster_surprise: 2101,
	seared_steak: 2102,
	seared_fish: 2103,
	seared_poultry: 2104,
	birdie_pasta: 2105,
	spaghetti_meat: 2106,
	sushi: 2107,
	birdie_pie: 2108,
	cottage_pie: 2109,
	fishermans_pie: 2110,
	fruit_pie: 2111,
	demon_cake: 2112,
	cake: 2113,
	fruit_cake: 2114,
	grain_cake: 2115,
	omelette: 2116,
	monster_omelette: 2117,
	meaty_omelette: 2118,
	veggie_crepe: 2119,
	fruity_crepe: 2120,
	eggnog: 2121,
	meat_roast: 2122,
	fried_fruit: 2123,
	grilled_veggies: 2124,
	kebabs: 2125,
	candied_fruit: 2126,
	milkshake: 2127,
	vegetable_stew: 2128,
	meaty_stew: 2129,
	monster_stew: 2130,
	jerky: 2131,
	pudding: 2132,
	ice_cream: 2133,
	monster_curry: 2134,
	birdie_curry: 2135,
	beast_curry: 2136,
	seafood_curry: 2137,
	monster_gelato: 2138,
	common_potion: 2139,
	common_concentrated_potion: 2140,
	common_large_potion: 2141,
	rare_potion: 2142,
	rare_concentrated_potion: 2143,
	rare_large_potion: 2144,
	epic_potion: 2145,
	epic_large_potion: 2146,
	legendary_potion: 2147,
	legendary_large_potion: 2148,
	epic_concentrated_potion: 2149,
	water_imbued_dust: 3000,
	empty_vial: 3001,
	empty_large_vial: 3002,
	empty_reinforced_vial: 3003,
	watermelon: 3004,
	durian: 3005,
	onion: 3006,
	mana_berry: 3007,
	thorny_root: 3008,
	thorny_vine: 3009,
	tangle_root: 3010,
	mandrake: 3011,
	jellyfish: 3012,
	frost_flowers: 3013,
	black_berry: 3014,
	fairy_dust: 3015,
	lotus_flower: 3016,
	fourleaf_clover: 3017,
	glow_spore_shroom: 3018,
	pineapple: 3019,
	ghost_shrooms: 3020,
	yellow_pepper: 3021,
	bone: 4000,
	bone_plate: 4001,
	chitin: 4002,
	heavy_chitin: 4003,
	fur: 4004,
	thick_fur: 4005,
	wool: 4006,
	hide: 4007,
	rough_hide: 4008,
	scale: 4009,
	heavy_scale: 4010,
	crystal_scale: 4011,
	leather: 4012,
	rough_cloth: 4013,
	restored_cloth: 4014,
	lesser_cloth: 4015,
	imbued_cloth: 4016,
	major_cloth: 4017,
	greater_cloth: 4018,
	elder_cloth: 4019,
	aqueous_grass: 4020,
	water_weeds: 4021,
	river_vines: 4022,
	violet_ribbons: 4023,
	white_reeds: 4024,
	ancient_kelp: 4025,
	aqueous_chunks: 4026,
	frost_shard: 4027,
	elder_stone: 4028,
	box_trap: 4500,
	pitfall_trap: 4501,
	bird_trap: 4502,
	net_trap: 4503,
	bear_trap: 4504,
	water_totem: 4505,
	nature_totem: 4506,
	earth_totem: 4507,
	coalescing_totem: 4508,
	monster_trap: 4509,
	worm_composting_bin: 4510,
	shrimp_bauble: 5000,
	shrimp: 5001,
	shrimp_ascendant: 5002,
	gold_medal: 6000,
	silver_medal: 6001,
	bronze_medal: 6002,
	event_token: 6950,
	snow: 7000,
	snowball: 7001,
	wrapping_paper: 7002,
	present: 7003,
	cookie_dough: 7004,
	cookie: 7005,
	gummy_fish: 7006,
	gummy_shark_fin: 7007,
	krampus_horn: 7008,
	candy_buttons: 7009,
	scarf: 7010,
	evergreen_log: 7011,
	corrupted_evergreen_log: 7012,
	magic_ice: 7013,
	inferior_bearded_wanderer: 7014,
	inferior_reindeer_hunters_armor: 7015,
	inferior_reindeer_hunters_leggings: 7016,
	inferior_christmas_lights: 7017,
	supersled_1337: 7018,
	bearded_wanderer: 7019,
	reindeer_hunters_armor: 7020,
	reindeer_hunters_leggings: 7021,
	christmas_lights: 7022,
	supersled_9000: 7023,
	superior_bearded_wanderer: 7024,
	superior_reindeer_hunters_armor: 7025,
	superior_reindeer_hunters_leggings: 7026,
	superior_christmas_lights: 7027,
	supersled_9001: 7028,
	magical_snowball: 7029,
	candy_cane_daggers: 7030,
	festive_shroud: 7031,
	merry_makers_mittens: 7032,
	mistletoe_treads: 7033,
	magic_ice_bar: 7034,
	winter_rune: 7035,
	scroll_of_candy_canes: 7036,
	gummy_lure: 7037,
	winter_essence: 7038,
	gummy_shark: 7039,
	angry_letter: 7040,
	baubles: 7041,
	extra_large_snowball: 7042,
	extra_large_baubles: 7043,
	snowglobe: 7050,
	mr_klimp: 7060,
	the_mine_dwelling_hamster: 7061,
	lil_crown_hamperster: 7062,
	lightning_edge: 7063,
	frost_maul: 7064,
	abusive: 7065,
	elite_titan_helm: 7066,
	ancient_crown: 7067,
	idlescape_bond: 7068,
	character_token: 7069,
	active_character_token: 7070,
	item_name_token: 7071,
	vacation_token: 7072,
	loadout_token: 7073,
	holy_avenger_helm: 7074,
	holy_avenger_breastplate: 7075,
	holy_avenger_cape: 7076,
	holy_avenger_leggings: 7077,
	holy_avenger_boots: 7078,
	holy_avenger_gloves: 7079,
	a_mad_faiths_wrath: 7080,
	limpe_k_limpe: 7081,
	username_change_token: 7082,
	shapeless_bow: 7083,
	shapeless_staff: 7084,
	gift_of_the_lightning_king: 7085,
	put_something_on: 7086,
	fresh_off_the_island: 7087,
	forgotten_platebody: 7088,
	inventory_tab_token: 7089,
	user_action_queue_count_token: 7090,
	user_action_queue_count_token_formerly_length_token: 7091,
	valiant_lumberjacks_vest: 7092,
	valiant_lumberjacks_hat: 7093,
	valiant_lumberjacks_trousers: 7094,
	valiant_camo_cloak: 7095,
	night_walker: 7096,
	night_wand3rer: 7097,
	celestial: 7098,
	eyes_of_chaos: 7099,
	forgotten_platelegs: 7100,
	tiny_wise_hat: 7101,
	wise_hat: 7102,
	big_wise_hat: 7103,
	librarians_chef_hat: 7104,
	librarians_chef_coat: 7105,
	librarians_chef_pants: 7106,
	master_chefs_lesser_ladle: 7107,
	master_chefs_ladle: 7108,
	master_chefs_greater_ladle: 7109,
	master_chefs_golden_spoon: 7110,
	lichs_visage: 7111,
	lichs_robe_top: 7112,
	lichs_robe_bottoms: 7113,
	lichs_gloves: 7114,
	lichs_boots: 7115,
	marshall_the_rats_gun: 7116,
	profile_slot_token: 7117,
	randomisator_horse: 7118,
	lost_artisan: 7119,
	lost_craftsman: 7120,
	rise_of_info: 8000,
	black_knights_trial: 8001,
	giant_scroll: 8002,
	corroded_scroll: 8003,
	stew_stained_page: 8004,
	clustercluck: 8005,
	demonic_trial: 8006,
	taproot_scroll: 8007,
	the_final_test: 8008,
	shapeless_scroll: 8009,
	stormy_sea_scroll: 8010,
	enchanted_armory_scroll: 8011,
	fallen_academy_scroll: 8012,
	overgrown_woods_scroll: 8013,
	elven_encampment_scroll: 8014,
	giants_keep_key: 9000,
	nature_fragment: 9001,
	ice_fragment: 9002,
	fire_fragment: 9003,
	corrupted_fragment: 9004,
	chaos_fragment: 9005,
	torn_goblin_settlement_map: 9006,
	scrap_of_stained_parchment: 9007,
	dark_fortress_key: 9008,
	dark_key_piece: 9009,
	cursed_spade: 9010,
	burning_feather: 9011,
	krampus_key: 9012,
	molten_ashes: 9013,
	infernal_flame: 9014,
	cracked_shrimp_bauble: 9015,
	elder_lockstone: 9016,
	castle_map: 9017,
	ancient_pyramid: 9018,
	ancient_maelstrom_orb: 9019,
	ancient_crystal: 9020,
	ancient_stone: 9021,
	partial_map: 9022,
	elder_gem: 9023,
	awakened_shrimp_eye: 9024,
	dwarven_research_key: 9025,
	heart_of_the_oak: 9026,
	abandoned_academy_scroll: 9027,
	core_fragment: 9028,
	core_ingot: 9029,
	ancient_cloth_fragment: 9030,
	ancient_oak_seed: 9031,
	cursed_spade_head: 9032,
	dark_vault_key: 9033,
	dark_vault_identification_crystal: 9034,
	prismatic_pudding: 9035,
	prismatic_extract: 9036,
	the_castled_king: 10000,
	dextrous_scraps: 10001,
	intellectual_scraps: 10002,
	gem_package: 10003,
	sealed_scroll: 10004,
	small_gathering_supply_crate: 10005,
	gathering_supply_crate: 10006,
	large_gathering_supply_crate: 10007,
	bucket_of_worms: 10008,
	box_of_skill_books: 10009,
	box_of_jewelry: 10010,
	magic_starter_kit: 10011,
	range_starter_kit: 10012,
	lesser_jewelry_cache: 10013,
	greater_jewelry_cache: 10014,
	goblin_cache: 10015,
	dark_cache: 10016,
	giant_cache: 10017,
	elder_cache: 10018,
	overgrown_cache: 10019,
	barrows_cache: 10020,
	flaming_cache: 10021,
	storm_cache: 10022,
	maelstrom_cache: 10023,
	info_cache: 10024,
	ancient_cache: 10025,
	dwarven_research_cache: 10026,
	elven_research_cache: 10027,
	academy_research_cache: 10028,
	dark_vault_cache: 10029,
	chicken_kitchen_cache: 10030,
	novice_tacklebox: 11000,
	basic_tacklebox: 11001,
	advanced_tacklebox: 11002,
	pro_tacklebox: 11003,
	supreme_tacklebox: 11004,
	oceans_embrace: 11005,
	earthworm_bait: 11010,
	fat_nightcrawler_bait: 11011,
	glowbug_bait: 11012,
	shrimp_tail_bait: 11013,
	fish_guts_bait: 11014,
	smelly_meat_bait: 11015,
	hardened_hook_bait: 11016,
	alluring_lure_bait: 11017,
	writhing_tentacle_bait: 11018,
	mysterious_bait: 11019,
	smelly_fish_guts_bait: 11020,
	crustacean_meat_bait: 11021,
	improved_hook_bait: 11022,
	rotten_driftwood: 11030,
	sturdy_driftwood: 11031,
	aquamarine: 11032,
	ancient_fishing_rod: 11033,
	ancient_harpoon: 11034,
	ancient_fishing_net: 11035,
	mystical_driftwood: 11036,
	dwarven_alloy: 11037,
	dwarven_ingot: 11038,
	dwarven_mold: 11039,
	very_mysterious_seedling: 11040,
	elder_sap: 11041,
	world_tree_branch: 11042,
	fishermans_smock: 12000,
	fishermans_bucket_hat: 12001,
	fishermans_waders: 12002,
	miners_smock: 12003,
	miners_lantern_helmet: 12004,
	miners_trousers: 12005,
	lumberjacks_vest: 12006,
	lumberjacks_hat: 12007,
	lumberjacks_trousers: 12008,
	fishing_shard: 12009,
	mining_shard: 12010,
	foraging_shard: 12011,
	prismatic_dust: 12012,
	chefs_hat: 12013,
	infernal_dust: 12014,
	golden_spoon: 12015,
	cooking_shard: 12016,
	runecrafting_shard: 12017,
	camo_cloak: 12018,
	flamboyant_cape: 12019,
	small_bag_of_holding: 12020,
	bag_of_holding: 12021,
	large_bag_of_holding: 12022,
	newbie_adventurers_backpack: 12023,
	dwarven_adventurers_backpack: 12024,
	cloak_of_many_pockets: 12025,
	cloak_of_the_void: 12026,
	dwarven_manufacturing_pendant: 12027,
	elven_logistics_pendant: 12028,
	farming_shard: 12029,
	dwarven_tongs: 12030,
	smithing_shard: 12031,
	elvish_hoe: 12032,
	void_portal_anchor: 12033,
	defunct_golem_core: 12034,
	lava_heart: 12035,
	bleached_cloth: 12036,
	academy_wards: 12037,
	smiths_golem_torso: 12038,
	smiths_golem_legs: 12039,
	chefs_coat: 12040,
	chefs_pants: 12041,
	runecrafters_robe_top: 12042,
	runecrafters_robe_hood: 12043,
	runecrafters_robe_bottoms: 12044,
	combat_manual_volume_1: 13000,
	combat_manual_volume_2: 13001,
	combat_manual_volume_3: 13002,
	spell_tome_fire_ball: 13003,
	spell_tome_flamethrower: 13004,
	ballistics_101: 13005,
	projectile_based_diplomacy_i: 13006,
	projectile_based_diplomacy_ii: 13007,
	projectile_based_diplomacy_iii: 13008,
	apocalyptic_tome: 13009,
	intermediate_combat_manual_volume_1: 13010,
	city_decree: 13011,
	how_to_first_aid: 13012,
	defending_the_weak_an_excerpt: 13013,
	combat_manual_volume_4: 13014,
	attention_getting_tactics: 13015,
	smoke_and_mirrors: 13016,
	all_ghillied_up: 13017,
	ice_ice_ice: 13018,
	trappers_guide: 13019,
	spell_tome_ice_shard: 13020,
	notes_on_acid_i: 13021,
	notes_on_acid_ii: 13022,
	armor_and_its_weaknesses: 13023,
	cultist_notes_i: 13024,
	cultist_notes_ii: 13025,
	enlightened_parchment: 13026,
	a_study_on_anatomy_i: 13027,
	a_study_on_anatomy_ii: 13028,
	a_study_on_anatomy_iii: 13029,
	trappers_guide_the_sequel: 13030,
	ancient_ice_tome: 13031,
	slayers_mark: 15000,
	token_of_ownership: 15001,
	token_of_action: 15002,
	talent_respec_token: 15003,
	tradeable_talent_respec_token: 15004,
	wooden_cup: 16000,
	wooden_goblet: 16001,
	wooden_chalice: 16002,
	refined_wooden_chalice: 16003,
	exquisite_wooden_chalice: 16004,
	small_silver_cup: 16005,
	silver_goblet: 16006,
	silver_chalice: 16007,
	refined_silver_chalice: 16008,
	exquisite_silver_chalice: 16009,
	tall_gold_goblet: 16010,
	wide_gold_goblet: 16011,
	golden_chalice: 16012,
	refined_golden_chalice: 16013,
	exquisite_golden_chalice: 16014,
	golden_grail: 16015,
	monster_tooth: 25000,
	monster_horn: 25001,
	sturdy_horn: 25002,
	old_coins: 25003,
	primitive_coin: 25004,
	undead_token: 25005,
	woodland_trinket: 25006,
	intact_wing: 25007,
	ornate_trinket: 25008,
	useless_metal_scraps: 25009,
	exotic_trinket: 25010,
	demonic_statue: 25011,
	monster_claws: 25012,
	bottled_blood: 25013,
	broken_armaments: 25014,
	creature_eggs: 25015,
	dwarven_coins: 25016,
	bone_shards: 25017,
	giant_heart: 25018,
	ancient_armor_scraps: 25019,
	unknown_rune: 25020,
	light_armor_scraps: 25021,
	armor_scraps: 25022,
	heavy_armor_scraps: 25023,
	tusk: 25024,
	gem_shards: 25025,
	goblin_fork: 25026,
	nature_stone: 25027,
	fire_stone: 25028,
	ice_stone: 25029,
	a_diamond_plated_bug: 25030,
	valuable_furs: 25031,
	metal_slag: 25032,
	ban_hamer: 100000,
};
