// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TIdlescapeBondPackList } from '../../../game-server/src/modules/monetization/IdlescapeBondPackData';

export const idlescapeBondPacks: TIdlescapeBondPackList = {
	// Starting at 1000 to reduce the risk of mixing up bond and plat packs
	1001: {
		id: 1001,
		name: '1 Idlescape Bond',
		description: 'Idlescape Bonds are used to gift one month of Idlescape Plus to other players.',
		bondAmount: 1,
		price: 4.95,
		priceId: {
			development: 'price_1QrjlLEnEMjApR1KD44il3jB',
			production: 'price_1Qg9fHEnEMjApR1KkttoZDnX',
		},
		image: '/images/premium/gift_token.png',
	},
	1002: {
		id: 1002,
		name: '5 Idlescape Bonds',
		description: 'Idlescape Bonds are used to gift one month of Idlescape Plus to other players.',
		bondAmount: 5,
		price: 23.45,
		priceId: {
			development: 'price_1Qrjm2EnEMjApR1K6BUccdD0',
			production: 'price_1QrNu6EnEMjApR1KyvFqDZP8',
		},
		image: '/images/premium/gift_token.png',
	},
	1003: {
		id: 1003,
		name: '10 Idlescape Bonds',
		description: 'Idlescape Bonds are used to gift one month of Idlescape Plus to other players.',
		bondAmount: 10,
		price: 44.95,
		priceId: {
			development: 'price_1QrjmWEnEMjApR1Kv5TeEF4o',
			production: 'price_1QrNzZEnEMjApR1KJ53FJdIM',
		},
		image: '/images/premium/gift_token.png',
	},
};

const basePack = idlescapeBondPacks[1001];
export const BASE_BONDS_PER_DOLLAR = basePack.bondAmount / basePack.price;
