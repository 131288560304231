import CombatPlayerArea from './CombatPlayerArea';
import { CombatMonsterArea } from './CombatMonsterArea';
import CombatBar from './CombatBar';
import { CombatFightGroup } from './CombatFightGroup';
import { useGroupField } from '../../../hooks/hooks';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { monsterAtom } from '../../../atoms/monsterAtom';
import { combatBuffsAtom } from '../../../atoms/combatBuffsAtom';
import { combatOverTimeEventsAtom } from '../../../atoms/combatOverTimeEvents';
import { useEffect } from 'react';
import { socket } from '../../../services/socket.service';
import { ICombatOverTimeEvents } from '../../../../../game-server/src/modules/combat/Combat.interface';

export const CombatFight = () => {
	const groupMemberData = useGroupField('groupMemberData');

	// We need this to see player summons since they're technically also monsters
	const monsters = useRecoilValue(monsterAtom);
	const setBuffs = useSetRecoilState(combatBuffsAtom);
	const setOverTimeEvents = useSetRecoilState(combatOverTimeEventsAtom);
	const summons = monsters.filter((monster) => monster.faction === 'playerSummon');

	function isSameEvent(event1: ICombatOverTimeEvents, event2: ICombatOverTimeEvents) {
		return (
			event1.targetID === event2.targetID &&
			event1.sourceID === event2.sourceID &&
			event1.sourceName === event2.sourceName
		);
	}

	useEffect(() => {
		// Clear the overTimeEvents on mount, this was mostly for testing when things break
		setOverTimeEvents([]);
		socket.emit('combat:requestOverTimeEvents');
		socket.on('combat:overTimeEvent', (data) => {
			// Remove the old event from the list, if it's 0 then it just won't be read
			if (data.overTimeTicksLeft !== 0) {
				setOverTimeEvents((prev) => [...prev.filter((event) => !isSameEvent(event, data)), data]);
			} else {
				setOverTimeEvents((prev) => prev.filter((event) => !isSameEvent(event, data)));
			}
		});
		socket.on('combat:buffs', (data) => setBuffs(data));

		// Was there a reason that socket.off wasn't present prior to the overTimeEvent changes?
		return () => {
			socket.off('combat:overTimeEvent');
			socket.off('combat:buffs');
		};
	}, []);

	if (groupMemberData && (groupMemberData.length > 1 || summons.length > 0)) {
		return <CombatFightGroup />;
	}

	return (
		<div className='combat-fight-container'>
			<div className='combat-fight'>
				<CombatPlayerArea />
				<CombatMonsterArea />
			</div>
			<CombatBar />
		</div>
	);
};
