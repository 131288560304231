import { TSkillName, TSkills } from '../../../../../game-server/src/Atypes/Skills';
import { IQuestData } from '../../../../../game-server/src/modules/quest/Quest.interface';
import { questList } from '../../../utils/questList';

export function checkRequirements(quest: IQuestData, skills: TSkills, questIdsCompleted: number[]) {
	const questPoints = getQuestPoints(questIdsCompleted);
	if (!quest.questRequirements) {
		return true;
	}
	if (quest.questRequirements.questPoints && questPoints < quest.questRequirements.questPoints) {
		return false;
	}
	const questNotDone = quest.questRequirements.requiredQuests?.findIndex((req) => !questIdsCompleted.includes(req));
	if (quest.questRequirements.requiredQuests && questNotDone !== -1) {
		return false;
	}
	if (quest.questRequirements.skillRequirements) {
		for (const [skillName, level] of Object.entries(quest.questRequirements.skillRequirements)) {
			if (skills[skillName as TSkillName].level < level) {
				return false;
			}
		}
	}
	if (quest.questRequirements.masterySkillRequirements) {
		for (const [skillName, level] of Object.entries(quest.questRequirements.masterySkillRequirements)) {
			if (skills[skillName as TSkillName].masteryLevel < level) {
				return false;
			}
		}
	}
	return true;
}

export function getQuestPoints(questIdsCompleted: number[]) {
	return questIdsCompleted.reduce((acc, questId) => (acc += questList[questId]?.questRewards?.questPoints ?? 0), 0);
}
