//security
import { load } from 'recaptcha-v3';
//endsecurity

import React, { useEffect, useState } from 'react';
import { Header } from '../layout/Header';
import { Levels } from '../layout/Levels';
import { NavigationTabs } from './NavigationTabs';
import RightPanel from './RightPanel';
import { ReactNotifications } from 'react-notifications-component';
import { initiateSocketConnection, socket } from '../../services/socket.service';

import './InventoryItem.css';
import './Inventory.css';
import './Items.css';
import './Game.css';
import '../layout/UI/globals.css';
import '../layout/UI/IdlescapeConfirmAlert.css';
import ChatContainer from './Chat/ChatContainer';
import { NotificationManager } from '../../services/NotificationManager';
import { ChatMessageManager } from '../../services/ChatMessageManager';
import { MonsterManager } from '../../services/MonsterManager';
import { GlobalsManager } from '../../services/GlobalsManager';
import { GroupManager } from '../../services/GroupManager';
import PlayerManager from '../../services/PlayerManager';
import { useGroupField, useUnsavePlayerField } from '../../hooks/hooks';
import Details from '../layout/popups/details/Details';
import Book from '../layout/popups/Book';
import Popup from '../layout/popups/Popup';
import { OfflineProgression } from '../layout/popups/OfflineProgression';
import ChestContents from '../layout/popups/ChestContents';
import Disconnect from '../layout/popups/Disconnect';
import Inspect from '../layout/popups/profile/Inspect';
import { currentVersion } from 'idlescape-globals';
import { PaymentListener } from '../../services/PaymentListener';
import { MonetizationPurchasePopup } from '../layout/popups/MonetizationPurchasePopup';
import { LoadingWheel } from '../layout/LoadingWheel';
import { AbsoluteCenter, Box, Flex, Grid, Text } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeWrappingTooltip } from '@idlescape/ui';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { MobileLayout } from '../mobile/MobileLayout';
import { abilities } from '../../utils/abilityList';
import { enchantmentsList } from '../../utils/enchantmentList';
import { gameShopList } from '../../utils/gameShopList';
import { itemList } from '../../utils/itemList';
import { locations } from '../../utils/locationList';
import { affixList } from '../../utils/affixList';
import { productionRecipeList } from '../../utils/craftAugTable';
import useIsMobile from '../../hooks/useIsMobile';
import { Drawer } from '../layout/drawer/Drawer';
import Split from 'react-split-grid';
import { MdDragHandle } from 'react-icons/md';
import useNavbarPinned from '../../hooks/useNavbarPinned';
import { changeFaviconToDefault, getMaxQueueCount } from '../../helper/helperFunctions';
import { cookingList } from '../../utils/cookingList';
import { ClientJS } from 'clientjs';
import { Resetter, useResetRecoilState } from 'recoil';
import { messageAtom } from '../../atoms/chat/messageAtom';
import { activeChannelAtom } from '../../atoms/activeChannelAtom';
import QuestPopup from '../layout/popups/QuestPopup';
import { GiCheckMark, GiCrossMark } from 'react-icons/gi';
import { openGameUrl } from '../../GameUrls';
import ProfileEditor from '../layout/popups/profile/ProfileEditor';
import FailedMerc from '../layout/popups/FailedMerc';
import { leagueList } from '../../utils/leagueList';
import setAuthToken from '../../helper/setAuthToken';
import { augmentingLootList } from '../../utils/augmentingLootList';
import { getTimeString } from '../../utils/helperFunctions';
import MaxLevelPopup from '../layout/popups/MaxLevelPopup';
import { monsters } from '../../utils/monsterList';
import { monsterAtom } from '../../atoms/monsterAtom';
import { forges } from '../../utils/forgeList';

// Add more atoms here which need to be reset when switching characters
const atomResetList = [messageAtom, activeChannelAtom, monsterAtom];

export const Game = () => {
	const [socketConnected, setSocketConnected] = useState<boolean | undefined>();
	const [running, setRunning] = useState<boolean | undefined>();
	const [restartSocket, setRestartSocket] = useState(false); // Switch value to restart socket connection
	const [statusText, setStatusText] = useState('Connecting to the server... 1/3');
	const [subStatusText, setSubStatusText] = useState('');
	const setReconnectAmount = useState(0)[1];

	const [mainGridColumns, setMainGridColumns] = useLocalStorage('main-grid-columns', '2fr 5px 1fr');
	const [mainGridRows, setMainGridRows] = useLocalStorage('main-grid-rows', '3fr 5px 1fr');

	const [playerLoaded, setPlayerLoaded] = useState<boolean | undefined>();
	const [notificationManagerLoaded, setNotificationManagerLoaded] = useState<boolean | undefined>();
	const [chatMessageManagerLoaded, setChatMessageManagerLoaded] = useState<boolean | undefined>();
	const [monsterManagerLoaded, setMonsterManagerLoaded] = useState<boolean | undefined>();
	const [globalsManagerLoaded, setGlobalsManagerLoaded] = useState<boolean | undefined>();
	const [groupManagerLoaded, setGroupManagerLoaded] = useState<boolean | undefined>();

	const { navbarPinned } = useNavbarPinned();
	const settings = useUnsavePlayerField('settings');
	const highPerformance = settings?.miscellaneous?.highPerformance ?? false;

	const subscription = useUnsavePlayerField('subscription');
	const bonusActionQueueCount = useUnsavePlayerField('bonusActionQueueCount');
	const maxQueue = getMaxQueueCount(subscription?.active ?? false, bonusActionQueueCount ?? 0);

	const showActionQueue = (settings?.miscellaneous?.showQueueButton && maxQueue > 1) ?? false;

	const navigate = useNavigate();

	const isMobile = useIsMobile();

	const id = useUnsavePlayerField('id');
	const leaderId = useGroupField('leaderId');
	const groupStatus = useGroupField('status');
	const catchupTime = useGroupField('catchupTime') ?? 0;
	const catchingUp = leaderId === id && groupStatus === 'catchup' && catchupTime > 0;

	const atomResetter: Resetter[] = [];
	for (const atom of atomResetList) {
		atomResetter.push(useResetRecoilState(atom));
	}

	useEffect(() => {
		for (const resetAtom of atomResetter) {
			resetAtom();
		}
	}, [id]);

	useEffect(() => {
		const characterID = sessionStorage.getItem('characterID');
		if (!characterID) {
			openGameUrl('characters', { navigate });
		}
	}, []);

	useEffect(() => {
		const body = document.querySelector('body');
		body?.classList.toggle('mobile-layout', isMobile);
	}, [isMobile]);

	useEffect(() => {
		if (catchingUp) {
			setStatusText('Catching Up...');
			setSubStatusText('Time left: ' + getTimeString(catchupTime));
		} else if (running) {
			setStatusText(
				'If this message persists through several page refreshes and clearing your cache, please report it to staff!'
			);
			setSubStatusText('');
		}
	}, [catchupTime, running]);

	function exposeGameData() {
		if (!window.Idlescape) window.Idlescape = { data: {} };
		window.Idlescape.data = {
			items: itemList,
			enchantments: enchantmentsList,
			locations: locations,
			abilities: abilities,
			gameShopItems: gameShopList,
			affixes: affixList,
			craftingAugmenting: productionRecipeList,
			cooking: cookingList,
			leagues: leagueList,
			augmentingLoot: augmentingLootList,
			monsters: monsters,
			forges: forges,
		};
	}

	function setGameLoaded(loaded: boolean) {
		setStatusText('Starting... 3/3');
		socket.emit('session:start:managers:loaded');

		// @ts-ignore
		const client = new window.ClientJS() as ClientJS;
		const dataToSend = {
			hash: client.getFingerprint(),
			userAgent: client.getUserAgent(),
			plugins: client.getPlugins(),
			timezone: client.getTimeZone(),
			language: client.getLanguage(),
		};
		socket.emit('account:security:check', dataToSend);

		setTimeout(() => {
			setRunning(loaded);
			setReconnectAmount(0);
		}, 500);

		exposeGameData();
	}

	function getGameContent() {
		return (
			<Box
				position='relative'
				height='100%'
				width='100%'
				padding={isMobile ? '' : '0 10px'}
				display='grid'
				gridTemplateRows='min-content 1fr'
				gridArea={navbarPinned ? '2 / pinned-start / -1 / game-end' : '2 / unpinned-start / -1 / game-end'}
			>
				<Levels />
				{isMobile ? (
					<Box position='relative' marginTop='10px' overflow='auto'>
						<MobileLayout />
					</Box>
				) : (
					<Split
						gridTemplateRows={mainGridRows}
						gridTemplateColumns={mainGridColumns}
						rowSnapOffset={50}
						onDrag={(direction, track, style) => {
							if (direction === 'column') {
								setMainGridColumns(style);
							} else {
								setMainGridRows(style);
							}
						}}
						// @ts-ignore
						render={({ getGridProps, getGutterProps }) => (
							<Grid
								gridTemplateAreas="'play-area gutter-col right-panel'
												   'gutter-row gutter-col right-panel'
												   'chat-area gutter-col right-panel'"
								{...getGridProps()}
								overflowY='auto'
								paddingBottom='10px'
							>
								<NavigationTabs />
								<RightPanel />
								<ChatContainer />
								{getDragHandles(getGutterProps)}
							</Grid>
						)}
					/>
				)}
			</Box>
		);
	}

	function getDragHandles(getGutterProps: any) {
		return (
			<>
				<Flex
					gridArea='gutter-col'
					gridRow='1 / 4'
					alignItems='center'
					marginX='-5px'
					alignSelf='center'
					transform='rotate(90deg)'
				>
					<MdDragHandle />
				</Flex>
				<Flex gridArea='gutter-row' alignItems='center' width='15px' marginY='-5px' justifySelf='center'>
					<MdDragHandle />
				</Flex>
				<Box
					gridArea='gutter-col'
					cursor='col-resize'
					gridRow='1 / 4'
					{...getGutterProps('column', 1)}
					zIndex='1'
				/>
				<Box gridArea='gutter-row' cursor='row-resize' {...getGutterProps('row', 1)} zIndex='1' />
			</>
		);
	}

	function loadingStatus(state?: boolean) {
		switch (state) {
			case true:
				return <GiCheckMark color='green' />;
			case false:
				return <GiCrossMark color='red' />;
			default:
				return '...';
		}
	}

	function getLoadingWheel() {
		return (
			<AbsoluteCenter textAlign={'center'}>
				<LoadingWheel statusText={statusText} subStatusText={subStatusText}></LoadingWheel>
				<IdlescapeButton
					margin={'10px auto'}
					width={'fit-content'}
					padding={'0 30px'}
					onClick={() => {
						openGameUrl('characters', { navigate });
					}}
				>
					Back to Character Menu
				</IdlescapeButton>
				{running && socketConnected && (
					<IdlescapeWrappingTooltip content='Game may lag as it catches up'>
						<IdlescapeButton
							margin={'10px auto'}
							width={'fit-content'}
							variant='red'
							padding={'0 30px'}
							onClick={() => {
								socket.emit('action:cancelCatchup');
							}}
						>
							Resume Game
						</IdlescapeButton>
					</IdlescapeWrappingTooltip>
				)}
				<Text lineHeight='1.5em'>
					Socket Connected: {loadingStatus(socketConnected)}
					<br />
					Player Data: {loadingStatus(playerLoaded)}
					<br />
					Notification Manager: {loadingStatus(notificationManagerLoaded)}
					<br />
					Chat Manager: {loadingStatus(chatMessageManagerLoaded)}
					<br />
					Monster Manager: {loadingStatus(monsterManagerLoaded)}
					<br />
					Globals Manager: {loadingStatus(globalsManagerLoaded)}
					<br />
					Group Manager: {loadingStatus(groupManagerLoaded)}
					<br />
					Complete: {loadingStatus(running)}
				</Text>
			</AbsoluteCenter>
		);
	}

	const managersState = [
		playerLoaded,
		notificationManagerLoaded,
		chatMessageManagerLoaded,
		monsterManagerLoaded,
		globalsManagerLoaded,
		groupManagerLoaded,
	];

	useEffect(() => {
		if (socket && !socket.active) {
			socket.connect();
		}
	}, [restartSocket]);

	useEffect(() => {
		for (const managerState of managersState) {
			if (!managerState) {
				return;
			}
		}

		console.log('All managers loaded');
		setGameLoaded(true);
	}, managersState);

	useEffect(() => {
		console.log('Game component mounted, starting...');

		const startGame = async () => {
			console.log('Game started');
			await initiateSocketConnection();
			window.addEventListener('focus', changeFaviconToDefault);
		};

		startGame()
			.then(() => {
				console.log('Socket connection established, game fully started');

				socket.io.on('reconnect', () => {
					setStatusText('Loading... 2/3');
					setSubStatusText('Reconnected');
				});

				socket.on('connect_error', (reason) => {
					if (reason.message === 'xhr poll error') {
						setReconnectAmount((reconnectAmount) => {
							setSubStatusText('Reconnecting... Attempt ' + (reconnectAmount + 1));
							return reconnectAmount + 1;
						});
					} else {
						setSubStatusText(reason.message);
					}
				});

				socket.on('connect', () => {
					setSocketConnected(true);
					setStatusText('Loading... 2/3');
					console.log('Socket connected', socket);

					/** Event loop which fires when both client and server are ready to continue with syncing */
					socket.on('session:start:socket:ready', () => {
						socket.emit('session:start:socket:ready:client', currentVersion);
					});

					socket.on('session:terminate', (reason) => {
						setStatusText('Disconnected.');
						setSubStatusText(reason);
						setRunning(false);
						setSocketConnected(false);
					});

					socket.on('disconnect', (reason) => {
						setSocketConnected(false);
						setRunning(false);
						setPlayerLoaded(false);
						setStatusText('Disconnected. Reason: ' + reason);
						switch (reason) {
							case 'io server disconnect':
								setStatusText('Disconnected. Server kicked you out.');
								break;
							case 'io client disconnect':
								setStatusText('Disconnected. Client has disconnected. You should not be here.');
								break;
							case 'ping timeout':
								setStatusText('Disconnected. Server has timed out. Reconnecting...');
								setRestartSocket(!restartSocket);
								break;
							case 'transport close':
								setStatusText('Disconnected. Your device has lost the connection. Reconnecting...');
								setRestartSocket(!restartSocket);
								break;
							case 'transport error':
								setStatusText('Disconnected. Server is updating...');
								setRestartSocket(!restartSocket);
								break;
							default:
								setStatusText('Disconnected. Reason: ' + reason);
						}
						console.log('Socket disconnected', reason);
						socket.off('session:start:socket:ready');
						socket.off('disconnect');
					});

					socket.on('jwt:invalid', () => {
						setStatusText('Disconnected. Reason: Invalid JWT token');
						setRunning(false);
						setSocketConnected(false);
						setAuthToken(false);
						openGameUrl('landing', { navigate });
					});

					// ! Rewrite this shit
					/* socket.on('received', () => {
				console.log('RECEIVED CHARACTER DATA FROM SERVER, LOADING IT!');
				load('6LflzNoZAAAAALU4KXPJzxFxZVElL7WsZkY9dInH', { autoHideBadge: true }).then((recaptcha) => {
				  recaptcha.execute('game').then((token) => {
					var client = new window.ClientJS();
					let verification = {};
					verification['token'] = token;
					verification['hash'] = client.getFingerprint();
					verification['userAgent'] = client.getUserAgentLowerCase();
					verification['plugins'] = client.getPlugins();
					verification['timezone'] = client.getTimeZone();
					verification['language'] = client.getLanguage();
					socket.emit('account:security:check', verification);
				  });
				});
			  }); */
				});
			})
			.catch((error) => {
				console.error('Failed to start the game:', error);
			});

		return () => {
			console.log('Game stopped working');
			window.removeEventListener('focus', changeFaviconToDefault);

			if (socket) {
				try {
					socket.off('session:start:socket:ready');
					socket.off('connect');
					socket.off('disconnect');
					socket.off('session:terminate');
					socket.off('jwt:invalid');
				} catch (error) {
					console.error('Error during socket cleanup:', error);
				}
			}
		};
	}, []);

	// console.log('IM RENDERING', socketConnected, socket?.connected, playerLoaded, loaded);

	return (
		<div
			className={`game-container${highPerformance ? ' high-performance' : ''}${
				showActionQueue ? ' action-queue' : ''
			}`}
		>
			<Header loaded={running} />
			<Drawer loaded={running} />
			{socketConnected && socket.connected && (
				<>
					<PlayerManager loaded={playerLoaded} setLoaded={setPlayerLoaded} />
					<GlobalsManager loaded={globalsManagerLoaded} setLoaded={setGlobalsManagerLoaded} />
					{playerLoaded && (
						<>
							<NotificationManager
								loaded={notificationManagerLoaded}
								setLoaded={setNotificationManagerLoaded}
							/>
							<MonsterManager loaded={monsterManagerLoaded} setLoaded={setMonsterManagerLoaded} />
							<GroupManager loaded={groupManagerLoaded} setLoaded={setGroupManagerLoaded} />
							<ChatMessageManager
								loaded={chatMessageManagerLoaded}
								setLoaded={setChatMessageManagerLoaded}
							/>
							<PaymentListener />

							{/* These are popups */}
							<Book />
							<Popup />
							<OfflineProgression />
							<ChestContents />
							<QuestPopup />
							<MaxLevelPopup />
							<FailedMerc />
							<Disconnect socket={socket} />
							<Inspect />
							<ProfileEditor />
							<MonetizationPurchasePopup />
						</>
					)}
					{/* @ts-ignore */}
					<ReactNotifications className='idlescape-notification' />
				</>
			)}
			{running && socketConnected && !catchingUp ? getGameContent() : getLoadingWheel()}
		</div>
	);
};
