import { atom } from 'recoil';
import { IItemData } from '../../../../game-server/src/modules/items/items.interface';

export const openedChestAtom = atom({
	key: 'openedChestAtom',
	default: [] as {
		/** The id of the first opening that is captured. Not overwritten by other chests of the same type */
		id: number;
		itemID: IItemData['id'];
		/** The amount of opened chests in the last batch */
		stackSize: number;
		/** The total amount of opened chests */
		totalStackSize: number;
	}[],
});
