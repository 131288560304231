import { BoxProps, Flex } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import useIsMobile from '../../../../hooks/useIsMobile';

export const ProfileAddIcon = forwardRef(({ onClick, ...props }: { onClick?: () => void } & BoxProps, ref) => {
	const isMobile = useIsMobile();
	return (
		<Flex
			ref={ref}
			height={isMobile ? '50px' : '60px'}
			width={isMobile ? '50px' : '60px'}
			border='3px solid white'
			borderRadius='15px'
			borderStyle='dashed'
			justifyContent='center'
			alignItems='center'
			cursor='pointer'
			_hover={{ backgroundColor: 'rgba(255,255,255,0.3)' }}
			onClick={onClick}
			{...props}
		>
			<FaPlus />
		</Flex>
	);
});
